import MarkupLine from "./MarkupLine";
import Line from "../base/Line";
import {getPerpendicularToLine} from "../../utils/MathUtils";

class MarkupPerpendicular extends MarkupLine {
  constructor(name, label, description, refPoints = [], color = '#000') {
    super(name, label, description, refPoints, color);
    this.fromPoint = null;
    this.recalculateShapeGeometry();
  }

  checkRefPoints() {
    if (this._refPoints.length < 3) {
      return `Perpendicular requires 3 reference points, got ${this._refPoints.length}`;
    }
  }

  recalculateShapeGeometry() {
    const [fromPoint, lineStart, lineEnd] = this._refPoints;
    const line = new Line(lineStart.position, lineEnd.position);
    const segment = getPerpendicularToLine(line, fromPoint.position);

    this.startPoint = segment.start.clone();
    this.endPoint = segment.end.clone();
  }
}

export default MarkupPerpendicular;