import {femoralHeadPointTypes} from "../../markup/MarkupPointTypes";
import MainMeasureResult from "../MainMeasureResult";

class DH extends MainMeasureResult {
  constructor(refMarkupData) {
    super('DH', refMarkupData);
  }

  recalculateValue() {
    // Femoral head
    const femoralHead = this.getFemoralHead();

    return femoralHead.radius * 2;
  }

  recalculateShapes() {
    this.shapes.push(this.getCircle('femoral', femoralHeadPointTypes, 'Rh'));
  }
}

export default DH;