import React from 'react';
import PropTypes from 'prop-types';
import './MarkupItemInfo.scss';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const MarkupItemInfo = ({markupItemData}) => {
  const { t } = useTranslation();
  const {name, label, description} = markupItemData;
  const fullName = `${t(name)}: ${t(label)}`;

  return (
    <Stack sx={{padding: '4px'}} direction="column">
      <Typography sx={{fontSize: 14, fontWeight: 'bold'}} color="text.secondary" gutterBottom>
        {fullName || ''}
      </Typography>
      <Typography sx={{fontSize: 10}} color="text.secondary" gutterBottom>
        {t(description)}
      </Typography>
    </Stack>
  );
};

MarkupItemInfo.propTypes = {
  markupItemData: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  })
};

MarkupItemInfo.defaultProps = {};

export default MarkupItemInfo;
