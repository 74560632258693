import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ShapesViewer.scss';
import Size from "../../../model/base/Size";
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import MarkupShapeView from "../MarkupShapeView/MarkupShapeView";

const ShapesViewer = ({viewportSize, xRayMarkupData, visible}) => {
  const [markupShapes, setMarkupShapes] = useState([]);

  const onMarkupShapesChanged = useCallback(() => {
    setMarkupShapes(xRayMarkupData.shapes);
  }, [xRayMarkupData]);

  useEffect(() => {
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_SHAPE_ADDED, onMarkupShapesChanged);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, onMarkupShapesChanged);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, onMarkupShapesChanged);

    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_SHAPE_ADDED, onMarkupShapesChanged);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, onMarkupShapesChanged);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, onMarkupShapesChanged);
    }
  }, [xRayMarkupData, onMarkupShapesChanged]);

  useEffect(() => {
    onMarkupShapesChanged();
  }, [xRayMarkupData, onMarkupShapesChanged]);

  return (
    <div className="shapes-container"
         style={{width: `${viewportSize.width}px`, height: `${viewportSize.height}px`,
                 visibility: (visible ? 'visible' : 'hidden'), overflow: 'hidden'}}
    >
      {
        markupShapes.map(markupShape => {
          return (
            <MarkupShapeView
              key={markupShape.id}
              markupShape={markupShape}>
            </MarkupShapeView>
          );
        })
      }
    </div>
  );
};

ShapesViewer.propTypes = {
  viewportSize: PropTypes.instanceOf(Size).isRequired,
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
  visible: PropTypes.bool,
};

ShapesViewer.defaultProps = {
  visible: true
};

export default ShapesViewer;
