import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import './MarkupPointView.scss';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {hex2rgba} from "../../../utils/ColorUtils";
import MarkupPoint from "../../../model/markup/MarkupPoint";
import {MarkupPointEvent} from "../../../model/events/MarkupPointEvent";
import {useTranslation} from "react-i18next";

const MarkupPointView = ({markupPoint, pointSize, selected, onPointClick, onPointMouseDown}) => {
  const pointElem = useRef(null);
  const { t } = useTranslation();
  const {color, label, description, visible} = markupPoint;

  const outerSize = useMemo(()=> 5 * pointSize, [pointSize]);
  const pointGeometry = useMemo(() =>{
    const labelPos = pointSize * 1.2;
    const outlineWidth = (selected ? 0.6 : 1.2) * pointSize;
    const outlineOffset = 2 * pointSize - outlineWidth;
    const outlineStyle = 'solid';

    const {color} = markupPoint;
    const outlineColor = selected ? color : hex2rgba(color, 0);

    const centerOffset = (outerSize - pointSize) / 2;
    return {outlineWidth, outlineOffset, outlineColor, outlineStyle, centerOffset, labelPos};
  }, [markupPoint, pointSize, selected, outerSize]);

  const onPositionChanged = useCallback(() => {
    if (!pointElem || !pointElem.current) {
      return;
    }

    const {position} = markupPoint;
    const x = position.x - outerSize / 2;
    const y = position.y - outerSize / 2;

    const {style} = pointElem.current;
    style.left = `${x}px`;
    style.top = `${y}px`;

  }, [pointElem, markupPoint, outerSize]);

  const onVisibilityChanged = useCallback(() => {
    if (!pointElem || !pointElem.current) {
      return;
    }

    const {visible} = markupPoint;
    const {style} = pointElem.current;
    style.visibility = visible ? 'visible' : 'hidden';

  }, [pointElem, markupPoint]);

  useEffect(() => {
    markupPoint.addEventListener(MarkupPointEvent.POSITION_CHANGED, onPositionChanged);
    markupPoint.addEventListener(MarkupPointEvent.VISIBILITY_CHANGED, onVisibilityChanged);
    return () => {
      markupPoint.removeEventListener(MarkupPointEvent.POSITION_CHANGED, onPositionChanged);
      markupPoint.removeEventListener(MarkupPointEvent.VISIBILITY_CHANGED, onVisibilityChanged);
    }
  }, [markupPoint, onPositionChanged, onVisibilityChanged]);

  useEffect(() => {
    onPositionChanged();
  }, [pointElem, onPositionChanged]);

  const onClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (!selected) {
      onPointClick(markupPoint);
    }
  }

  const onMouseDown = () => {
    onPointMouseDown(markupPoint, selected);
  }

  const {outlineWidth, outlineOffset, outlineColor, outlineStyle,
         centerOffset, labelPos} = pointGeometry;

  return (
    <Tooltip title={t(description)}>
      <div ref={pointElem} className={`markup-point ${selected ? 'selected-point' : ''}`}
           style={{width: outerSize, height: outerSize, visibility: (visible ? 'visible' : 'hidden')}}
           onClick={onClick} onMouseDown={onMouseDown}>
          <div className="markup-point-center"
               style={{
                 backgroundColor: color, width: pointSize, height: pointSize,
                 outlineWidth, outlineOffset, outlineColor, outlineStyle,
                 top: centerOffset, left: centerOffset
               }}>
          </div>
        <Typography className="point-label" variant="body1" gutterBottom
                    sx={{top: '-20%', left: `${outerSize - labelPos}px`, fontWeight: 'bold'}}>
          {t(label)}
        </Typography>
      </div>
    </Tooltip>
  )
};

MarkupPointView.propTypes = {
  markupPoint: PropTypes.instanceOf(MarkupPoint).isRequired,
  pointSize: PropTypes.number,
  selected: PropTypes.bool,
  onPointClick: PropTypes.func.isRequired
};

MarkupPointView.defaultProps = {
  pointSize: 4,
  selected: false
};

export default MarkupPointView;
