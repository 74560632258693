import MeasureCalculator from "./MeasureCalculator";
import AngleD from "./results/AngleD";

class FrontalMeasureCalculator extends MeasureCalculator {
   recreateMeasureResults() {
    return [
      new AngleD(this.markupData),
    ];
  }
}

export default FrontalMeasureCalculator;