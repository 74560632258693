import React from 'react';
import './AboutMethod.scss';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircleIcon from '@mui/icons-material/Circle';

import {useTranslation} from "react-i18next";

const AboutMethod = () => {
  const {t} = useTranslation();
  const descriptionItems = t('general.method_description').split(';');
  return (
    <>
    <List>
      {
        descriptionItems.map((item, index) => {
          return (
            <ListItem key={index} >
              <ListItemIcon sx={{minWidth: '25px'}}>
                <CircleIcon size="xx-small" sx={{fontSize: '7px'}}/>
              </ListItemIcon>
              {item}
            </ListItem>
          )
        })
      }
    </List>
    <Typography component="p" variant="caption" sx={{marginTop:"50px"}}>{t('general.method_disclaimer')}</Typography>
    </>
  )
};

AboutMethod.propTypes = {};

AboutMethod.defaultProps = {};

export default AboutMethod;
