import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './MeasureResultsView.scss';
import MeasureCalculator from "../../../model/measure/MeasureCalculator";
import {MeasureResultEvent} from "../../../model/events/MeasureResultEvent";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MeasureResultView from "../MeasureResultView/MeasureResultView";
import {useTranslation} from "react-i18next";

const MeasureResultsView = ({measureCalculator, onMeasureResultSelected}) => {
  const [measureResults, setMeasureResults] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const {t} = useTranslation();

  const onResultsUpdated = useCallback(() => {
    if (!measureCalculator.measureResults || !measureCalculator.measureResults.length) {
      resetSelection();
    }
    setMeasureResults(measureCalculator.measureResults ? [...measureCalculator.measureResults] : null);
  }, [measureCalculator]);

  useEffect(() => {
    measureCalculator.addEventListener(MeasureResultEvent.RESULTS_UPDATED, onResultsUpdated);

    return () => {
      measureCalculator.removeEventListener(MeasureResultEvent.RESULTS_UPDATED, onResultsUpdated);
    }
  }, [measureCalculator, onResultsUpdated]);

  useEffect(() => {
    onResultsUpdated();
  }, [measureCalculator, onResultsUpdated]);

  useEffect(() => {
    onMeasureResultSelected(selectedResult);
  }, [selectedResult, onMeasureResultSelected]);

  const onResultClick = (measureResult) => {
    if (selectedResult && selectedResult.id === measureResult.id) {
      resetSelection();
    }
    else {
      setSelectedResult(measureResult);
    }

  };

  const resetSelection = () => {
    setSelectedResult(null);
  }

  if (!measureResults) {
    return;
  }

  return (
    <Stack sx={{height: '100%', width: "200px", position: 'relative'}} direction="column">
      {
        selectedResult !== null
          ? <Button onClick={resetSelection} size="small">{t('general.reset_selection')}</Button>
          : null
      }
      <Box sx={{height: '100%', width: "100%", position: 'relative', overflow: 'auto'}}>
        <Stack className="results-container" direction="column"
             divider={<Divider orientation="horizontal" flexItem />}
             sx={{width: "100%", position: 'absolute'}}
        >
          {
            measureResults.map(measureResult => {
              return (
                <MeasureResultView
                  key={measureResult.id}
                  measureResult={measureResult}
                  onResultClick={onResultClick}
                  selected={selectedResult && selectedResult.id === measureResult.id ? true : false}>
                </MeasureResultView>
              );
            })
          }
        </Stack>
      </Box>
    </Stack>
  );
};

MeasureResultsView.propTypes = {
  measureCalculator: PropTypes.instanceOf(MeasureCalculator).isRequired,
  onMeasureResultSelected: PropTypes.func.isRequired,
};

MeasureResultsView.defaultProps = {};

export default MeasureResultsView;
