import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './XRayMarkupTool.scss';
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Viewport from "../Viewport/Viewport";
import Zoom from "../../controls/Zoom/Zoom";
import Contrast from "../../controls/Contrast/Contrast";
import InputListener from "../InputListener/InputListener";
import PointerViewer from "../PointsViewer/PointsViewer";
import ControlPanel from "../../controls/ControlPanel/ControlPanel";
import ShapesViewer from "../ShapesViewer/ShapesViewer";
import MeasureCalculator from "../../../model/measure/MeasureCalculator";
import MeasureResultsView from "../MeasureResultsView/MeasureResultsView";
import MeasureHintViewer from "../MeasureHintViewer/MeasureHintViewer";
import MarkupHeader from "../MarkupHeader/MarkupHeader";

const XRayMarkupTool = ({xRayMarkupData, measureCalculator, showPreliminaryDiagnosis}) => {
  const {data, size} = xRayMarkupData.imageData;
  const [zoomLevel, setZoomLevel] = useState(0);
  const [contrastLevel, setContrastLevel] = useState(1);
  const [selectedMeasureResult, setSelectedMeasureResult] = useState(null);

  const onMeasureResultSelected = (measureResult) => {
    setSelectedMeasureResult(measureResult);
  }
  return (
    <Stack sx={{width: '100%', height: '100%'}} spacing={2} justifyContent="center"
           alignItems="center">
      <MarkupHeader xRayMarkupData={xRayMarkupData} showPreliminaryDiagnosis={showPreliminaryDiagnosis}></MarkupHeader>
      <Stack sx={{width: '100%', height: '100%'}} spacing={2} direction="row">
        <Box sx={{width: '100%', height: '100%', position: 'relative', minWidth: '500px'}}>
          <Viewport contentSize={size} zoomLevel={zoomLevel}>
            <img width={size.width} height={size.height} className="xray-content" alt="X-Ray content"
                 src={data}
                 style={{filter: `contrast(${contrastLevel})`}}></img>
            <ShapesViewer viewportSize={size} xRayMarkupData={xRayMarkupData} visible={selectedMeasureResult === null}></ShapesViewer>
            <MeasureHintViewer viewportSize={size} measureResult={selectedMeasureResult}></MeasureHintViewer>
            <InputListener viewportSize={size}></InputListener>
            <PointerViewer viewportSize={size} xRayMarkupData={xRayMarkupData}></PointerViewer>
          </Viewport>
          <ControlPanel>
              <Zoom zoomLevel={zoomLevel} onZoomLevelChange={setZoomLevel} defaultZoomLevel={0} minZoom={0}
                    maxZoom={1}></Zoom>
              <Contrast contrastLevel={contrastLevel} minContrast={0.5} maxContrast={2}
                        onContrastLevelChange={setContrastLevel}></Contrast>
          </ControlPanel>
        </Box>
        <MeasureResultsView measureCalculator={measureCalculator} onMeasureResultSelected={onMeasureResultSelected}></MeasureResultsView>
      </Stack>
    </Stack>
  )
};

XRayMarkupTool.propTypes = {
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
  measureCalculator: PropTypes.instanceOf(MeasureCalculator).isRequired,
  showPreliminaryDiagnosis: PropTypes.bool,
};

XRayMarkupTool.defaultProps = {
  showPreliminaryDiagnosis: false,
};

export default XRayMarkupTool;
