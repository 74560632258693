import {getAngleWithVerticalFromCenter} from "../../../utils/MathUtils";
import Line from '../../base/Line';
import MarkupAxisLine from "../../markup/MarkupAxisLine";
import MainMeasureResult from "../MainMeasureResult";

class AngleA extends MainMeasureResult {
  constructor(refMarkupData) {
    super('ANGLE_A', refMarkupData);
  }

  recalculateValue() {
    const bPoint = this.getBMarkupPoint().position;
    const tearDropPoint = this.getClosestTearDropMarkupPoint(bPoint).position;
    const line = new Line(tearDropPoint, bPoint);
    return getAngleWithVerticalFromCenter(line, this.getMeasureSideDirection());
  }

  recalculateShapes() {
    const bPoint = this.getBMarkupPoint();
    const tearDropPoint = this.getClosestTearDropMarkupPoint(bPoint.position);

    this.shapes.push(this.getAxisLine('tearDropAxis', tearDropPoint.type, MarkupAxisLine.VERTICAL_DIRECTION, -200));
    this.shapes.push(this.getLine('ab', [tearDropPoint, bPoint].map(markupPoint => markupPoint.type)));
  }
}

export default AngleA;