import {BaseEvent} from "./BaseEvent";

export class PointEvent extends BaseEvent {
  static POINT_SELECTED = 'pointSelected';
  static DESELECT_ALL_POINTS = 'deselectAllPoints';
  static SELECT_POINT = 'selectPoint';

  constructor(eventType, payload) {
    const point = payload || {};
    if (point === undefined && eventType !== PointEvent.DESELECT_ALL_POINTS) {
      throw new Error('Point should be provided in the payload of PointEvent');
    }
    super(eventType, payload);
  }
}

