import {v4 as uuidv4} from 'uuid';
import {EventDispatcher} from "../EventDispatcher";
import {getMeasureDataByType} from "./MeasureTypes";
import Range from "../base/Range";
import MarkupLine from "../markup/MarkupLine";
import MarkupCircle from "../markup/MarkupCircle";
import MarkupAxisLine from "../markup/MarkupAxisLine";
import MarkupPerpendicular from "../markup/MarkupPerpendicular";
import {getAngleWithHorizontal} from "../../utils/MathUtils";
import {MeasureResultEvent} from "../events/MeasureResultEvent";

class MeasureResult extends EventDispatcher {
  constructor(type, refMarkupData = null, refMeasureResults = null) {
    super();
    const {label, name, description} = getMeasureDataByType(type);
    this.id = uuidv4();
    this.type = type;
    this.label = label;
    this.name = name;
    this.description = description;
    this.refMarkupData = refMarkupData;
    this.refMeasureResults = refMeasureResults;
    this._value = null;
    this._shapes = [];
    this.range = this.getStandardRange();
    this.markupSideDirection = this.refMarkupData.getMarkupSideFromSagittalAxis();
    this.dependsOnMarkupDirection = false;
    this.recalculateShapes();
  }

  get value () {
    return this._value;
  }

  get shapes () {
    return this._shapes;
  }

  recalculate() {
    this._value = this.recalculateValue();
    const direction = this.refMarkupData.getMarkupSideFromSagittalAxis();
    if (direction !== this.markupSideDirection) {
      this.markupSideDirection = direction;
      this.onMarkupDirectionChanged();
    }
  }

  onMarkupDirectionChanged() {
    if (this.dependsOnMarkupDirection) {
      this.clearShapes();
      this.recalculateShapes();
      this.dispatch(new MeasureResultEvent(MeasureResultEvent.RESULT_SHAPES_UPDATED, {shapes: this.shapes}));
    }
  }

  recalculateValue() {
    // stub to override
    return null;
  }

  recalculateShapes() {
    // stub to override
  }

  getLine (name, pointTypes) {
    return new MarkupLine(name, '', '', this.refMarkupData.getPointsOfTypes(pointTypes), '#ffff00');
  }

  getAxisLine (name, pointType, direction, length) {
    return new MarkupAxisLine(name, '', '', this.refMarkupData.getPointsOfTypes([pointType]), direction, length, '#ffff00');
  }

  getPerpendicularLine (name, pointTypes) {
    return new MarkupPerpendicular(name, '', '', this.refMarkupData.getPointsOfTypes(pointTypes), '#ffff00');
  }

  getCircle(name, pointTypes, radiusLabel) {
    return new MarkupCircle(name, '', '', this.refMarkupData.getPointsOfTypes(pointTypes), '#ffff00', radiusLabel && radiusLabel.length > 0, radiusLabel);
  }

  getStandardRange() {
    // stub to override
    return new Range();
  }

  clearShapes() {
    this._shapes.forEach(markupShape => markupShape.destroy());
    this._shapes.splice(0, this._shapes.length);
  }

  getFemoralHead() {
    return this.refMarkupData.getFemoralHead();
  }

  getMeasureSideDirection () {
    return this.refMarkupData.getMarkupSideFromSagittalAxis();
  }

  getAngleWithHorizontalDirection (line) {
    const direction = this.getMeasureSideDirection();
    const angle = getAngleWithHorizontal(line);
    return (direction > 0 ? angle : (angle / Math.abs(angle)) * (180 - Math.abs(angle)));
  }

  destroy() {
    this._value = null;
    this.clearShapes();
  }
}

export default MeasureResult;