import MarkupPoint from "./MarkupPoint";
import TearDropSegment from "./TearDropSegment";
import {getPointDataByType} from "./MarkupPointTypes";
import AbductionSegment from "./AbductionSegment";

export const MarkupItemsFactory = {
  getMarkupPoint: (type, point) => {
    const {label, name, description, color} = getPointDataByType(type);
    return new MarkupPoint(type, label, name, description, point, color);
  },

  getMarkupSegment: (type, points) => {
    switch (type) {
      case 'TEAR_DROP':
        return new TearDropSegment(points);
      case 'LATERAL_ABDUCTION':
        return new AbductionSegment(points);
      default:
        return null;
    }
  }
};