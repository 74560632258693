import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import './MarkupWizard.scss';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import GlobalModelProvider from "../../../contexts/GlobalModelContext";
import {InputTypes} from "../../../model/base/InputTypes";
import {InputEvent} from "../../../model/events/InputEvent";
import {v4 as uuidv4} from "uuid";
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import {MarkupItemsFactory} from "../../../model/markup/MarkupItemsFactory";
import MarkupItemInfo from "../MarkupItemInfo/MarkupItemInfo";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import {useTranslation} from "react-i18next";

const MarkupWizard = forwardRef(({xRayMarkupData}, ref) => {
  const {eventDispatcher} = useContext(GlobalModelProvider);
  const [markupInputData, setMarkupInputData] = useState(null);
  const [inputId, setInputId] = useState(null);
  const { t } = useTranslation();

  const cancelAddPoint = useCallback(() => {
    eventDispatcher.dispatch(new InputEvent(InputEvent.CANCEL_INPUT, {inputId}));
  }, [eventDispatcher, inputId])

  const resetWizard = useCallback(() => {
    if (inputId) {
      cancelAddPoint();
    }
    else {
      setMarkupInputData(xRayMarkupData.getNextObjectToInput());
    }
  }, [xRayMarkupData, inputId, cancelAddPoint])

  useEffect(() => {
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, resetWizard);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, resetWizard);
    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, resetWizard);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, resetWizard);
    }
  }, [xRayMarkupData, resetWizard]);

  const doesEventInputIdMatch = useCallback((event) => {
    return event && event.payload && event.payload.inputId === inputId;
  }, [inputId]);

  const resetInput = useCallback(() => {
    setInputId(null);
  }, []);

  const onInputPoint = useCallback((event) => {
    if (!doesEventInputIdMatch(event)) {
      return;
    }

    const {point} = event.payload;
    const {type} = markupInputData.markupItemData;
    const markupPoint = MarkupItemsFactory.getMarkupPoint(type, point);
    xRayMarkupData.addMarkupPoint(markupPoint);
    resetInput();
  }, [doesEventInputIdMatch, markupInputData, xRayMarkupData, resetInput]);

  const onInputSegment = useCallback((event) => {
    if (!doesEventInputIdMatch(event)) {
      return;
    }

    const {point1, point2} = event.payload;
    const {type} = markupInputData.markupItemData;
    const markupSegment = MarkupItemsFactory.getMarkupSegment(type, [point1, point2]);
    xRayMarkupData.addMarkupSegment(markupSegment);
    resetInput();
  }, [doesEventInputIdMatch, markupInputData, xRayMarkupData, resetInput]);

  const onCanceledAddPoint = useCallback((event) => {
    if (!doesEventInputIdMatch(event)) {
      return;
    }

    resetInput();
  }, [doesEventInputIdMatch, resetInput]);

  const addInputEventListeners = useCallback(() => {
    eventDispatcher.addEventListener(InputEvent.ADDED_POINT, onInputPoint);
    eventDispatcher.addEventListener(InputEvent.ADDED_SEGMENT, onInputSegment);
    eventDispatcher.addEventListener(InputEvent.CANCELED_INPUT, onCanceledAddPoint);
  }, [eventDispatcher, onInputPoint, onInputSegment, onCanceledAddPoint])

  const removeInputEventListeners = useCallback(() => {
    eventDispatcher.removeEventListener(InputEvent.ADDED_POINT, onInputPoint);
    eventDispatcher.removeEventListener(InputEvent.ADDED_SEGMENT, onInputSegment);
    eventDispatcher.removeEventListener(InputEvent.CANCELED_INPUT, onCanceledAddPoint);
  }, [eventDispatcher, onInputPoint, onInputSegment, onCanceledAddPoint]);

  useEffect(() => {
    if (inputId === null) {
      removeInputEventListeners();
    }
  }, [inputId, removeInputEventListeners]);

  useEffect(() => {
    if (inputId && markupInputData) {
      addInputEventListeners();
      const inputEventType = markupInputData.markupItemType === InputTypes.SEGMENT
                              ? InputEvent.INPUT_SEGMENT
                              : InputEvent.INPUT_POINT;
      eventDispatcher.dispatch(new InputEvent(inputEventType, {inputId}));
    }
  }, [inputId, markupInputData, eventDispatcher, addInputEventListeners]);

  useEffect(() => {
    if (xRayMarkupData && !inputId) {
      setMarkupInputData(xRayMarkupData.getNextObjectToInput());
    }
  }, [xRayMarkupData, inputId]);

  const requestNextInput = () => {
    setInputId(uuidv4());
  }

  const getAddItemLabel = () => {
    const inputType = markupInputData ? markupInputData.markupItemType : null;

    switch (inputType) {
      case InputTypes.POINT:
        return 'general.add_point';
      case InputTypes.SEGMENT:
        return 'general.add_segment';
      default:
        return '';
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      requestNextInput() {
        requestNextInput();
      },
    }),
  );

  const markupItemData = markupInputData ? markupInputData.markupItemData : {};
  const {label} = markupItemData || {};
  const addItemLabel = t(getAddItemLabel(), {label: t(label)});

  if (!markupInputData) {
    return null;
  }

  return (
    <Stack direction="column" sx={{alignItems: 'flex-start'}}>
      <MarkupItemInfo markupItemData={markupItemData}></MarkupItemInfo>
      <Stack direction="row" sx={{alignItems: 'flex-start'}} spacing={1}>
        <Button onClick={requestNextInput} disabled={inputId !== null} size="small" variant="contained">
          {addItemLabel}
        </Button>
        <Button onClick={cancelAddPoint}
                sx={{visibility: (inputId === null ? 'hidden' : 'visible')}} size="small" >{t('general.cancel')}</Button>
      </Stack>
    </Stack>
  )

});

MarkupWizard.propTypes = {
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
};

MarkupWizard.defaultProps = {};

export default MarkupWizard;
