import React, {useCallback, useContext, useEffect, useState} from 'react';
import './MainNavigation.scss';
import StepsProvider from '../../contexts/StepsContext';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import Save from "../controls/Save/Save";
import ImportFromFile from "../controls/ImportFromFile/ImportFromFile";
import MarkupModelProvider from "../../contexts/MarkupModelContext";
import {getAgeDescr} from "../../model/base/AgeRangeTypes";
import {MarkupDataEvent} from "../../model/events/MarkupDataEvent";

const MainNavigation = () => {
  const {t} = useTranslation();
  const [dataProgress, setDataProgress] = useState({main: 0, lateral: 0, frontal: 0});
  const {steps, currentStep, setCurrentStep, toStart} = useContext(StepsProvider);
  const {resetData, mainXRayData, lateralXRayData, frontalXRayData, age} = useContext(MarkupModelProvider);

  const onStatusChanged = useCallback(() => {
    setDataProgress({
      main: mainXRayData.markup.getCompletionProgress(),
      lateral: lateralXRayData.markup.getCompletionProgress(),
      frontal: frontalXRayData.markup.getCompletionProgress(),
    });
  }, [mainXRayData, lateralXRayData, frontalXRayData, setDataProgress]);

  useEffect(() => {
    if (!mainXRayData || !mainXRayData.markup
        || !lateralXRayData || !lateralXRayData.markup
        || !frontalXRayData || !frontalXRayData.markup) {
      return;
    }

    mainXRayData.markup.addEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    lateralXRayData.markup.addEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    frontalXRayData.markup.addEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    onStatusChanged();
    return () => {
      mainXRayData.markup.removeEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
      lateralXRayData.markup.removeEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
      frontalXRayData.markup.removeEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    }
  }, [mainXRayData, lateralXRayData, frontalXRayData, onStatusChanged]);

  const toNextStep = () => {
    if (currentStep && currentStep.id < steps.length - 1) {
      setCurrentStep(steps[currentStep.id + 1]);
    }
  };

  const toPrevStep = () => {
    if (currentStep && currentStep.id > 0) {
      setCurrentStep(steps[currentStep.id - 1]);
    }
  };

  const reset = useCallback(() => {
    resetData();
    toStart();
  }, [resetData, toStart]);

  const isLastStep = (step) => {
    return step && step.id === steps.length - 1;
  }

  const getCompletionStatus = useCallback((progress) => {
    return t('general.completed', {progress});
  }, [t]);

  const getTreatmentStatus = useCallback((dataProgress) => {
    const isReady = Object.values(dataProgress).every(value => value >= 100);
    return t(isReady ? 'general.treatment_ready' : 'general.treatment_not_ready');
  }, [t]);

  const getOptionalLabelContent = useCallback((stepId) => {
    switch (stepId) {
      case 'age':
        return getAgeDescr(age);
      case "main_measures":
        return getCompletionStatus(dataProgress['main']);
      case "lateral_inclination_measures":
        return getCompletionStatus(dataProgress['lateral']);
      case "frontal_inclination_measures":
        return getCompletionStatus(dataProgress['frontal']);
      case "treatment":
        return getTreatmentStatus(dataProgress);
      default:
        return null;
    }
  }, [age, dataProgress, getCompletionStatus, getTreatmentStatus]);


  const getOptionalLabel = useCallback((stepId) => {
    const content = getOptionalLabelContent(stepId);
    if (content) {
      return (<Typography variant="caption">{content}</Typography>);
    }

    return null;
  }, [getOptionalLabelContent]);

  if (!steps.length || !currentStep) {
    return null;
  }

  return (
    <Stack className="MainNavigation" sx={{width: 260, minWidth: 260, height: '100%'}} direction="column" spacing={1}>
      <Box sx={{width: '100%'}}>
        <Stepper activeStep={currentStep.id} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.id}>
              <StepLabel optional={getOptionalLabel(step.stepId)}
                onClick={() => {
                if (currentStep.id !== step.id) {
                  setCurrentStep(step);
                }
              }} sx={{cursor: 'pointer'}}>
                {t(step.name)}
              </StepLabel>
              <StepContent>
                <Typography variant="caption">{t(step.description)}</Typography>
                <Box sx={{mb: 2}}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={isLastStep(step) ? reset : toNextStep}
                      sx={{mt: 1, mr: 1}}
                      size="small"
                    >
                      {t("general.continue")}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={toPrevStep}
                      sx={{mt: 1, mr: 1}}
                      size="small"
                    >
                      {t("general.back")}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Stack direction="column" sx={{width: '100%', alignContent: "stretch"}} spacing={1}>
        <ImportFromFile></ImportFromFile>
        <Save></Save>
        <Button variant="contained" onClick={reset} size="small">
          {t("general.reset")}
        </Button>
      </Stack>
    </Stack>
  );
};

MainNavigation.propTypes = {};

MainNavigation.defaultProps = {};

export default MainNavigation;
