import {EventDispatcher} from "../EventDispatcher";
import {ImageData} from "../base/ImageData";
import Point from "../base/Point";
import {MarkupDataEvent} from "../events/MarkupDataEvent";
import {
  femoralHeadPointTypes,
  getPointDataByType,
  getPointDependencies,
  getSegmentDataByType,
  getShapeDataByName,
  MarkupCalculationTypes
} from "./MarkupPointTypes";
import {InputTypes} from "../base/InputTypes";
import CircleCenterMarkupPoint from "./CircleCenterMarkupPoint";
import MarkupCircle from "./MarkupCircle";
import {getCircleBy3Points} from "../../utils/MathUtils";
import {MarkupItemsFactory} from "./MarkupItemsFactory";
import Size from "../base/Size";

class XRayMarkupData extends EventDispatcher{
  constructor() {
    super();
    this.imageData = new ImageData();
    this._shapes = new Map();
    this._markupPoints = new Map();
    this._markupSegments = new Map();
    this._requiredPointTypes = [];
    this._requiredSegmentTypes = [];
    this._isFulfilled = false;
    this.completionProgress = 0;
  }

  get isFulfilled() {
    return this._isFulfilled;
  }

  get markupPoints() {
    return Array.from(this._markupPoints.values());
  }

  get markupSegments() {
    return Array.from(this._markupSegments.values());
  }

  get shapes() {
    return Array.from(this._shapes.values());
  }

  get allRequiredPointTypes() {
    return this._requiredPointTypes;
  }

  get fulfilledPointTypes() {
    const {markupPoints} = this;
    return this._requiredPointTypes.filter(type => markupPoints.find(markupPoint => markupPoint.type === type) !== undefined);
  }

  get visiblePointTypes() {
    return this.fulfilledPointTypes.filter(type => this.getMarkupPoint(type).visible);
  }

  get isEmpty() {
    return this.imageData.isEmpty();
  }

  isTypeRequired (type) {
    return this.allRequiredPointTypes.includes(type);
  }

  updateImage({data, size}) {
    this.imageData.update({data, size});
    this.clearAllMarkupData();
  }

  clearAllMarkupData() {
    this.markupSegments.forEach(markupSegment => markupSegment.destroy());
    this.markupPoints.forEach(markupPoint => markupPoint.destroy());
    this.shapes.forEach(shape => shape.destroy());
    this._markupPoints.clear();
    this._markupSegments.clear();
    this._shapes.clear();
    this.updateStatus();
    this.dispatch(new MarkupDataEvent(MarkupDataEvent.MARKUP_DATA_CLEARED));
  }

  clearEntireMarkup() {
    // will trigger clearAllMarkupData
    this.updateImage({data: null, size: new Size()});
  }

  addMarkupPoint(markupPoint) {
    if (!this.isTypeRequired(markupPoint.type)) {
      throw new Error(`Point type ${markupPoint.type} doesn't belong to the markup`);
    }

    if (this._markupPoints.has(markupPoint.type)) {
      throw new Error(`Duplicated point type ${markupPoint.type}`);
    }

    this._markupPoints.set(markupPoint.type, markupPoint);
    this.updateShapes(markupPoint);
    this.updateStatus();
    this.dispatch(new MarkupDataEvent(MarkupDataEvent.MARKUP_POINT_ADDED, {markupPoint}));
    this.updateDependantMarkupPoints(markupPoint);
  }

  showHidePoints(types, visible) {
    const getMarkupPoint = this.getMarkupPoint.bind(this);
    types.forEach(type => getMarkupPoint(type).updateVisibility(visible));
    this.updateStatus();
  }

  updateShapes(addedMarkupPoint) {
    // Stub to override
  }

  // Returns 1 if markup is placed on the right side from the sagittal axis and -1 otherwise
  getMarkupSideFromSagittalAxis () {
    // stub to override
    return 1;
  }

  getFHCircleShape() {
    const name = 'FH_CIRCLE';
    const {label, description, color} = getShapeDataByName(name);
    return new MarkupCircle(name, label, description, this.getFemoralHeadMarkupPoints(), color);
  }

  updateDependantMarkupPoints(addedMarkupPoint) {
    const dependants = this.getDependantsFromType(addedMarkupPoint.type);

    if (!dependants) {
      return;
    }

    const areTypesFulfilled = this.areTypesFulfilled.bind(this);
    const dependantPointsToAdd = dependants.filter(type => areTypesFulfilled(getPointDependencies(type)));

    if (dependantPointsToAdd) {
      const createDependantMarkupPoint = this.createDependantMarkupPoint.bind(this);
      const addMarkupPoint = this.addMarkupPoint.bind(this);
      dependantPointsToAdd.map(type => createDependantMarkupPoint(type))
                            .filter(point => point !== null)
                            .forEach(point => addMarkupPoint(point))
    }
  }

  getDependantsFromType(markupPointType) {
    const dependantPoints = this.getMarkupDependantPoints()
                            .filter(type => getPointDependencies(type).indexOf(markupPointType) >= 0);
    return dependantPoints && dependantPoints.length > 0 ? dependantPoints : null;
  }

  getMarkupDependantPoints() {
    const isDependentMarkupPoint = this.isDependentMarkupPoint.bind(this);
    return this._requiredPointTypes.filter(type => isDependentMarkupPoint(type));
  }

  createDependantMarkupPoint(markupPointType) {
    const {label, description, name, color, calc, refs} = getPointDataByType(markupPointType);
    switch (calc) {
      case MarkupCalculationTypes.CIRCLE_CENTER:
        return new CircleCenterMarkupPoint(markupPointType, label, name, description, this.getPointsOfTypes(refs), color);
      default:
        return null;
    }
  }

  shouldAddShape (addedPointType, pointTypesRequiredForShape) {
    return pointTypesRequiredForShape.indexOf(addedPointType) >= 0 && this.areTypesFulfilled(pointTypesRequiredForShape);
  }

  addShape(markupShape) {
    if (this._shapes.has(markupShape.type)) {
      throw new Error(`Duplicated shape ${markupShape.name}`);
    }

    this._shapes.set(markupShape.name, markupShape);
    this.dispatch(new MarkupDataEvent(MarkupDataEvent.MARKUP_SHAPE_ADDED, {markupShape}));
  }

  getMarkupPoint(type) {
    if (this._markupPoints.has(type)) {
      return this._markupPoints.get(type)
    }

    return null;
  }

  addMarkupSegment(markupSegment) {
    if (this._markupSegments.has(markupSegment.type)) {
      throw new Error(`Duplicated segment type ${markupSegment.type}`);
    }

    this._markupSegments.set(markupSegment.type, markupSegment);
    const addMarkupPoint = this.addMarkupPoint.bind(this);
    markupSegment.markupPoints.forEach(markupPoint => addMarkupPoint(markupPoint));

    this.updateStatus();
    this.dispatch(new MarkupDataEvent(MarkupDataEvent.MARKUP_SEGMENT_ADDED, {markupSegment}));
  }

  getMarkupSegment(type) {
    if (this._markupSegments.has(type)) {
      return this._markupSegments.get(type)
    }

    return null;
  }

  getNextObjectToInput () {
    const containsType = (arr, type) => {return arr.find(item => item.type === type) !== undefined};
    const getResult = (markupSegmentType = null, markupPointType = null) => {
      const type = markupSegmentType || markupPointType;
      if (!type) {
        return null;
      }

      const markupItemType = markupSegmentType ? InputTypes.SEGMENT : InputTypes.POINT;
      const getMarkupDataFunc = markupSegmentType ? getSegmentDataByType : getPointDataByType;
      const markupItemData = {...getMarkupDataFunc(type), type};
      return {markupItemType, markupItemData}
    }

    const {markupSegments, markupPoints} = this;
    const markupSegmentType = this._requiredSegmentTypes.find(type => !containsType(markupSegments, type));
    const isDependentMarkupPoint = this.isDependentMarkupPoint;
    const markupPointType = this._requiredPointTypes.find(type => !containsType(markupPoints, type) && !isDependentMarkupPoint(type));
    return getResult(markupSegmentType, markupPointType);
  }

  isDependentMarkupPoint(type) {
    const {refs} = getPointDataByType(type);
    return refs && refs.length > 0;
  }

  areTypesFulfilled(types) {
    const existingPoints = this._markupPoints;
    return types.every(type => existingPoints.get(type) !== undefined);
  }

  getPointsOfTypes(types) {
    const getMarkupPoint = this.getMarkupPoint.bind(this);
    return types.map(type => getMarkupPoint(type));
  }

  getCompletionProgress () {
    const fulfilled = this.getPointsOfTypes(this._requiredPointTypes).filter(markupPoint => markupPoint !== null).length;
    return Math.round((fulfilled / this._requiredPointTypes.length) * 100);
  }

  updateStatus () {
    this._isFulfilled = this.getNextObjectToInput() === null;
    this.dispatch(new MarkupDataEvent(MarkupDataEvent.STATUS_CHANGED, {isFulfilled: this._isFulfilled}));
  }

  getFemoralHeadMarkupPoints() {
    return this.getPointsOfTypes(femoralHeadPointTypes);
  }

  getFemoralHead() {
    const points = this.getFemoralHeadMarkupPoints();
    if (!points || points.length < 3) {
      throw new Error('Incorrect Femoral Head points');
    }

    return getCircleBy3Points(points.map(markupPoint => markupPoint.position));
  }

  toJSON () {
    const {imageData, markupPoints, markupSegments} = this;
    return {
      imageData: imageData.toJSON(),
      markupPoints: markupPoints.map(markupPoint => markupPoint.toJSON()),
      markupSegments: markupSegments.map(markupSegment => markupSegment.toJSON())
    };
  }

  fromJSON(jsonData) {
    this.clearEntireMarkup();

    const getErrorMgs = (err) => {
      return err;
    }

    if (!jsonData) {
      throw new Error(getErrorMgs('Data are empty'));
    }

    const {imageData, markupPoints, markupSegments} = jsonData;
    if (!imageData || !markupPoints || !markupSegments) {
      throw new Error(getErrorMgs('Incorrect data format'));
    }

    try {
      this.imageData.fromJSON(imageData);

      const getPoint = (position) => {
        const point = new Point();
        point.fromJSON(position);
        return point;
      }

      const addMarkupSegment = this.addMarkupSegment.bind(this);
      markupSegments.forEach(({type, points}) => {
        const segmentPoints = points.map(pointData => getPoint(pointData));

        const markupSegment = MarkupItemsFactory.getMarkupSegment(type, segmentPoints);
        if (markupSegment) {
          addMarkupSegment(markupSegment);
        }
      });

      const addMarkupPoint = this.addMarkupPoint.bind(this);
      markupPoints.forEach(({type, position}) => {
        const markupPoint = MarkupItemsFactory.getMarkupPoint(type, getPoint(position));
        if (markupPoint) {
          try {
            addMarkupPoint(markupPoint);
          }
          catch(err) {
            return;
          }
        }
      });

      this.dispatch(new MarkupDataEvent(MarkupDataEvent.MARKUP_DATA_IMPORTED));
    }
    catch(err) {
      throw new Error(getErrorMgs(err));
    }

  }

  destroy() {

  }
}

export default XRayMarkupData;