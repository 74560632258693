import {getAngleBetween} from "../../../utils/MathUtils";
import Line from '../../base/Line';
import Range from "../../base/Range";
import MainMeasureResult from "../MainMeasureResult";

class AngleV extends MainMeasureResult {
  constructor(refMarkupData) {
    super('ANGLE_V', refMarkupData);
  }

  recalculateValue() {
    const bPoint = this.getBMarkupPoint().position;
    const tearDropPoint = this.getClosestTearDropMarkupPoint(bPoint).position;

    const gPoint = this.getGPoint().position;
    const b1Point = this.getB1Point().position;
    const line1 = new Line(tearDropPoint, bPoint);
    const line2 = new Line(gPoint, b1Point);
    return Math.abs(getAngleBetween(line1, line2));
  }

  getGPoint() {
    return this.refMarkupData.getMarkupPoint('AC_BOTTOM_BACK');
  }

  getB1Point() {
    return this.refMarkupData.getMarkupPoint('AC_WEIGHT_BEARING_OUTER_EDGE');
  }

  recalculateShapes() {
    const bPoint = this.getBMarkupPoint();
    const tearDropPoint = this.getClosestTearDropMarkupPoint(bPoint.position);

    const gPoint = this.getGPoint();
    const b1Point = this.getB1Point();

    this.shapes.push(this.getLine('ab', [tearDropPoint, bPoint].map(markupPoint => markupPoint.type)));
    this.shapes.push(this.getLine('gb1', [gPoint, b1Point].map(markupPoint => markupPoint.type)));
  }

  getStandardRange() {
    // stub to override
    return new Range(15, 20);
  }
}

export default AngleV;