import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './MeasureHintViewer.scss';
import Size from "../../../model/base/Size";
import MarkupShapeView from "../MarkupShapeView/MarkupShapeView";
import MeasureResult from "../../../model/measure/MeasureResult";
import {MeasureResultEvent} from "../../../model/events/MeasureResultEvent";

const MeasureHintViewer = ({viewportSize, measureResult}) => {
  const [hintShapes, setHintShapes] = useState([]);

  const setupShapes = useCallback((shapes) => {
    setHintShapes([...shapes]);
  }, [setHintShapes]);

  const onMeasureShapesChanged = useCallback((event) => {
    setupShapes(event.payload.shapes);
  }, [setupShapes]);

  useEffect(() => {
    if (measureResult) {
      measureResult.addEventListener(MeasureResultEvent.RESULT_SHAPES_UPDATED, onMeasureShapesChanged);
      setupShapes(measureResult.shapes);
    }
    return () => {
      if (measureResult) {
        measureResult.removeEventListener(MeasureResultEvent.RESULT_SHAPES_UPDATED, onMeasureShapesChanged);
      }
    }
  }, [measureResult, onMeasureShapesChanged, setupShapes]);

  return (
    <div className="measure-hint-shapes-container"
         style={{width: `${viewportSize.width}px`, height: `${viewportSize.height}px`}}
    >
      {
       hintShapes.map(markupShape => {
          return (
            <MarkupShapeView
              key={markupShape.id}
              markupShape={markupShape}>
            </MarkupShapeView>
          );
        })
      }
    </div>
  );
};

MeasureHintViewer.propTypes = {
  viewportSize: PropTypes.instanceOf(Size).isRequired,
  measureResult: PropTypes.instanceOf(MeasureResult),
};

MeasureHintViewer.defaultProps = {};

export default MeasureHintViewer;
