import XRayMarkupData from "./XRayMarkupData";
import {
  abductionPointTypes,
  femoralHeadPointTypes,
  getShapeDataByName,
  lateralMarkupPointTypes,
  lateralMarkupSegmentTypes
} from "./MarkupPointTypes";
import MarkupLine from "./MarkupLine";

export class LateralXRayMarkupData extends XRayMarkupData {
  constructor() {
    super();
    this._requiredPointTypes = lateralMarkupPointTypes;
    this._requiredSegmentTypes = lateralMarkupSegmentTypes;
  }

  updateShapes(addedMarkupPoint) {
    const {type} = addedMarkupPoint;
    if (this.shouldAddShape(type, abductionPointTypes)) {
      this.addShape(this.getAbductionLineShape());
    }
    else if (this.shouldAddShape(type, femoralHeadPointTypes)) {
      this.addShape(this.getFHCircleShape());
    }
  }

  getAbductionLineShape() {
    const name = 'ABDUCTION_LINE';
    const {label, description, color} = getShapeDataByName(name);
    return new MarkupLine(name, label, description, this.getAbductionMarkupPoints(), color);
  }

  getAbductionMarkupPoints() {
    return this.getPointsOfTypes(abductionPointTypes);
  }

  getAbduction () {
    const [start, end] = this.getAbductionMarkupPoints();
    return {start, end};
  }

  getMarkupSideFromSagittalAxis () {
    const {end} = this.getAbduction();
    const abductionOutPoint = end.position;
    const {center:fhCenter} = this.getFemoralHead();

    if (abductionOutPoint.x === fhCenter.x) {
      return 0;
    }

    return (abductionOutPoint.x - fhCenter.x) / Math.abs(fhCenter.x - abductionOutPoint.x);
  }

}