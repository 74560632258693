import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './MarkupStep.scss';
import Upload from "../../controls/Upload/Upload";
import Box from "@mui/material/Box";
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import {getImageDataFromBinary} from "../../../utils/ImageUtils";
import XRayMarkupTool from "../../markup/XRayMarkupTool/XRayMarkupTool";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import MeasureCalculator from "../../../model/measure/MeasureCalculator";

const MarkupStep = ({xRayMarkupData, measureCalculator, xRayType, showPreliminaryDiagnosis}) => {
  const [isEmpty, setIsEmpty] = useState(xRayMarkupData.isEmpty);
  const [uploadedImageData, setUploadedImageData] = useState(null);

  useEffect(() => {
    const onStatusChanged = () => {
      setIsEmpty(xRayMarkupData.isEmpty);
    }

    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, onStatusChanged);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, onStatusChanged);

    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, onStatusChanged);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, onStatusChanged);
    }
  }, [xRayMarkupData]);

  useEffect(() => {
    if (xRayMarkupData && uploadedImageData) {
      xRayMarkupData.updateImage(uploadedImageData);
      setIsEmpty(xRayMarkupData.isEmpty);
    }
  }, [uploadedImageData, xRayMarkupData]);

  const onUpload = async (fileData) => {
    const imageData = await getImageDataFromBinary(fileData);
    if (imageData) {
      setUploadedImageData(imageData);
    }
  }

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      {
        isEmpty
          ? <Upload setUploadData={onUpload} xRayType={xRayType}></Upload>
          : <XRayMarkupTool xRayMarkupData={xRayMarkupData}
                            measureCalculator={measureCalculator}
                            showPreliminaryDiagnosis={showPreliminaryDiagnosis}></XRayMarkupTool>
      }
    </Box>
  );
};

MarkupStep.propTypes = {
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
  measureCalculator: PropTypes.instanceOf(MeasureCalculator).isRequired,
  xRayType: PropTypes.string.isRequired,
  showPreliminaryDiagnosis: PropTypes.bool,
};

MarkupStep.defaultProps = {
  showPreliminaryDiagnosis: false
};

export default MarkupStep;
