import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ClearMarkupData.scss';
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import {useTranslation} from "react-i18next";

const ClearMarkupData = ({xRayMarkupData}) => {
  const [markupPoints, setMarkupPoints] = useState(xRayMarkupData.markupPoints);
  const { t } = useTranslation();

  const onStatusChanged = useCallback(() => {
    setMarkupPoints(xRayMarkupData.markupPoints);
  }, [xRayMarkupData]);

  useEffect(() => {
    xRayMarkupData.addEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);

    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    }
  }, [xRayMarkupData, onStatusChanged]);

  const clearAllPoints = () => {
    xRayMarkupData.clearAllMarkupData();
  }

  const clearEntireMarkup = () => {
    xRayMarkupData.clearEntireMarkup();
  }

  return (
    <Stack direction="column" sx={{alignItems: 'stretch', whiteSpace: 'nowrap'}} spacing={1}>
      <Button size="small" onClick={clearAllPoints} variant="outlined" disabled={markupPoints.length === 0}>{t('general.clear_all_points')}</Button>
      <Button size="small" onClick={clearEntireMarkup} variant="outlined">{t('general.clear_markup')}</Button>
    </Stack>
  )
};

ClearMarkupData.propTypes = {
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
};

ClearMarkupData.defaultProps = {};

export default ClearMarkupData;
