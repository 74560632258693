import {getItemParams} from '../../utils/LocalizationUtils'
import {AgeType} from "../base/AgeRangeTypes";

const MarkupColors = {
  TEAR_DROP: '#00FF00',
  AC: '#f54242',
  AC_WB: '#f56642',
  AC_MARKUP: '#f54242',
  FH: '#42f5f5',
  FH_MARKUP: '#42f5f5',
  ABDUCTION: '#00FF00',
};

export const MarkupCalculationTypes = {
  CIRCLE_CENTER: 'circle_center',
}

const MarkupPointTypes = {
  TEAR_DROP_L: {name: "tear_drop_l", color: MarkupColors.TEAR_DROP},
  TEAR_DROP_R: {name: "tear_drop_r", color: MarkupColors.TEAR_DROP},
  AC_UP_OUTER: {name: "ac_up_outer", label: "b", color: MarkupColors.AC},
	AC_WEIGHT_BEARING_OUTER_EDGE: {name: "ac_weight_bearing_outer_edge", label: "b1", color: MarkupColors.AC_WB},
  AC_BOTTOM_BACK: {name: "ac_bottom_back", label: "g", color: MarkupColors.AC},
  AC_BOTTOM_MIDDLE: {name: "ac_bottom_middle", label: "d", color: MarkupColors.AC},
  AC_U_SHAPED_CARTILAGE_OUTER_EDGE: {name: "ac_u_shaped_cartilage_outer_edge", label: "v", color: MarkupColors.AC_WB},
  AC_WEIGHT_BEARING_BOTTOM_EDGE: {name: "ac_weight_bearing_bottom_edge", label: "v1", color: MarkupColors.AC_WB},
  AC_OUTER: {name: "ac_outer", label: 'ao', color: MarkupColors.AC},
  FH_INNER: {name: "fh_inner", color: MarkupColors.FH},
  FH_OUTER: {name: "fh_outer", color: MarkupColors.FH},
	FH_CENTER: {name: "fh_center", color: MarkupColors.FH},
  FH_CIRCLE_CENTER: {name: "fh_circle_center", color: MarkupColors.FH,
                    refs: ['FH_INNER', 'FH_OUTER', 'FH_CENTER'], calc: MarkupCalculationTypes.CIRCLE_CENTER},
  ABDUCTION_START: {name: "abduction_start", color: MarkupColors.ABDUCTION},
  ABDUCTION_END: {name: "abduction_end",  color: MarkupColors.ABDUCTION},
  AC_FRONTAL_UP_OUTER: {name: "ac_frontal_up_outer", color: MarkupColors.AC},
};

const MarkupSegmentTypes = {
  TEAR_DROP: {name: "tear_drop", color: MarkupColors.TEAR_DROP},
  LATERAL_ABDUCTION: {name: "lateral_abduction", color: MarkupColors.ABDUCTION},
};

const MarkupShapeTypes = {
  TEAR_DROP_LINE: {name: "tear_drop_line", color: MarkupColors.TEAR_DROP},
  AC_CIRCLE: {name: "ac_circle", color: MarkupColors.AC_MARKUP},
  FH_CIRCLE: {name: "fh_circle", color: MarkupColors.FH_MARKUP},
  ABDUCTION_LINE: {name: "abduction_line", color: MarkupColors.ABDUCTION},
};

export const getPointDataByType = (type) => {
  if (!MarkupPointTypes[type]) {
    return {};
  }

  const {name, ...rest} = MarkupPointTypes[type] ;
  const prefix = "markup_points";
  return {...rest, type, ...getItemParams(name, prefix)};
}

export const getSegmentDataByType = (type) => {
  if (!MarkupSegmentTypes[type]) {
    return {};
  }

  const {name, ...rest} = MarkupSegmentTypes[type] ;
  const prefix = "markup_segments";
  return {...rest, type, ...getItemParams(name, prefix)};
}

export const getShapeDataByName = (shapeName) => {
  if (!MarkupShapeTypes[shapeName]) {
    return {};
  }

  const {name, ...rest} = MarkupShapeTypes[shapeName] ;
  const prefix = "markup_shapes";
  return {...rest, ...getItemParams(name, prefix)};
}

export const getPointDependencies = (type) => {
  const pointData = getPointDataByType(type);
  return pointData.refs || null;
}

export const tearDropPointTypes = ['TEAR_DROP_R', 'TEAR_DROP_L'];
export const acetabulumPointTypes = ['AC_OUTER', 'AC_UP_OUTER', 'AC_BOTTOM_MIDDLE'];
export const femoralHeadPointTypes = ['FH_INNER', 'FH_OUTER', 'FH_CENTER'];
export const abductionPointTypes = ['ABDUCTION_START', 'ABDUCTION_END'];

const getWeightBearingCalculationPoints = (age) => age === AgeType.R12_ ? 'AC_WEIGHT_BEARING_BOTTOM_EDGE' : 'AC_U_SHAPED_CARTILAGE_OUTER_EDGE';
// Main markup doesn't contain abduction points
export const getMainMarkupPointTypes = (age) => {
  return [...tearDropPointTypes, ...acetabulumPointTypes,
          'AC_BOTTOM_BACK',
          ...femoralHeadPointTypes,
          'AC_WEIGHT_BEARING_OUTER_EDGE', getWeightBearingCalculationPoints(age)];
}
export const mainMarkupSegmentTypes = ['TEAR_DROP'];
export const lateralMarkupPointTypes = [...abductionPointTypes, ...femoralHeadPointTypes, 'FH_CIRCLE_CENTER', 'AC_UP_OUTER'];
export const lateralMarkupSegmentTypes = ['LATERAL_ABDUCTION'];
export const frontalMarkupPointTypes = [...femoralHeadPointTypes, 'FH_CIRCLE_CENTER', 'AC_FRONTAL_UP_OUTER'];