import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './InputListener.scss';
import GlobalModelProvider from "../../../contexts/GlobalModelContext";
import {InputEvent} from "../../../model/events/InputEvent";
import Point from "../../../model/base/Point";
import {InputTypes} from "../../../model/base/InputTypes";
import Size from "../../../model/base/Size";
import SegmentInput from "../SegmentInput/SegmentInput";

const InputListener = ({viewportSize}) => {
  const [waitingInputId, setWaitingInputId] = useState(null);
  const [inputType, setInputType] = useState(null);
  const clickPan = useRef(null);
  const [clickPanElem, setClickPanElem] = useState(null);
  const {eventDispatcher} = useContext(GlobalModelProvider);

  const resetWaiting = useCallback(() => {
    setWaitingInputId(null);
  }, []);

  useEffect(() => {
    setClickPanElem(clickPan && clickPan.current ? clickPan.current : null);
  }, [clickPan]);

  useEffect(() => {
    return () => {
      resetWaiting();
    }
  }, [resetWaiting]);

  useEffect(() => {
    if (!waitingInputId) {
      setInputType(null);
    }
  }, [waitingInputId]);

  const cancelInput = useCallback(() => {
    eventDispatcher.dispatch(new InputEvent(InputEvent.CANCELED_INPUT, {inputId: waitingInputId}));
    resetWaiting();
  }, [eventDispatcher, resetWaiting, waitingInputId]);

  const onInputRequestCancel = useCallback(() => {
    cancelInput();
  }, [cancelInput]);

  const onInputReady = useCallback((points) => {
    if (!points || !points.length) {
      throw new Error('Input is empty');
    }

    const inputId = waitingInputId;
    if (inputType === InputTypes.POINT) {
      eventDispatcher.dispatch(new InputEvent(InputEvent.ADDED_POINT, {inputId, point: points[0]}));
    }
    else if (inputType === InputTypes.SEGMENT) {
      if (points.length < 2) {
        throw new Error('Segment input should have at least 2 points');
      }

      eventDispatcher.dispatch(new InputEvent(InputEvent.ADDED_SEGMENT, {inputId, point1: points[0], point2: points[1]}));
    }

    resetWaiting();
  }, [waitingInputId, eventDispatcher, inputType, resetWaiting]);

  const onPanClick = useCallback((event) => {
    const {offsetX, offsetY} = event;
    onInputReady([new Point(offsetX, offsetY)]);
  }, [onInputReady]);

  const onKeyUp = useCallback((event) => {
    if (event.which === 27) {
      cancelInput();
    }
  }, [cancelInput]);

  const addInputEventListeners = useCallback(() => {
    if (!clickPanElem) {
      return;
    }
    clickPanElem.addEventListener('mouseup', onPanClick);
    document.addEventListener('keyup', onKeyUp);
    eventDispatcher.addEventListener(InputEvent.CANCEL_INPUT, onInputRequestCancel);
  }, [clickPanElem, eventDispatcher, onInputRequestCancel, onPanClick, onKeyUp]);

  const removeInputEventListeners = useCallback(() => {
    if (!clickPanElem) {
      return;
    }

    clickPanElem.removeEventListener('mouseup', onPanClick);
    document.removeEventListener('keyup', onKeyUp);
    eventDispatcher.removeEventListener(InputEvent.CANCEL_INPUT, onInputRequestCancel);
  }, [clickPanElem, eventDispatcher, onInputRequestCancel, onPanClick, onKeyUp]);

  const onInputRequest = useCallback((event) => {
    const {inputId} = event.payload;
    const {eventType} = event;

    if (eventType === InputEvent.INPUT_POINT) {
      setInputType(InputTypes.POINT);
    }
    else {
      setInputType(InputTypes.SEGMENT);
    }

    setWaitingInputId(inputId);
  }, []);

  const addInputRequestEventListeners = useCallback(() => {
    eventDispatcher.addEventListener(InputEvent.INPUT_POINT, onInputRequest);
    eventDispatcher.addEventListener(InputEvent.INPUT_SEGMENT, onInputRequest);
  }, [onInputRequest, eventDispatcher]);

  const removeInputRequestEventListeners = useCallback(() => {
    eventDispatcher.removeEventListener(InputEvent.INPUT_POINT, onInputRequest);
    eventDispatcher.removeEventListener(InputEvent.INPUT_SEGMENT, onInputRequest);
  }, [onInputRequest, eventDispatcher]);

  useEffect(() => {
    if (waitingInputId && inputType) {
      // wait for user input
      removeInputRequestEventListeners();
      addInputEventListeners();
    }

    if (!waitingInputId && !inputType) {
      // wait for the next input request
      removeInputEventListeners();
      addInputRequestEventListeners();
    }
  }, [waitingInputId, inputType,
      addInputRequestEventListeners, addInputEventListeners,
      removeInputRequestEventListeners, removeInputEventListeners]);

  useEffect(() => {
    return () => {
      removeInputEventListeners();
      removeInputRequestEventListeners();
    }
  }, [clickPan, removeInputEventListeners, removeInputRequestEventListeners])

  return (
    <div className={`click-pan ${waitingInputId !== null ? 'waiting-input' : ''}`}
         ref={clickPan} style={{width: `${viewportSize.width}px`, height: `${viewportSize.height}px`}}>
      {
        inputType === InputTypes.SEGMENT
          ? (<SegmentInput viewportSize={viewportSize} onSegmentReady={onInputReady}></SegmentInput>)
          : null
      }

    </div>
  )
};

InputListener.propTypes = {
  viewportSize: PropTypes.instanceOf(Size).isRequired,
};

InputListener.defaultProps = {};

export default InputListener;
