import MarkupSegment from "./MarkupSegment";
import {getSegmentDataByType, tearDropPointTypes} from "./MarkupPointTypes";

class TearDropSegment extends MarkupSegment {
  constructor(points=[]) {
    const type = 'TEAR_DROP';
    const {name, description, color} = getSegmentDataByType(type);
    super(type, name, description, points, color);
  }

  createMarkupPoints(points) {
    if (!points || points.length < 2) {
      throw new Error('Not enough data for TearDropSegment segment');
    }

    const sortedPoints = this.sortByXCoord(points);
    const markupPointTypes = tearDropPointTypes;
    const getMarkupPoint = this.getMarkupPoint;
    return markupPointTypes.map((type, index) => {
      return getMarkupPoint(type, sortedPoints[index]);
    });
  }

  sortByXCoord(points) {
    return points.sort((p1, p2) => {
      return p1.x - p2.x;
    });
  }

}

export default TearDropSegment;