import {acetabulumPointTypes, tearDropPointTypes} from "../../markup/MarkupPointTypes";
import Range from "../../base/Range";
import MainMeasureResult from "../MainMeasureResult";

class ISA extends MainMeasureResult {
  constructor(refMarkupData) {
    super('ISA', refMarkupData);
  }

  recalculateValue() {
    // Tear drop
    const tearDrop = this.getTearDrop();

    // Acetabulum
    const acetabulum = this.getAcetabulum();

    return (acetabulum.radius * 2) / (tearDrop.length / 2);
  }

  recalculateShapes() {
    this.shapes.push(this.getLine('tearDrop', tearDropPointTypes));
    this.shapes.push(this.getCircle('ac', acetabulumPointTypes, 'Ra'));
  }

  getStandardRange() {
    // stub to override
    return new Range(0.8, 1.24);
  }

}

export default ISA;