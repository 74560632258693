import MeasureCalculator from "./MeasureCalculator";
import ISH from "./results/ISH";
import ISA from "./results/ISA";
import ICAS from "./results/ICAS";
import AngleA from "./results/AngleA";
import AngleB from "./results/AngleB";
import AngleV from "./results/AngleV";
import AcCoeff from "./results/AcCoeff";
import DH from "./results/DH";
import {AgeType} from "../base/AgeRangeTypes";
import AngleB1 from "./results/AngleB1";

class MainMeasureCalculator extends MeasureCalculator {
   recreateMeasureResults() {
    return [
      new DH(this.markupData),
      new ISH(this.markupData),
      new ISA(this.markupData),
      new ICAS(this.markupData),
      new AngleA(this.markupData),
      (this.markupData.age === AgeType.R12_ ? new AngleB(this.markupData) : new AngleB1(this.markupData)),
      new AngleV(this.markupData),
      new AcCoeff(this.markupData),
    ];
  }
}

export default MainMeasureCalculator;