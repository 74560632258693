class Step {
  constructor(id, stepId, name, description, isCompleted = false) {
    this.id = id;
    this.stepId = stepId;
    const prefix = 'steps';
    this.name = `${prefix}.${stepId}.name`;
    this.description = `${prefix}.${stepId}.descr`;
    this.isCompleted = isCompleted;
  }
}

export default Step;