import React, {useContext} from 'react';
import './StepsStack.scss';
import Box from '@mui/material/Box';
import StepsProvider from "../../../contexts/StepsContext";
import StepPanel from "../StepPanel/StepPanel";
import MainMarkupStep from "../MainMarkupStep/MainMarkupStep";
import LateralMarkupStep from "../LateralMarkupStep/LateralMarkupStep";
import FrontalMarkupStep from "../FrontalMarkupStep/FrontalMarkupStep";
import DiagnosisView from "../../diagnosis/DiagnosisView/DiagnosisView";
import Age from "../../controls/Age/Age";
import AboutMethod from "../../controls/AboutMethod/AboutMethod";

const StepsStack = () => {
  const {currentStep} = useContext(StepsProvider);

  if (!currentStep) {
    return null;
  }

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <StepPanel value={currentStep.id} index={0}>
        <AboutMethod/>
      </StepPanel>
      <StepPanel value={currentStep.id} index={1}>
        <Age/>
      </StepPanel>
      <StepPanel value={currentStep.id} index={2}>
        <MainMarkupStep></MainMarkupStep>
      </StepPanel>
      <StepPanel value={currentStep.id} index={3}>
        <LateralMarkupStep></LateralMarkupStep>
      </StepPanel>
      <StepPanel value={currentStep.id} index={4}>
        <FrontalMarkupStep></FrontalMarkupStep>
      </StepPanel>
      <StepPanel value={currentStep.id} index={5}>
        <DiagnosisView fullDiagnosis={true} showComments={true}></DiagnosisView>
      </StepPanel>
    </Box>
  );
};

StepsStack.propTypes = {};

StepsStack.defaultProps = {};

export default StepsStack;
