import Point from "./Point";
import {getDistance} from "../../utils/MathUtils";

class Segment {
  constructor(start = new Point(), end = new Point()) {
    this.start = start;
    this.end = end;
  }

  get length () {
    return getDistance(this.start, this.end);
  }

  clone () {
    return new Segment(this.start.clone(), this.end.clone());
  }
}

export default Segment;