import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './DiagnosisView.scss';
import MarkupModelProvider from "../../../contexts/MarkupModelContext";
import {MeasureResultEvent} from "../../../model/events/MeasureResultEvent";
import {getDiagnosisCase} from "../../../model/diagnosis/DiagnosisCase";
import Stack from "@mui/material/Stack";
import DiagnosisItemView from "../DiagnosisItemView/DiagnosisItemView";
import DiagnosisList from "../DiagnosisList/DiagnosisList";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const DiagnosisView = ({fullDiagnosis, showComments}) => {
  const {t} = useTranslation();
  const [diagnosisCase, setDiagnosisCase] = useState(null);
  const [allMeasureResultsMap, setAllMeasureResultsMap] = useState(null);
  const [mainMeasureResultsMap, setMainMeasureResultsMap] = useState(null);
  const {mainXRayData, lateralXRayData, frontalXRayData, age} = useContext(MarkupModelProvider);
  const {calculator: mainCalculator} = mainXRayData;
  const {calculator: lateralCalculator} = lateralXRayData;
  const {calculator: frontalCalculator} = frontalXRayData;
  const allCalculators = useMemo(() => [mainCalculator, lateralCalculator, frontalCalculator],
                                        [mainCalculator, lateralCalculator, frontalCalculator]);

  const getResultsMap = (results) => {
    return new Map(
      results.map(measureResult => {
        return [measureResult.type, measureResult];
      }),
    );
  }

  const onResultsUpdated = useCallback(() => {
    if (!mainCalculator.measureResults || !mainCalculator.measureResults.length) {
      setDiagnosisCase(null);
      return;
    }

    const resultsMap = getResultsMap(mainCalculator.measureResults);
    const diagnosis = getDiagnosisCase(age, resultsMap);
    setDiagnosisCase(diagnosis);
    setMainMeasureResultsMap(resultsMap);

  }, [mainCalculator, age, setMainMeasureResultsMap]);

  const onAllResultsUpdated = useCallback(() => {
    const allReady = allCalculators.every(calculator => {
      return calculator.measureResults && calculator.measureResults.length;
    });

    if (allReady) {
      const allResults = allCalculators.reduce((acc, calculator) => {
        acc = [...acc, ...calculator.measureResults];
        return acc;
      }, []);

      setAllMeasureResultsMap(getResultsMap(allResults));
    } else {
      setAllMeasureResultsMap(null);
    }

  }, [allCalculators]);

  useEffect(() => {
    if (!allCalculators.every(calc => calc !== null) || !fullDiagnosis) {
      return;
    }

    allCalculators.forEach(calc => {
      calc.addEventListener(MeasureResultEvent.RESULTS_UPDATED, onAllResultsUpdated);
    });

    onAllResultsUpdated();

    return () => {
      allCalculators.forEach(calc => {
        calc.removeEventListener(MeasureResultEvent.RESULTS_UPDATED, onAllResultsUpdated);
      });
    }
  }, [allCalculators, onAllResultsUpdated, fullDiagnosis]);

  useEffect(() => {
    mainCalculator.addEventListener(MeasureResultEvent.RESULTS_UPDATED, onResultsUpdated);
    onResultsUpdated();

    return () => {
      mainCalculator.removeEventListener(MeasureResultEvent.RESULTS_UPDATED, onResultsUpdated);
    }
  }, [mainCalculator, onResultsUpdated]);

  if (!diagnosisCase) {
    if (!showComments) {
      return null;
    }
    else {
      return (<Alert severity="error">{t('general.not_enough_data_for_diagnosis')}</Alert>);
    }
  }

  return (
    <Stack sx={{width: '100%', overflow: 'auto', position: 'relative'}} direction="column">
      <Stack direction="row" sx={{width: '100%', justifyContent: 'space-between', alignItems: 'flex-end',
                                  paddingRight: '10px', boxSizing: 'border-box'}}>
        <Typography sx={{fontSize: 12, fontWeight: 'bold', textDecoration: 'underline'}} color="text.secondary"
                      gutterBottom>
          {
            !allMeasureResultsMap
              ? t('general.preliminary_diagnosis')
              : t('general.final_diagnosis')
          }
        </Typography>
        <DiagnosisList currentDiagnosis={diagnosisCase}
                       allMeasureResultsMap={mainMeasureResultsMap}></DiagnosisList>
      </Stack>
      <DiagnosisItemView diagnosisCase={diagnosisCase}
                         allMeasureResultsMap={allMeasureResultsMap}></DiagnosisItemView>
      {
        showComments && !allMeasureResultsMap
          ? <Alert severity="warning">{t('general.input_data_for_full_diagnosis')}</Alert>
          : null
      }
    </Stack>
  )
};

DiagnosisView.propTypes = {
  fullDiagnosis: PropTypes.bool,
};

DiagnosisView.defaultProps = {
  fullDiagnosis: false,
  showComments: false,
};

export default DiagnosisView;
