import Point from "./Point";
class Line {
  constructor(p1, p2) {
    this.p1 = p1;
    this.p2 = p2;
  }

  get coeff () {
    const {p1, p2} = this;
    if (p2.x === p1.x) {
      return undefined;
    }
    return (p2.y - p1.y) / (p2.x - p1.x);
  }

  get b () {
    const {p1} = this;
    return p1.y - this.coeff * p1.x;
  }

  getValue (x) {
    return this.coeff * x + this.b;
  }

  getIntersection (line) {
    if (this.coeff === line.coeff) {
      return null;
    }

    const x = (line.b - this.b) / (this.coeff - line.coeff);
    const y = this.coeff * x + this.b;

    return new Point(x, y);
  }
}

export default Line;