import Line from '../../base/Line';
import MarkupAxisLine from "../../markup/MarkupAxisLine";
import MainMeasureResult from "../MainMeasureResult";

class AngleB1 extends MainMeasureResult {
  constructor(refMarkupData) {
    super('ANGLE_B1', refMarkupData);
    this.dependsOnMarkupDirection = true;
  }

   recalculateValue() {
    const vPoint = this.getVMarkupPoint().position;
    const b1Point = this.getB1MarkupPoint().position;
    const line = new Line(vPoint, b1Point);
    return this.getAngleWithHorizontalDirection(line);
  }

  getVMarkupPoint() {
    return this.refMarkupData.getMarkupPoint('AC_U_SHAPED_CARTILAGE_OUTER_EDGE');
  }

  recalculateShapes() {
    const vPoint = this.getVMarkupPoint();
    const b1Point = this.getB1MarkupPoint();

    this.shapes.push(this.getAxisLine('v1Axis', vPoint.type, MarkupAxisLine.HORIZONTAL_DIRECTION, 200 * this.getHAxisDirection()));
    this.shapes.push(this.getLine('v1b1', [vPoint, b1Point].map(markupPoint => markupPoint.type)));
  }
}

export default AngleB1;