import React from 'react';
import PropTypes from 'prop-types';
import './Zoom.scss';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import ResetButton from "../ResetButton/ResetButton";

const Zoom = ({zoomLevel, onZoomLevelChange, minZoom, maxZoom, defaultZoomLevel}) => {

  const handleZoomChange = (event, newValue) => {
     onZoomLevelChange(newValue);
  }

  const resetZoomLevel = () => {
    onZoomLevelChange(defaultZoomLevel);
  }

  return (
    <Stack sx={{width: '100%', alignItems: 'center'}} spacing={2} direction="row">
      <ZoomInIcon size="small"></ZoomInIcon>
      <Slider size="small" value={zoomLevel} step={0.01} min={minZoom} max={maxZoom}
              onChange={handleZoomChange}></Slider>
      <ResetButton onResetClick={resetZoomLevel}></ResetButton>
    </Stack>)
};

Zoom.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  onZoomLevelChange: PropTypes.func.isRequired,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  defaultZoomLevel: PropTypes.number,
};

Zoom.defaultProps = {
  minZoom: 0,
  maxZoom: 1,
  defaultZoomLevel: 0,
};

export default Zoom;
