import React from "react";
import './App.scss';
import MainWrapper from "./components/MainWrapper/MainWrapper";
import MainShell from "./components/MainShell/MainShell";

function App() {
  return (
      <MainWrapper>
          <MainShell></MainShell>
      </MainWrapper>
  );
}

export default App;
