import React, {useContext} from 'react';
import './ChangeLanguage.scss';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useTranslation} from "react-i18next";
import GlobalModelProvider from "../../../contexts/GlobalModelContext";

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const {settings} = useContext(GlobalModelProvider);

  function changeLanguage(event) {
    i18n.changeLanguage(event.target.value);
  }

  const {resolvedLanguage:language, languageList:languages} = i18n;

  if (!settings.showLanguageOptions) {
    return null;
  }

  return (
    <Select value={language} onChange={changeLanguage} autoWidth size="small" sx={{width: 'min-content', fontSize: '0.75rem', lineHeight: '1rem'}}>
      {
        languages.map((lang => {
          return (<MenuItem value={lang} key={lang}>{lang}</MenuItem>);
        }))
      }
    </Select>);
};

ChangeLanguage.propTypes = {};

ChangeLanguage.defaultProps = {};

export default ChangeLanguage;
