import {PointEvent} from "./events/PointEvent";
import {EventDispatcher} from "./EventDispatcher";

export class SelectionManager extends EventDispatcher {
  constructor(eventDispatcher) {
    super();
    this.eventDispatcher = eventDispatcher;
    this.selectedPoint = null;

    this.addEventListeners();
  }

  addEventListeners() {
    this.eventDispatcher.addEventListener(PointEvent.SELECT_POINT, this.onSelectPoint);
    this.eventDispatcher.addEventListener(PointEvent.DESELECT_ALL_POINTS, this.clearSelection);
  }

  removeEventListeners() {
    this.eventDispatcher.removeEventListener(PointEvent.SELECT_POINT, this.onSelectPoint);
    this.eventDispatcher.removeEventListener(PointEvent.DESELECT_ALL_POINTS, this.clearSelection);
  }

  selectPoint(point) {
    this.selectedPoint = point;
    this.dispatchSelectedPointEvent();
  }

  onSelectPoint(event) {
    const {point} = event.payload;
    this.selectPoint(point);
  }

  clearSelection() {
    this.selectPoint(null);
  }

  dispatchSelectedPointEvent() {
    const {selectedPoint} = this;
    this.dispatch(new PointEvent(PointEvent.POINT_SELECTED, {selectedPoint}))
  }

  destroy() {
    this.clearSelection();
    this.removeEventListeners();
    this.eventDispatcher = null;
  }
}