import {getPerpendicularToLine} from "../../../utils/MathUtils";
import Line from '../../base/Line';
import Segment from "../../base/Segment";
import Range from "../../base/Range";
import MainMeasureResult from "../MainMeasureResult";

class AcCoeff extends MainMeasureResult {
  constructor(refMarkupData) {
    super('AC_COEFF', refMarkupData);
  }

  recalculateValue() {
    const bPoint = this.getBMarkupPoint().position;
    const tearDropPoint = this.getClosestTearDropMarkupPoint(bPoint).position;
    const lineAB = new Line(tearDropPoint, bPoint);

    const dPoint = this.getDMarkupPoint().position;
    const segmentD = getPerpendicularToLine(lineAB, dPoint);
    const segmentW = new Segment(tearDropPoint, bPoint);

    if (segmentW.length === 0) {
      throw new Error('Length of AB line is 0');
    }

    return (segmentD.length / segmentW.length) * 1000;
  }

  getDMarkupPoint() {
    return this.refMarkupData.getMarkupPoint('AC_BOTTOM_MIDDLE');
  }

  recalculateShapes() {
    const bPoint = this.getBMarkupPoint();
    const tearDropPoint = this.getClosestTearDropMarkupPoint(bPoint.position);
    const dPoint = this.getDMarkupPoint();

    this.shapes.push(this.getLine('ab', [tearDropPoint, bPoint].map(markupPoint => markupPoint.type)));
    this.shapes.push(this.getPerpendicularLine('d', [dPoint, tearDropPoint, bPoint].map(markupPoint => markupPoint.type)));
  }

  getStandardRange() {
    return new Range(250);
  }
}

export default AcCoeff;