import React from 'react';
import './StepPanel.scss';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StepPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className="tab-panel"
      {...other}
    >
      {value === index && (
        <Box sx={{width: '100%', height: '100%'}}>
         {children}
        </Box>
      )}
    </div>
  );
};

StepPanel.propTypes = {};

StepPanel.defaultProps = {};

export default StepPanel;
