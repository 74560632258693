import React from 'react';
import PropTypes from 'prop-types';
import './Contrast.scss';
import ContrastIcon from '@mui/icons-material/Contrast';
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import ResetButton from "../ResetButton/ResetButton";

const Contrast = ({contrastLevel, onContrastLevelChange, minContrast, maxContrast, defaultContrastLevel}) => {

  const handleContrastChange = (event, newValue) => {
     onContrastLevelChange(newValue);
  }

  const resetContrastLevel = () => {
    onContrastLevelChange(defaultContrastLevel);
  }

  return (
    <Stack sx={{width: '100%', alignItems: 'center'}} spacing={2} direction="row">
      <ContrastIcon size="small"></ContrastIcon>
      <Slider size="small" value={contrastLevel} step={0.01} min={minContrast} max={maxContrast}
              onChange={handleContrastChange}></Slider>
      <ResetButton onResetClick={resetContrastLevel}></ResetButton>
    </Stack>)
};

Contrast.propTypes = {
  contrastLevel: PropTypes.number.isRequired,
  onContrastLevelChange: PropTypes.func.isRequired,
  minContrast: PropTypes.number,
  maxContrast: PropTypes.number,
  defaultContrastLevel: PropTypes.number,
};

Contrast.defaultProps = {
  minContrast: 0.5,
  maxContrast: 2,
  defaultContrastLevel: 1,
};

export default Contrast;
