import {MarkupDataEvent} from "../events/MarkupDataEvent";
import {MarkupPointEvent} from "../events/MarkupPointEvent";
import {EventDispatcher} from "../EventDispatcher";
import {MeasureResultEvent} from "../events/MeasureResultEvent";

class MeasureCalculator extends EventDispatcher {
  constructor(markupData) {
    super();
    this._markupData = markupData;
    this._measureResults = null;
    this._measureTypes = [];

    this.onMarkupDataStatusChangedProxy = this.onMarkupDataStatusChanged.bind(this);
    this.onMarkupDataClearedProxy = this.onMarkupDataCleared.bind(this);

    this._markupData.addEventListener(MarkupDataEvent.STATUS_CHANGED, this.onMarkupDataStatusChangedProxy);
    this._markupData.addEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, this.onMarkupDataClearedProxy);
  }

  get markupData () {
    return this._markupData;
  }

  get measureResults () {
    return this._measureResults;
  }

  updateMeasureResults (value) {
    this._measureResults = value;
    this.dispatch(new MeasureResultEvent(MeasureResultEvent.RESULTS_UPDATED));
  }

  onMarkupDataStatusChanged() {
    if (!this.markupData.isFulfilled) {
      return;
    }

    if (!this.measureResults) {
      this.updateMeasureResults(this.recreateMeasureResults());
      this.addPointEventListeners();
    }

    this.recalculate();
  }

  addPointEventListeners() {
    const recalculate = this.recalculate.bind(this);

    this.markupData.markupPoints.forEach(markupPoint => {
      markupPoint.addEventListener(MarkupPointEvent.POSITION_CHANGED, recalculate);
    });
  }

  removePointEventListeners() {
    const recalculate = this.recalculate.bind(this);

    this.markupData.markupPoints.forEach(markupPoint => {
      markupPoint.removeEventListener(MarkupPointEvent.POSITION_CHANGED, recalculate);
    });
  }

  onMarkupDataCleared() {
    this.removePointEventListeners();
    if (this.measureResults) {
      this.measureResults.forEach(measureResult => measureResult.destroy());
    }

    this.updateMeasureResults(null);
  }

  recreateMeasureResults() {
    // stub to override
  }

  recalculate() {
    this.measureResults.forEach(measureResult => measureResult.recalculate());
    this.dispatch(new MeasureResultEvent(MeasureResultEvent.RESULTS_UPDATED, {}));
  }

  destroy () {
    this.onMarkupDataCleared();
    this._markupData.removeEventListener(MarkupDataEvent.STATUS_CHANGED, this.onMarkupDataStatusChangedProxy);
    this._markupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, this.onMarkupDataClearedProxy);
  }

}

export default MeasureCalculator;