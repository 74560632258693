import React from 'react';
import PropTypes from 'prop-types';
import './ControlPanel.scss';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const ControlPanel = ({children}) => {
  return (
    <Box className="control-panel">
      <Stack sx={{width: '100%'}} spacing={2} alignItems="center" direction="row">
        {children}
      </Stack>
    </Box>
  );
};

ControlPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ControlPanel.defaultProps = {};

export default ControlPanel;
