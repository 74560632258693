import Size from "./Size";

export class ImageData {
  constructor(data = null, size = new Size()) {
      this.data = data;
      this.size = size;
  }

  isEmpty () {
      return this.data === null;
  }

  update ({data, size}) {
      this.data = data;
      this.size.update(size);
  }

  toJSON() {
    const {data, size} = this;
    return {data, size: size.toJSON()};
  }

  fromJSON(jsonData) {
    this.update(jsonData);
  }
}
