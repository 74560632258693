import {femoralHeadPointTypes, tearDropPointTypes} from "../../markup/MarkupPointTypes";
import Range from "../../base/Range";
import MainMeasureResult from "../MainMeasureResult";

class ISH extends MainMeasureResult {
  constructor(refMarkupData) {
    super('ISH', refMarkupData);
  }

  recalculateValue() {
    // Tear drop
    const tearDrop = this.getTearDrop();

    // Femoral head
    const femoralHead = this.getFemoralHead();

    return (femoralHead.radius * 2) / (tearDrop.length / 2);
  }

  recalculateShapes() {
    this.shapes.push(this.getLine('tearDrop', tearDropPointTypes));
    this.shapes.push(this.getCircle('femoral', femoralHeadPointTypes, 'Rh'));
  }

  getStandardRange() {
    return new Range(0.6, 1);
  }
}

export default ISH;