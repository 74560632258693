import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './MarkupProgress.scss';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import {getPointDataByType} from "../../../model/markup/MarkupPointTypes";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const MarkupProgress = ({xRayMarkupData, onNextPointClick}) => {
  const [currStep, setCurrStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [completed, setCompleted] = useState({});
  const [visiblePoints, setVisiblePoints] = useState({});
  const { t } = useTranslation();

  const arrToHash = (arr) => arr.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  const updateProgress = useCallback(() => {
    setCurrStep(xRayMarkupData.fulfilledPointTypes.length);
    setCompleted(arrToHash(xRayMarkupData.fulfilledPointTypes));
    setVisiblePoints(arrToHash(xRayMarkupData.visiblePointTypes));

  }, [xRayMarkupData]);

  useEffect(() => {
    setSteps(xRayMarkupData.allRequiredPointTypes.map(type => getPointDataByType(type)));
    updateProgress();

    xRayMarkupData.addEventListener(MarkupDataEvent.STATUS_CHANGED, updateProgress);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, updateProgress);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, updateProgress);

    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.STATUS_CHANGED, updateProgress);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, updateProgress);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, updateProgress);
    };
  }, [xRayMarkupData, updateProgress]);

  const toggleVisibility = (type) => {
    if (!completed[type]) {
      onNextPointClick(type);
      return;
    }

    const markupPoint = xRayMarkupData.getMarkupPoint(type);
    markupPoint.updateVisibility(!markupPoint.visible);
    setVisiblePoints(arrToHash(xRayMarkupData.visiblePointTypes));
  }

  const getTooltip = (type, isActive) => {
    if (isActive) {
      return 'general.add_point';
    }

    if (!completed[type]) {
      return '';
    }

    return (visiblePoints[type] ? 'general.hide_point' : 'general.show_point');
  }

  const isActiveStep = (type) => {
    return (currStep !== null && steps[currStep] && steps[currStep].type === type)
  }

  return (
    <Stepper alternativeLabel
      activeStep={currStep}
      sx={{width: '100%'}}
      className="markup-progress"
    >
      {steps.map(({label, type}) => (
          <Step key={label} size="small"
                value={type}
                sx={{opacity: (completed[type] === true && visiblePoints[type] !== true ? 0.6 : 1), cursor: 'pointer'}}
                onClick={() => toggleVisibility(type)}
          >
           <Tooltip title={t(getTooltip(type, isActiveStep(type)), {label: t(label)})}>
            <StepLabel icon={isActiveStep(type) ? (<RadioButtonCheckedIcon color="primary" />) : null}>{t(label)}</StepLabel>
           </Tooltip>
          </Step>
      ))}
    </Stepper>
  );
};

MarkupProgress.propTypes = {};

MarkupProgress.defaultProps = {
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
  onNextPointClick: PropTypes.func.isRequired,
};

export default MarkupProgress;
