import React from 'react';
import PropTypes from 'prop-types';
import './ResetButton.scss';
import CropFreeIcon from "@mui/icons-material/CropFree";
import IconButton from "@mui/material/IconButton";

const ResetButton = ({onResetClick}) => {
  return (
    <IconButton size="small" onClick={onResetClick} color="primary">
      <CropFreeIcon/>
    </IconButton>)
};

ResetButton.propTypes = {
  onResetClick: PropTypes.func.isRequired,
};

ResetButton.defaultProps = {};

export default ResetButton;
