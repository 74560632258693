import MarkupPoint from "./MarkupPoint";
import {MarkupPointEvent} from "../events/MarkupPointEvent";

class DependantMarkupPoint extends MarkupPoint {
  constructor(type, label, name, description, refMarkupPoints, color) {
    super(type, label, name, description, null, color);
    this.refMarkupPoints = refMarkupPoints;
    this.addRefPointEventListeners();
    this.updatePosition();
  }

  addRefPointEventListeners () {
    const updatePosition = this.updatePosition.bind(this);
    this.refMarkupPoints.forEach(markupPoint => {
      markupPoint.addEventListener(MarkupPointEvent.POSITION_CHANGED, updatePosition)
    });
  }

  removeRefPointEventListeners () {
    const updatePosition = this.updatePosition.bind(this);
    this.refMarkupPoints.forEach(markupPoint => {
      markupPoint.addEventListener(MarkupPointEvent.POSITION_CHANGED, updatePosition)
    });
  }

  recalculatePosition () {
    // stub to override
  }

  updatePosition() {
    const pos = this.recalculatePosition();
    super.updatePosition(pos);
  }

  destroy() {
    this.removeRefPointEventListeners();
    this.removeAllEventListeners();
  }
}

export default DependantMarkupPoint;