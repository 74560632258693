import MarkupSegment from "./MarkupSegment";
import {abductionPointTypes, getSegmentDataByType} from "./MarkupPointTypes";

class AbductionSegment extends MarkupSegment {
  constructor(points=[]) {
    const type = 'LATERAL_ABDUCTION';
    const {name, description, color} = getSegmentDataByType(type);
    super(type, name, description, points, color);
  }

  createMarkupPoints(points) {
    if (!points || points.length < 2) {
      throw new Error('Not enough data for AbductionSegment');
    }

    const sortedPoints = this.sortByYCoord(points);
    const markupPointTypes = abductionPointTypes;
    const getMarkupPoint = this.getMarkupPoint;
    return markupPointTypes.map((type, index) => {
      return getMarkupPoint(type, sortedPoints[index]);
    });
  }

  sortByYCoord(points) {
    return points.sort((p1, p2) => {
      return p1.y - p2.y;
    });
  }

}

export default AbductionSegment;