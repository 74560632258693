import {v4 as uuidv4} from 'uuid';
import {EventDispatcher} from "../EventDispatcher";
import {MarkupPointEvent} from "../events/MarkupPointEvent";
import {MarkupShapeEvent} from "../events/MarkupShapeEvent";
import {MarkupItemsFactory} from "./MarkupItemsFactory";

class MarkupSegment extends EventDispatcher {
  constructor(type, name, description, points=[], color = '#000') {
    super();
    this.id = uuidv4();
    this.type = type;
    this.name = name;
    this.description = description;
    this.color = color;
    this.markupPoints = this.createMarkupPoints(points);
    if (!this.markupPoints) {
      throw new Error('Corresponding markup points are not defined for the segment');
    }
    this.addPointEventListeners();
  }

  createMarkupPoints(points) {
    // Must be overridden;
    return null;
  }

  onSegmentChanged () {
    this.dispatch(new MarkupShapeEvent(MarkupShapeEvent.GEOMETRY_CHANGED));
  }

  getMarkupPoint(type, position) {
    return MarkupItemsFactory.getMarkupPoint(type, position);
  }

  addPointEventListeners() {
    const onSegmentChanged = this.onSegmentChanged.bind(this);

    this.markupPoints.forEach(markupPoint => {
      markupPoint.addEventListener(MarkupPointEvent.POSITION_CHANGED, onSegmentChanged)
    });
  }

  removePointEventListeners() {
    const onSegmentChanged = this.onSegmentChanged;

    this.markupPoints.forEach(markupPoint => {
      markupPoint.removeEventListener(MarkupPointEvent.POSITION_CHANGED, onSegmentChanged)
    });
  }

  toJSON() {
    const {type, markupPoints} = this;
    return {
      type,
      points: markupPoints.map(markupPoint => markupPoint.toJSON().position)
    }
  }

  destroy() {
    this.removePointEventListeners();
  }
}

export default MarkupSegment;