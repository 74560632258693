import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './SegmentInput.scss';
import Size from "../../../model/base/Size";
import Point from "../../../model/base/Point";

const SegmentInput = ({viewportSize, onSegmentReady, startPointSize}) => {
  const svg = useRef(null);
  const [svgElem, setSvgElem] = useState(null);

  let startX = useRef(0);
  let startY = useRef(0);
  let mousedown = useRef(false);
  let line = useRef(null);

  useEffect(() => {
    setSvgElem(svg.current);
  }, [svg]);

  const getSvgPoint = useCallback((svg, x, y, layerX, layerY) => {
    const p = svg.createSVGPoint();
    p.x = x;
    p.y = y;

    const isFF = navigator.userAgent.match(/firefox|fxios/i);

    if (isFF) {
      const {x:xOffset, y:yOffset} = svgElem.getBoundingClientRect();
      p.x = layerX + xOffset;
      p.y = layerY + yOffset;
    }

    return p.matrixTransform(svg.getScreenCTM().inverse());
  }, [svgElem]);

  const getStartPointObj = useCallback((x, y, svgNS) => {
    const startPoint = document.createElementNS(svgNS, 'rect');
    startPoint.setAttribute('x', x - startPointSize / 2);
    startPoint.setAttribute('y', y - startPointSize / 2);
    startPoint.setAttribute('width', startPointSize);
    startPoint.setAttribute('height', startPointSize);
    return startPoint;
  }, [startPointSize]);

  const getHAngle = useCallback((x1, y1, x2, y2) => {
    return Math.atan2(y1 - y2, x2 - x1) * 180 / Math.PI;
  }, []);

  const onSvgMouseDown = useCallback(({x, y, layerX, layerY}) => {
    const svgPoint = getSvgPoint(svgElem, x, y, layerX, layerY);
    const {x:mouseDownX, y:mouseDownY} = svgPoint;
    startX.current = mouseDownX;
    startY.current = mouseDownY;
    mousedown.current = true;

    const svgNS = svgElem.namespaceURI;
    const startPoint = getStartPointObj(mouseDownX, mouseDownY, svgNS);
    svgElem.appendChild(startPoint);

    const ray = document.createElementNS(svgNS, 'line');
    line.current = ray;
    ray.setAttribute('x1', mouseDownX);
    ray.setAttribute('y1', mouseDownY);
    ray.setAttribute('x2', mouseDownX);
    ray.setAttribute('y2', mouseDownY);
    svgElem.appendChild(ray);

  }, [svgElem, getSvgPoint, getStartPointObj]);

  const onSvgMouseUp = useCallback((event) => {
    event.stopImmediatePropagation();
    mousedown.current = false;
    const {current:ray} = line
    const coords = ['x1', 'y1', 'x2', 'y2'].map((attr) => parseFloat(ray.getAttribute(attr)));
    onSegmentReady([new Point(coords[0], coords[1]), new Point(coords[2], coords[3])]);
  }, [onSegmentReady]);

  const onSvgMouseMove = useCallback(({x, y, ctrlKey, layerX, layerY}) => {
    if (mousedown.current === true) {
      const svgPoint = getSvgPoint(svgElem, x, y, layerX, layerY);

      let endX = svgPoint.x, endY = svgPoint.y;
      if (ctrlKey) {
        const start = new Point(startX.current, startY.current);
        const hAngle = getHAngle(start.x, start.y, endX, endY);
        const rounded = Math.round(Math.abs(hAngle) / 90);
        if (rounded % 2 === 0) {
          // adjust to horizontal line
          endY = start.y;
        } else {
          // adjust to vertical line
          endX = start.x;
        }
      }

      const ray = line.current;
      ray.setAttribute('x2', endX);
      ray.setAttribute('y2', endY);
    }
  }, [svgElem, getHAngle, getSvgPoint]);

  useEffect(() => {
    if (svgElem) {
      svgElem.addEventListener('mousedown', onSvgMouseDown);
      svgElem.addEventListener('mousemove', onSvgMouseMove);
      svgElem.addEventListener('mouseup', onSvgMouseUp);
    }

    return () => {
      if (svgElem) {
        svgElem.removeEventListener('mousedown', onSvgMouseDown);
        svgElem.removeEventListener('mousemove', onSvgMouseMove);
        svgElem.removeEventListener('mouseup', onSvgMouseUp);
      }
    }
  }, [svgElem, onSvgMouseDown, onSvgMouseUp, onSvgMouseMove]);

  return (<svg ref={svg} className="segment-input"
               width={viewportSize.width}
               height={viewportSize.height}
               viewBox={`0 0 ${viewportSize.width} ${viewportSize.height}`}>
          </svg>);
};

SegmentInput.propTypes = {
  viewportSize: PropTypes.instanceOf(Size),
  onSegmentReady: PropTypes.func.isRequired,
  startPointSize: PropTypes.number
};

SegmentInput.defaultProps = {
  startPointSize: 10,
};

export default SegmentInput;
