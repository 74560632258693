import React from 'react';
import PropTypes from 'prop-types';
import './MeasureResultView.scss';
import MeasureResult from "../../../model/measure/MeasureResult";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

const MeasureResultView = ({measureResult, onResultClick, selected}) => {
  const {t} = useTranslation();
  const {label, description, value, range} = measureResult;
  const outsideOfStandardRange = false;//!range.isInRange(value);

  const onClick = () => {
    onResultClick(measureResult);
  }

  const isCalcError = value === null || value === undefined || isNaN(value);

  return (
    <Stack sx={{width: '100%', padding: '4px', boxSizing: 'border-box'}} direction="column"
           onClick={onClick} className={`measure-result ${selected ? 'selected' : ''}`}>
      <Typography sx={{fontSize: 12, fontWeight: 'bold'}} color={outsideOfStandardRange || isCalcError ? "error" : "text.secondary"} gutterBottom>
        {`${t(label)} = ${!isCalcError ? value.toFixed(2) : 'Error'}`}
      </Typography>
      {
        outsideOfStandardRange
          ? <Typography sx={{fontSize: 10}} color="error" gutterBottom>
              {`Normal value: ${range.toString()}`}
            </Typography>
          : null
      }
      <Typography sx={{fontSize: 10}} color="text.secondary" gutterBottom>
        {t(description || '')}
      </Typography>
    </Stack>
  );
};

MeasureResultView.propTypes = {
  measureResult: PropTypes.instanceOf(MeasureResult).isRequired,
  onResultClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

MeasureResultView.defaultProps = {};

export default MeasureResultView;
