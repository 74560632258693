import {BaseEvent} from "./BaseEvent";

export class MarkupDataEvent extends BaseEvent {
  static MARKUP_POINT_ADDED = 'markupPointAdded';
  static MARKUP_SEGMENT_ADDED = 'markupSegmentAdded';
  static MARKUP_SHAPE_ADDED = 'markupShapeAdded';
  static MARKUP_DATA_CLEARED = 'markupDataCleared';
  static MARKUP_DATA_IMPORTED = 'markupDataImported';
  static STATUS_CHANGED = 'markupStatusChanged';

  constructor(eventType, payload) {
    const {markupPoint, markupSegment, markupShape} = payload || {};

    if (!markupPoint && (eventType === MarkupDataEvent.MARKUP_POINT_ADDED)) {
      throw new Error('Point should be provided in the payload of MarkupDataEvent');
    }

    if (!markupSegment && (eventType === MarkupDataEvent.MARKUP_SEGMENT_ADDED)) {
      throw new Error('Segment should be provided in the payload of MarkupDataEvent');
    }

    if (!markupShape && (eventType === MarkupDataEvent.MARKUP_SHAPE_ADDED)) {
      throw new Error('Shape should be provided in the payload of MarkupDataEvent');
    }

    super(eventType, payload);
  }
}