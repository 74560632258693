import XRayMarkupData from "./XRayMarkupData";
import {
  acetabulumPointTypes,
  femoralHeadPointTypes,
  getMainMarkupPointTypes,
  getShapeDataByName,
  mainMarkupSegmentTypes,
  tearDropPointTypes
} from "./MarkupPointTypes";
import MarkupLine from "./MarkupLine";
import MarkupCircle from "./MarkupCircle";
import {getCircleBy3Points, getClosestPoint, getDistance} from "../../utils/MathUtils";

export class MainXRayMarkupData extends XRayMarkupData {
  constructor(age) {
    super();
    this.updateAge(age);
    this._requiredSegmentTypes = mainMarkupSegmentTypes;
  }

  get age() {
    return this._age;
  }

  updateAge(age) {
    const currentData = !this.isEmpty ? this.toJSON() : null;

    this._age = age;
    this._requiredPointTypes = getMainMarkupPointTypes(this.age);

    if (currentData) {
      this.fromJSON(currentData);
    }
  }

  updateShapes(addedMarkupPoint) {
    const {type} = addedMarkupPoint;
    if (this.shouldAddShape(type, tearDropPointTypes)) {
      this.addShape(this.getTearDropLineShape());
    }
    else if (this.shouldAddShape(type, acetabulumPointTypes)) {
      this.addShape(this.getACCircleShape());
    }
    else if (this.shouldAddShape(type, femoralHeadPointTypes)) {
      this.addShape(this.getFHCircleShape());
    }
  }

  getTearDropLineShape() {
    const name = 'TEAR_DROP_LINE';
    const {label, description, color} = getShapeDataByName(name);
    return new MarkupLine(name, label, description, this.getTearDropMarkupPoints(), color);
  }

  getACCircleShape() {
    const name = 'AC_CIRCLE';
    const {label, description, color} = getShapeDataByName(name);
    return new MarkupCircle(name, label, description, this.getAcetabulumMarkupPoints(), color);
  }

  getTearDropMarkupPoints() {
    return this.getPointsOfTypes(tearDropPointTypes);
  }

  getTearDrop() {
    const tearDropPoints = this.getTearDropMarkupPoints();
    if (!tearDropPoints || tearDropPoints.length < 2) {
      throw new Error('Incorrect Tear Drop values');
    }

    const [p1, p2] = tearDropPoints.map(markupPoint => markupPoint.position);
    const tearDropLength = getDistance(p1, p2);

    if (tearDropLength <= 0) {
      throw new Error("Tear drop line is setup incorrectly");
    }

    const center = p1.x + tearDropLength / 2;
    return {right: p1, left: p2, length: tearDropLength, center};
  }

  getAcetabulumMarkupPoints() {
    return this.getPointsOfTypes(acetabulumPointTypes);
  }

  getAcetabulum() {
    const points = this.getAcetabulumMarkupPoints();
    if (!points || points.length < 3) {
      throw new Error('Incorrect acetabulum points');
    }

    return getCircleBy3Points(points.map(markupPoint => markupPoint.position));
  }

  getBMarkupPoint () {
    return this.getMarkupPoint('AC_UP_OUTER');
  }

  getB1MarkupPoint () {
    return this.getMarkupPoint('AC_WEIGHT_BEARING_OUTER_EDGE');
  }

  getClosestTearDropMarkupPoint (point) {
    const tearDropPoints = this.getTearDropMarkupPoints();
    const tearDropPointPositions = tearDropPoints.map(markupPoint => markupPoint.position);
    const closestTearDropPosition = getClosestPoint(point, tearDropPointPositions);
    return tearDropPoints.find(markupPoint => markupPoint.position === closestTearDropPosition);
  }

  getMarkupSideFromSagittalAxis () {
    const {center:tdXCenter} = this.getTearDrop();
    const {center:fhCenter} = this.getFemoralHead();

    if (tdXCenter === fhCenter.x) {
      return 0;
    }

    return (fhCenter.x - tdXCenter) / Math.abs(fhCenter.x - tdXCenter);
  }

}