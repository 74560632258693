import {ImageData} from "../model/base/ImageData";
import Size from "../model/base/Size";

const retrieveImageData = (src, onLoad) => {
  const img = new Image();

  img.onload = () => {
    const { width, height } = img;
    onLoad(new ImageData(src, new Size(width, height)));
  };

  img.onerror = () => {
    onLoad(null);
  };

  img.src = src;
};

const loadImageData = (file, onLoad) => {
  if (!file) {
    onLoad(null);
  }

  const reader = new FileReader();
  reader.onload = (e) => {
    const { result } = e.target;
    retrieveImageData(result, (imageData) => onLoad(imageData));
  };

  reader.readAsDataURL(file);
};


const getImageDataFromBinary = async (file) => new Promise(((resolve) => {
  loadImageData(file, (imageData) => resolve(imageData));
}));

export { getImageDataFromBinary };