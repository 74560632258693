class Size {
  constructor(width = 0, height = 0) {
    this.width = width;
    this.height = height;
  }

  update ({width, height}) {
    this.width = width;
    this.height = height;
  }

  toJSON() {
    const {width, height} = this;
    return {width, height};
  }

  fromJSON(jsonData) {
    this.update(jsonData);
  }
}

export default Size;