import {v4 as uuidv4} from 'uuid';
import {EventDispatcher} from "../EventDispatcher";
import {MarkupPointEvent} from "../events/MarkupPointEvent";
import {MarkupShapeEvent} from "../events/MarkupShapeEvent";

class MarkupShape extends EventDispatcher {
  constructor(name, label, description, refPoints = [], color = '#000') {
    super();
    this.id = uuidv4();
    this.name = name;
    this.label = label;
    this.description = description;
    this.color = color;
    // Shape reference points
    this._refPoints = refPoints;
    if (!this._refPoints || !refPoints.length) {
      throw new Error('Reference points are not defined for the shape');
    }

    const refPointsErr = this.checkRefPoints();

    if (refPointsErr) {
      throw new Error(refPointsErr);
    }

    this.addPointEventListeners();
  }

  checkRefPoints() {
    // Must be overridden;
    return null;
  }

  recalculateShapeGeometry() {
    // Must be overridden;
  }

  getBoundingBox() {
    // Must be overridden;
  }

  getLabel(svgNS, text, position) {
    const label = document.createElementNS(svgNS, 'text');
    label.setAttribute('x', position.x);
    label.setAttribute('y', position.y);
    const textNode = document.createTextNode(text);
    label.appendChild(textNode);
    return label;
  }

  getLine(svgNS, p1, p2) {
    const line = document.createElementNS(svgNS, 'line');
    line.setAttribute('x1', p1.x);
    line.setAttribute('y1', p1.y);
    line.setAttribute('x2', p2.x);
    line.setAttribute('y2', p2.y);
    return line;
  }

  onPointsChanged() {
    this.recalculateShapeGeometry();
    this.dispatch(new MarkupShapeEvent(MarkupShapeEvent.GEOMETRY_CHANGED));
  }

  addPointEventListeners() {
    const onPointsChanged = this.onPointsChanged.bind(this);

    this._refPoints.forEach(markupPoint => {
      markupPoint.addEventListener(MarkupPointEvent.POSITION_CHANGED, onPointsChanged)
    });
  }

  removePointEventListeners() {
    const onPointsChanged = this.onPointsChanged;

    this._refPoints.forEach(markupPoint => {
      markupPoint.removeEventListener(MarkupPointEvent.POSITION_CHANGED, onPointsChanged)
    });
  }

  destroy() {
    this.removePointEventListeners();
  }
}

export default MarkupShape;