import {BaseEvent} from "./BaseEvent";

export class InputEvent extends BaseEvent {
  static INPUT_POINT = 'inputPoint';
  static INPUT_SEGMENT = 'inputSegment';
  static CANCEL_INPUT = 'cancelInput';
  static ADDED_POINT = 'addedPoint';
  static ADDED_SEGMENT = 'addedSegment';
  static CANCELED_INPUT = 'canceledInput';

  constructor(eventType, payload) {
    const {point, point1, point2} = payload
    if (eventType === InputEvent.ADDED_POINT && !point) {
      throw new Error('Point and inputId should be provided in the payload of InputEvent.ADDED_POINT');
    }

    if (eventType === InputEvent.ADDED_SEGMENT && (!point1 || !point2)) {
      throw new Error('Point1, point2 and inputId should be provided in the payload of InputEvent.ADDED_SEGMENT');
    }

    super(eventType, payload);
  }
}