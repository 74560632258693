import { v4 as uuidv4 } from 'uuid';
import Point from '../base/Point';
import {EventDispatcher} from "../EventDispatcher";
import {MarkupPointEvent} from "../events/MarkupPointEvent";

class MarkupPoint extends EventDispatcher {
  constructor(type, label, name, description, position = null, color = '#000000', visible = true) {
    super();
    this.id = uuidv4();
    this.type = type;
    this.label = label;
    this.name = name;
    this.description = description;
    this.position = position || new Point();
    this.color = color;
    this.visible = visible;
  }

  updatePosition(point) {
    this.position.update(point);
    this.dispatch(new MarkupPointEvent(MarkupPointEvent.POSITION_CHANGED));
  }

  updateVisibility(visible) {
    this.visible = visible;
    this.dispatch(new MarkupPointEvent(MarkupPointEvent.VISIBILITY_CHANGED));
  }

  toJSON() {
    const {type, position} = this;
    return {
      type,
      position: position.toJSON()
    }
  }

  destroy() {
    this.removeAllEventListeners();
  }
}

export default MarkupPoint;