import React, {useContext} from 'react';
import './FrontalMarkupStep.scss';
import MarkupModelProvider from "../../../contexts/MarkupModelContext";
import MarkupStep from "../MarkupStep/MarkupStep";

const FrontalMarkupStep = () => {
  const {frontalXRayData} = useContext(MarkupModelProvider);
  const {markup, calculator} = frontalXRayData;

  return (
    <MarkupStep xRayMarkupData={markup} measureCalculator={calculator} xRayType="false_profile_xray_type"></MarkupStep>
  );
};

FrontalMarkupStep.propTypes = {};

FrontalMarkupStep.defaultProps = {};

export default FrontalMarkupStep;
