import Point from "../model/base/Point";
import Line from "../model/base/Line";
import Segment from "../model/base/Segment";

const isValidCalValue = (calcValue) => {
  return !isNaN(calcValue) && calcValue > -Infinity && calcValue < Infinity;
}

const getValidCalValue = (calcValue, defaultValue = 0) => {
  if (!isValidCalValue(calcValue)) {
    return defaultValue;
  }

  return calcValue;
}

const getCircleBy3Points = (points) => {
  const [p1, p2, p3] = points;
  const getErrorCircle = () => {
    return {center: new Point(0, 0), radius: 0};
  }

  if (p2.x === p1.x || p2.x === p3.x) {
    return getErrorCircle();
  }

  const lineACoeff = (p2.y - p1.y) / (p2.x - p1.x);
  const lineBCoeff = (p3.y - p2.y) / (p3.x - p2.x);

  if (lineACoeff === lineBCoeff || lineACoeff === 0) {
    return getErrorCircle();
  }

  const centerX = (lineACoeff * lineBCoeff * (p1.y - p3.y) + lineBCoeff * (p1.x + p2.x) - lineACoeff * (p2.x + p3.x)) / (2 * (lineBCoeff - lineACoeff));
  const centerY = (-1 / lineACoeff) * (centerX - (p1.x + p2.x) / 2) + (p1.y + p2.y) / 2;

  const center = new Point(getValidCalValue(centerX), getValidCalValue(centerY));
  const radius = getDistance(p1, center);

  return {center, radius};
}

const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

const getLineCenter = (line) => {
  return new Point(line.p1.x + (line.p2.x - line.p1.x) / 2, line.p1.y + (line.p2.y - line.p1.y) / 2);
}

const getPerpendicularLine = (line, point) => {
  let x, y;
  if (line.coeff === 0) {
    x = point.x;
    y = point.y + 100;
  }
  else {
    x = point.x + 100;
    y = point.y - (1 / line.coeff) * (x - point.x);
  }

  return new Line(new Point(x, y), point);
}

const getPerpendicularToLine = (line, point) => {
  const perpLine = getPerpendicularLine(line, point);

  const intersection = line.getIntersection(perpLine);
  if (!intersection) {
    throw new Error('Perpendicular doesn\'t intersect line');
  }

  return new Segment(point, intersection);
}

const toDegrees = (radians) => {
  return radians * (180 / Math.PI);
}

const getAngleBetween = (line1, line2) => {
  return toDegrees(Math.atan((line2.coeff - line1.coeff) / (1 + line2.coeff * line1.coeff)));
}

const getAngleWithHorizontal = (line) => {
  const {p1, p2} = line;
  return -toDegrees(Math.atan2(p2.y - p1.y, p2.x - p1.x));
}

const getAngleWithVertical = (line) => {
  return 90 - Math.abs(getAngleWithHorizontal(line));
}

// positive to the outside from the central vertical line and negative otherwise
const getAngleWithVerticalFromCenter = (line, measureSideDirection) => {
  if (!measureSideDirection) {
    return null;
  }
  const {p1, p2} = line;
  const direction = getHAxisDirection(p1, p2);
  return Math.abs(getAngleWithVertical(line)) * direction * measureSideDirection;
}

const getClosestPoint = (point, points) => {
  if (!points || !points.length) {
    return null;
  }

  const distances = points.map(p => {return {d: getDistance(p, point), p}}).sort((p1, p2) => p1.d - p2.d);
  return distances[0].p;
}

const getDirection = (start, end) => {
  if (!start || !end || isNaN(start) || isNaN(end)) {
    return 0;
  }

  if (start === end) {
    return 1;
  }
  // +1 or -1
  return (end - start) / Math.abs((end - start));
}

const getHAxisDirection = (start, end) => {
  if (!start || !end) {
    return 0;
  }

  const {x:startX} = start;
  const {x:endX} = end;

  return getDirection(startX, endX);
}

const getVAxisDirection = (start, end) => {
  if (!start || !end || isNaN(start) || isNaN(end)) {
    return 0;
  }

  const {y:startY} = start;
  const {y:endY} = end;

  return getDirection(startY, endY);
}

export {getCircleBy3Points, getDistance, getPerpendicularLine, toDegrees, getClosestPoint, getAngleBetween,
        getAngleWithVertical, getAngleWithHorizontal, getLineCenter, getPerpendicularToLine,
        getHAxisDirection, getVAxisDirection, getAngleWithVerticalFromCenter};