import Range from "../base/Range";
import {getMeasureDataByType} from "../measure/MeasureTypes";
import {getAllDiagnosisCases, getBaseISHRule} from "./DiagnosisCase";
import {AgeType} from "../base/AgeRangeTypes";

export const getMeasureRanges = (diagnosisCases, measureType) => {
  return diagnosisCases.reduce((acc, diagnosis) => {
    const rule = diagnosis.rules.find(rule => rule.measureResultType === measureType);

    if (rule && !acc.find((range) => rule.range.isEqual(range))) {
      acc.push(rule.range);
    }
    return acc;
  }, []);
}

export const getGapRanges = (ranges) => {
  const sortedRanges = ranges.sort((r1, r2) => (r1.min === r2.min ? r1.max - r2.max : r1.min - r2.min));
  const result = sortedRanges.reduce((acc, range, index) => {
    const overlap = (range.isInRange(acc.prevRangeMax)
      || (acc.prevRangeMax === range.min && (range.includeEdges || acc.includeEdges || range.min === -Infinity))
      || (range.min - acc.prevRangeMax <= 0.011 && range.includeEdges && acc.includeEdges));

    if (!overlap) {
      acc.gaps.push(new Range(acc.prevRangeMax, range.min, acc.prevRangeMax === range.min ? true : range.includeEdges));
    }

    acc.prevRangeMax = range.max;
    acc.includeEdges = range.includeEdges;
    return acc;
  }, {prevRangeMax: -Infinity, includeEdges: false, gaps: []});

  if (result.prevRangeMax !== Infinity) {
    result.gaps.push(new Range(result.prevRangeMax));
  }

  return result.gaps.filter(range => range.isDefined());
}

const getMeasures = (age) => {
  return ['ICAS', 'ANGLE_A', (age === AgeType.R12_ ? 'ANGLE_B' : 'ANGLE_B1'), 'AC_COEFF', 'ANGLE_V'];
}


const caseMatchByMeasureRanges = (dCase, measureRanges) => {
  return measureRanges.every(({measureType, range}) => dCase.rules.find(rule => rule.measureResultType === measureType && rule.range.isEqual(range)));
}

const getCasesForMeasures = (allCases, measureRanges) => {
  return allCases.filter(dCase => caseMatchByMeasureRanges(dCase, measureRanges));
}

class MeasureRange {
  constructor(measureType, range) {
    this.measureType = measureType;
    this.range = range;
  }
}

class CaseNode {
  constructor(id, measureRange, parentNode, level, index, isGap = false, isFinal = false) {
    this.id = id;
    this.measureRange = measureRange;
    this.parentNode = parentNode;
    this.isGap = isGap;
    this.isFinal = isFinal;
    this.level = level;
    this.index = index;
  }

  getMeasureRangesForNode(node) {
    const result = [];

    if (!node) {
      return result;
    }

    let parentNode = node.parentNode;
    while (parentNode) {
      result.push(parentNode.measureRange);
      parentNode = parentNode.parentNode;
    }

    const baseISHRule = getBaseISHRule();
    result.push(new MeasureRange(baseISHRule.measureResultType, baseISHRule.range));

    return result.reverse();
  }

  getAllMeasureRanges() {
    const result = [this.measureRange, ...this.getMeasureRangesForNode(this)];
    return result;
  }

  getData(t) {
    return {label: this.getLabel(t)};
  }

  getLabel(t) {
    const {label} = getMeasureDataByType(this.measureRange.measureType);
    return `${t(label)}\n${this.measureRange.range.toString()}`;
  }
}

class TreatmentNode extends CaseNode{
  constructor(id, diagnosisCase, parentNode, level, index) {
    super(id, null, parentNode, level, index, false, true)
    this.diagnosisCase = diagnosisCase;
  }

  getAllMeasureRanges() {
    return this.getMeasureRangesForNode(this.parentNode);
  }

  getData(t) {
    return {code: this.diagnosisCase.getCode(), label: this.getLabel(t), description: this.getDescription(t)};
  }

  getLabel(t) {
    return this.diagnosisCase.getGeneralTreatment(t);
  }

  getDescription(t) {
    const mainRecommendations = this.diagnosisCase.getMainRecommendations(null, t).map(({label, description}) => `${label} (${description})`).join('\n');
    return this.diagnosisCase.getDiagnosisRecommendations(t) + '\n' + mainRecommendations;
  }
}

const buildCasesTree = (parentNode, allCases, measures, treeNodes) => {
  if (!allCases || allCases.length === 0) {
    return;
  }

  const prefix = parentNode ? parentNode.id : 'r';
  const level = parentNode ? parentNode.level + 1 : 0;

  const getNextMeasureParameter = () => {
    const index = (parentNode ? measures.indexOf(parentNode.measureRange.measureType) : -1) + 1;
    return (index >= 0 && index < measures.length ? measures[index] : null);
  }

  const saveTreatments = () => {
    if (allCases && allCases.length > 0) {
      allCases.forEach((diagnosisCase, index) => {
        const treatmentNode = new TreatmentNode(
          `${prefix}_t${index + 1}`,
          diagnosisCase, parentNode, level, index);
        treeNodes.push(treatmentNode);
      })
    };
  }

  const nextMeasureToGroup = getNextMeasureParameter();

  if (!nextMeasureToGroup) {
    saveTreatments();
    return;
  }

  const measureRanges = getMeasureRanges(allCases, nextMeasureToGroup);
  if (!measureRanges.length) {
    saveTreatments();
    return;
  }

  const measureGapRanges = getGapRanges(measureRanges);

  measureRanges.forEach((range, index) => {
    const caseNode = new CaseNode(`${prefix}_${index + 1}`, new MeasureRange(nextMeasureToGroup, range), parentNode, level, index, false);
    const subCases = getCasesForMeasures(allCases, caseNode.getAllMeasureRanges());
    caseNode.isFinal = subCases.length === 0;

    treeNodes.push(caseNode);

    if (caseNode.isFinal) {
      return
    }

    buildCasesTree(caseNode, subCases, measures, treeNodes);
  });

  if (measureGapRanges && measureGapRanges.length > 0) {
    measureGapRanges.forEach((gapRange, index) => {
      const caseNode = new CaseNode(
        `${prefix}_g${index + 1}`,
        new MeasureRange(nextMeasureToGroup, gapRange), parentNode, level, measureRanges.length + index,
        true, true);
      treeNodes.push(caseNode);
    });
  }
}

export const getAllLevels = (treeNodes) => {
  return Array.from(new Set(treeNodes.map(node => node.level))).sort((a, b) => a - b);
}

export const getMaxLevel = (treeNodes) => {
  const allLevels = getAllLevels(treeNodes);
  return allLevels.length ? allLevels[allLevels.length - 1] : 0;
}

export const getNodesOfLevel = (treeNodes, level) => {
  return treeNodes.filter(node => node.level === level);
}

export const arrangeIndexesInsideLevel = (treeNodes) => {
  const levels = getAllLevels(treeNodes);

  levels.forEach(level => {
    const levelNodes = getNodesOfLevel(treeNodes, level).sort((a, b) => a.id < b.id);
    levelNodes.forEach((node, index) => node.index = index);
  });
}

export const getTreeNodes = (age) => {
  const allCases = getAllDiagnosisCases(age);
  const treeNodes = [];
  buildCasesTree(null, allCases, getMeasures(age), treeNodes);

  const maxLevel = getMaxLevel(treeNodes);

  treeNodes.filter(node => node instanceof TreatmentNode).forEach(node => {node.level = maxLevel});
  arrangeIndexesInsideLevel(treeNodes);

  return treeNodes;
}

export const getDiagnosisRelatedNodes = (allCases, currentDiagnosisCase) => {
  if (!currentDiagnosisCase) {
    return [];
  }

  return allCases.filter(dCase => {
    if (dCase.diagnosisCase) {
      return (currentDiagnosisCase === dCase.diagnosisCase);
    }

    const measureRanges = dCase.getAllMeasureRanges();
    return caseMatchByMeasureRanges(currentDiagnosisCase, measureRanges);
  });
}

const doesMapMatchToRanges = (measureRanges, allMeasureResultsMap) => {
  return measureRanges.every(({measureType, range}) => {
    const {value} = allMeasureResultsMap.get(measureType);
    return range.isInRange(value);
  });
}

export const getMeasuresRelatedNodes = (allCases, allMeasureResultsMap) => {
  if (!allMeasureResultsMap) {
    return [];
  }

  return allCases.filter(dCase => {
    const measureRanges = dCase.getAllMeasureRanges();
    return doesMapMatchToRanges(measureRanges, allMeasureResultsMap);
  });
}

export const getNumberOfNodesByLevel = (treeNodes) => {
  const levels = getAllLevels(treeNodes);
  return levels.map(level => {
    return {level, count: getNodesOfLevel(treeNodes, level).length}
  });
}

export const getChildNodes = (treeNodes, parentNode) => {
  const isChildOf = (node, parentNodeId) => {
    let parentNode = node.parentNode;
    let found = false;
    while (parentNode) {
      if (parentNode.id === parentNodeId) {
        found = true;
        break;
      }
      parentNode = parentNode.parentNode;
    }

    return found;
  }

  return treeNodes.filter((node => isChildOf(node, parentNode.id)));

}

export const getNumberOfChildNodesByLevel = (treeNodes, parentNode) => {
  const childNodes = getChildNodes(treeNodes, parentNode);
  return getNumberOfNodesByLevel(childNodes);
}

export const getLastLevelChildrenNumber = (treeNodes) => {
  const levels = getAllLevels(treeNodes);
  const lastLevel = levels && levels.length ? levels[levels.length - 1] : null;

  if (!lastLevel) {
    return 0;
  }

  const levelNodes = getNodesOfLevel(treeNodes, lastLevel);
  return levelNodes ? levelNodes.length : 0;
}

export const getMaxChildrenNumberInLevel = (treeNodes) => {
  const levels = getAllLevels(treeNodes);
  const childNumber = levels.map(level => getNodesOfLevel(treeNodes, level).length).sort((a, b) => b - a);
  return childNumber && childNumber.length ? childNumber[0] : 0;
}