import React, {useCallback, useContext} from 'react';
import './Save.scss';
import Button from "@mui/material/Button";
import MarkupModelProvider from "../../../contexts/MarkupModelContext";
import {useTranslation} from "react-i18next";

const Save = () => {
  const {exportData} = useContext(MarkupModelProvider);
  const {t} = useTranslation();

  const download = (filename, content) => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

  const save = useCallback(() => {
    const json = exportData();
    download(`${new Date().toJSON()}.json`, JSON.stringify(json));
  }, [exportData]);

  return (
    <Button variant="contained" onClick={save} size="small">
      {t("general.save")}
    </Button>
  );
}

Save.propTypes = {};

Save.defaultProps = {};

export default Save;
