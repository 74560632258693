import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import './Upload.scss';
import {useTranslation} from "react-i18next";

const Upload = ({setUploadData, xRayType}) => {
  const { t } = useTranslation();

  const onUpload = (event) => {
    setUploadData(event.target.files[0]);
    event.target.value = '';
  }

  return (
      <Button variant="contained" component="label" startIcon={<PhotoCamera />}>
        {t('general.upload_xray', {xRayType: t(`general.${xRayType}`)})}
        <input hidden accept="image/*" type="file" onChange={onUpload}/>
      </Button>)
};

Upload.propTypes = {
  setUploadData: PropTypes.func.isRequired,
  xRayType: PropTypes.string.isRequired,
};

Upload.defaultProps = {};

export default Upload;
