import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './DiagnosisList.scss';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {BaseDiagnosisCase} from "../../../model/diagnosis/DiagnosisCase";
import {useTranslation} from "react-i18next";
import {blue, red} from '@mui/material/colors';
import MarkupModelProvider from "../../../contexts/MarkupModelContext";
import {
  arrangeIndexesInsideLevel,
  getAllLevels,
  getChildNodes,
  getDiagnosisRelatedNodes,
  getMaxChildrenNumberInLevel,
  getMeasuresRelatedNodes,
  getNodesOfLevel,
  getNumberOfNodesByLevel,
  getTreeNodes
} from "../../../model/diagnosis/DiagnosisAnalysis";
import ReactFlow, {applyEdgeChanges, applyNodeChanges, Controls, MarkerType} from 'react-flow-renderer';
import DiagnosisFlowNode from "../DiagnosisFlowNode/DiagnosisFlowNode";
import PropTypes from "prop-types";
import {getAgeDescr} from "../../../model/base/AgeRangeTypes";

const DiagnosisList = ({currentDiagnosis, allMeasureResultsMap}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const {age} = useContext(MarkupModelProvider);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getGroupNodeId = (id) => `g_${id}`;

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [resultNodes, setResultNodes] = useState([]);
  const [size, setSize] = useState({width: 100, height: 100});

  useEffect(() => {
    const nodeWidth = 60;
    const nodeHeight = 40;
    const verticalGap = 50;
    const horizontalGap = 20;
    const groupsGap = 20;

    const treeNodes = getTreeNodes(age);
    const relatedNodes = currentDiagnosis && !currentDiagnosis.invalid
                          ? getDiagnosisRelatedNodes(treeNodes, currentDiagnosis)
                          : getMeasuresRelatedNodes(treeNodes, allMeasureResultsMap);

    const firstLevelNodes = getNodesOfLevel(treeNodes, 0);
    const allLevels = getAllLevels(treeNodes);
    const numberOfLevels = allLevels.length;
    const maxHeight = numberOfLevels * nodeHeight + (numberOfLevels - 1) * verticalGap;

    const getLayouts = (nodes) => {
      const maxChildren = getMaxChildrenNumberInLevel(nodes);
      const maxWidth = maxChildren * nodeWidth + horizontalGap * (maxChildren - 1);

      const layoutsByLevel = getNumberOfNodesByLevel(nodes).reduce((acc, {level, count}) => {
        let space = 0;
        const vPos = level === 0 ? 0 : level * (nodeHeight + verticalGap);
        if (count > 0) {
          const itemWidth = maxWidth / count;
          space = itemWidth - nodeWidth;
        }

        acc[level] = {space, count, vPos};
        return acc;
      }, {});

      return {maxWidth, layoutsByLevel};
    }

    const isNodeRelatedToCurrentDiagnosis = (node) => {
      if (!node) {
        return false;
      }

      const found = relatedNodes.find(relatedNode => relatedNode.id === node.id);
      return (found !== null && found !== undefined);
    }

    const firstLevelGroups = firstLevelNodes.map(firstLevelNode => {
      const childNodes = getChildNodes(treeNodes, firstLevelNode);
      arrangeIndexesInsideLevel([firstLevelNode, ...childNodes]);
      const {maxWidth, layoutsByLevel} = getLayouts([...childNodes, firstLevelNode]);
      return {parentNode: firstLevelNode, childNodes, maxWidth, layoutsByLevel, x: 0};
    }).sort((group1, group2) => group1.parentNode.index < group2.parentNode.index);

    firstLevelGroups.forEach((group, index) => {
      if (index === 0) {
        group.x = 0;
      }
      else {
        const prevGroup = firstLevelGroups[index - 1];
        group.x = prevGroup.x + prevGroup.maxWidth + groupsGap;
      }
    });

    const lastFirstLevelGroup = firstLevelGroups[firstLevelGroups.length - 1];
    const maxWidth = lastFirstLevelGroup.x + lastFirstLevelGroup.maxWidth;

    const getGroupNode = (group) => {
      return {
        id: getGroupNodeId(group.parentNode.id),
        data: {label: null},
        type: 'group',
        style: {width: group.maxWidth, height: maxHeight, borderColor: 'transparent', background: 'none'},
        position: { x: group.x, y: 0 }
      }
    }

    const nodeStyle = {padding: 2, margin: 0, fontSize: '10px', width: nodeWidth, height: nodeHeight,
                       border: '1px solid #777', borderRadius: '4px', whiteSpace: 'pre-line'};
    const gapNodeStyle = {backgroundColor: '#CCC', opacity: 0.7};
    const currentDiagnosisNodeStyle = {borderWidth: '2px', backgroundColor: blue[200]};
    const noDiagnosisNodeStyle = {backgroundColor: red[200]};

    const getNode = (node, layoutsByLevel, groupNode) => {
      const {index, level, isGap, id, diagnosisCase, parentNode, isFinal} = node;
      const isRelated = isNodeRelatedToCurrentDiagnosis(node);
      const isTreatmentNode = diagnosisCase !== null && diagnosisCase !== undefined;
      const style = {
        ...nodeStyle,
        ...(isGap ? gapNodeStyle : {}),
        ...(isRelated ? currentDiagnosisNodeStyle : {}),
        ...(isRelated && currentDiagnosis.invalid ? noDiagnosisNodeStyle : {})};
      const {space, vPos} = layoutsByLevel[level];
      const hPos = space / 2 + index * nodeWidth + index * space;

      const type = isTreatmentNode
                    ? 'diagnosisNode'
                    : (isFinal ? 'output' : (parentNode === null ? 'input' : 'default'));
      return {
        id: id,
        data: {...node.getData(t)},
        parentNode: groupNode.id,
        type,
        extent: 'parent',
        style,
        position: { x: hPos, y: vPos },
        selected: isRelated && isTreatmentNode
      }
    }

    const getInitialNodesForGroup = (group) => {
      const groupNode = getGroupNode(group);

      const {childNodes, parentNode, layoutsByLevel} = group;
      return [groupNode, ...([...childNodes, parentNode].map(node => getNode(node, layoutsByLevel, groupNode)))];
    }

    const initialNodes = firstLevelGroups.reduce((acc, group) => {
      acc = [...acc, ...getInitialNodesForGroup(group)];
      return acc;
    }, []);

    const initialEdges = treeNodes.reduce((acc, node) => {
      if (node.parentNode) {
        const sourceNode = node.parentNode;
        const targetNode = node;

        const edge = {
          id: `e_${node.parentNode.id}_${node.id}`,
          source: sourceNode.id,
          target: targetNode.id,
          animated: isNodeRelatedToCurrentDiagnosis(sourceNode) && isNodeRelatedToCurrentDiagnosis(targetNode)
        };
        acc.push(edge);
      }

      return acc;
    }, []);

    setResultNodes(relatedNodes);
    setSize({width: maxWidth, height: maxHeight});
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [currentDiagnosis, allMeasureResultsMap, age, t])

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onFlowInit = useCallback(() => {
    const selectedRootNode = resultNodes.find (node => node.parentNode === null);
    if (!selectedRootNode) {
      return;
    }

    const node = document.querySelector(`[data-id="${getGroupNodeId(selectedRootNode.id)}"]`);
    if (node) {
      node.scrollIntoView();
    }
  }, [resultNodes]);

  const edgeStyle = {markerEnd: {type: MarkerType.Arrow}};
  const nodeTypes = useMemo(() => ({ diagnosisNode: DiagnosisFlowNode }), []);

  return (
    <div>
      <Button size="small" color="primary" variant="outlined" onClick={handleClickOpen} startIcon={<AccountTreeIcon />}>
        {t('general.treatments_diagram')}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl">
        <DialogTitle>
          {t('general.list_of_treatments_for_age', {age: getAgeDescr(age)})}
        </DialogTitle>
        <DialogContent>
          <Box sx={{width: size.width, height: size.height * 1.2}}>
           <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            defaultZoom={1}
            minZoom={0.2}
            maxZoom={4}
            nodesConnectable={false}
            nodesDraggable={true}
            attributionPosition="bottom-left"
            defaultEdgeOptions={edgeStyle}
            style={{padding: 10}}
            nodeTypes={nodeTypes}
            onInit={onFlowInit}
          >
             <Controls />
          </ReactFlow>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

DiagnosisList.defaultProps = {};
DiagnosisList.propTypes = {
  currentDiagnosis: PropTypes.instanceOf(BaseDiagnosisCase),
  allMeasureResultsMap: PropTypes.object,
};

export default DiagnosisList;
