import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './Viewport.scss';
import ReactResizeDetector from "react-resize-detector";
import Size from "../../../model/base/Size";

const Viewport = ({contentSize, zoomLevel, children}) => {
  const container = useRef(null);
  const viewport = useRef(null);
  const [scaleCoeff, setScaleCoeff] = useState(1);
  const [containerSize, setContainerSize] = useState(new Size(1, 1));

  useEffect(() => {
    resize();
  }, [container]);

  useEffect(() => {
    const calculateScaleCoeff = () => {
      // fit coeff that corresponds to the 0 zoom level
      const baseCoeff = Math.min(containerSize.width / contentSize.width, containerSize.height / contentSize.height);
      // Expected zoomLevel is from the range [-1, 1].
      return Math.max(baseCoeff + zoomLevel, 0.1);
    }

    setScaleCoeff(calculateScaleCoeff());
  }, [containerSize, zoomLevel, contentSize]);

  const resize = () => {
    if (container) {
      const rect = container.current.getBoundingClientRect();
      const {width, height} = rect;
      setContainerSize(new Size(width, height));
    }
  }

  const invalidate = () => {
    setTimeout(() => {
      resize();
    }, 100);
  };

  return (
    <ReactResizeDetector
      onResize={invalidate}
      handleWidth
      handleHeight
      targetRef={container}
    >
      <div ref={container} className="main-container">
        <div ref={viewport} className="content-viewport"
             style={{width: `${containerSize.width}px`, height: `${containerSize.height}px`}}>
          <div className="content-wrapper" style={{transform: `scale(${scaleCoeff})`}}>
            {children}
          </div>
        </div>
      </div>
    </ReactResizeDetector>
  );
};

Viewport.propTypes = {
  contentSize: PropTypes.instanceOf(Size).isRequired,
  zoomLevel: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Viewport.defaultProps = {
  zoomLevel: 0,
};

export default Viewport;
