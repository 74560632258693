import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './MarkupHeader.scss';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import MarkupProgress from "../MarkupProgress/MarkupProgress";
import ClearMarkupData from "../ClearMarkupData/ClearMarkupData";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import MarkupWizard from "../MarkupWizard/MarkupWizard";
import DiagnosisView from "../../diagnosis/DiagnosisView/DiagnosisView";

const MarkupHeader = ({xRayMarkupData, showPreliminaryDiagnosis}) => {
  const wizardRef = useRef();
  const [isFulfilled, setIsFulfilled] = useState(xRayMarkupData.isFulfilled);

  const onStatusChanged = useCallback(() => {
    setIsFulfilled(xRayMarkupData.isFulfilled);
  }, [xRayMarkupData]);

  useEffect(() => {
    xRayMarkupData.addEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.STATUS_CHANGED, onStatusChanged);
    }
  }, [xRayMarkupData, onStatusChanged]);

  const requestNextInput = useCallback(() => {
    wizardRef.current.requestNextInput();
  }, [wizardRef]);

  return (
    <Stack direction="column" spacing={1} sx={{width: '100%', alignItems: 'flex-start'}}>
      <MarkupProgress xRayMarkupData={xRayMarkupData} onNextPointClick={requestNextInput}></MarkupProgress>
      <Stack direction="row" spacing={2} sx={{alignItems: 'center', width: '100%', height: '120px', justifyContent: 'space-between'}}>
        {
          !isFulfilled
            ? <MarkupWizard xRayMarkupData={xRayMarkupData} ref={wizardRef}></MarkupWizard>
            : (showPreliminaryDiagnosis ? <Box sx={{width: '100%', height: '100%', overflow: 'auto'}}><DiagnosisView></DiagnosisView></Box> : null)
        }
        <ClearMarkupData xRayMarkupData={xRayMarkupData}></ClearMarkupData>
      </Stack>
    </Stack>);
};

MarkupHeader.propTypes = {
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
  showPreliminaryDiagnosis: PropTypes.bool,
};

MarkupHeader.defaultProps = {
  showPreliminaryDiagnosis: false,
};

export default MarkupHeader;
