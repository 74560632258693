import {BaseEvent} from "./events/BaseEvent";

export class EventDispatcher {
    constructor() {
        this.handlersByEventName = {};
    }

    addEventListener(eventType, handler) {
        if (typeof handler !== 'function') {
            console.error(`The listener handler must be a function`);
            return false;
        }

        if (!this.handlersByEventName[eventType]) {
            this.handlersByEventName[eventType] = [];
        }

        this.handlersByEventName[eventType].push(handler);
    }

    removeEventListener(eventType, handler) {
        const listeners = this.handlersByEventName[eventType];
        if (!listeners) {
            return;
        }

        this.handlersByEventName[eventType] = listeners.filter(listener => {
            return listener.toString() !== handler.toString();
        });
    }

    removeAllEventListeners() {
        this.handlersByEventName = {};
    }

    dispatch(event) {
        if (!(event instanceof BaseEvent)) {
            console.error(`The event name must be an instance of BaseEvent`);
            return false;
        }

        const listeners = this.handlersByEventName[event.eventType] || [];
        listeners.forEach((handler) => {
            handler(event);
        });
    }
}