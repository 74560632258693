import {getItemParams} from '../../utils/LocalizationUtils'
const MeasureTypes = {
  DH: {description: "Diameter of the femoral head, Dh = 2 * Rh", name: "dh", label: 'DH'},
  ISH: {description: "Sphericity index of the femoral head, ISH=2Rh/0,5A where A is a tear drop line length", name: "ish", label: 'ISH'},
  ISA: {description: "Sphericity index of the acetabulum, ISA=2Ra/0,5A where A is a tear drop line length", name: "isa", label: 'ISA'},
	ICAS: {description: "Congruence index, ICAS=ISA/ISH", name: "icas", label: 'ICAS'},
  ANGLE_A: {description: "Frontal inclination, calculated as angle between ab line and perpendicular from 'a' point", name: "angle_a", label: 'Angle A'},
  ANGLE_B: {description: "Inclination of the weight bearing surface, calculated as angle between v1b1 line and horizontal line", name: "angle_b", label: 'Angle B'},
  ANGLE_B1: {description: "Angle between vb1 line and horizontal line", name: "angle_b1", label: 'Angle B1'},
  ANGLE_V: {description: "Horizontal inclination of the AC, calculated as angle between ab and gb1 lines", name: "angle_v", label: 'Angle_V'},
  AC_COEFF: {description: "Acetabular coefficient, calculated D/W * 1000 where D=ab and W is a length of perpendicular from point d to the ab", name: "ac_coeff", label: 'AC Coeff'},
  WIBERG_ANGLE: {description: 'The center-edge angle (CEA) of Wiberg, calculated as the angle between the line from the center of the femoral head to the lateral edge of the acetabulum and vertical line', name: 'wiberg_angle', label: 'Angle of Wiberg'},
  ABDUCTION: {description: 'Abduction on the lateral x-ray', name: 'abduction', label: 'Abduction'},
  ANGLE_D: {description: 'The level of the frontal inclination, calculated as the angle between the line from the center of the femoral head to the frontal edge of the acetabulum (b2) and vertical line', name: 'angle_d', label: 'Angle D'},
};

export const getMeasureDataByType = (type) => {
  if (!MeasureTypes[type]) {
    return {};
  }

  const {name, ...rest} = MeasureTypes[type] ;
  const prefix = "measures";
  return {...rest, type, ...getItemParams(name, prefix)};
}

export const lateralMeasureTypes = ['WIBERG_ANGLE', 'ABDUCTION'];
export const frontalMeasureTypes = ['ANGLE_D'];
export const mainMeasureTypes = ['DH', 'ISH', 'ISA', 'ICAS', 'ANGLE_A', 'ANGLE_B', 'ANGLE_V', 'AC_COEFF'];