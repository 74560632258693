import React, {useContext} from 'react';
import './MainMarkupStep.scss';
import MarkupStep from "../MarkupStep/MarkupStep";
import MarkupModelProvider from "../../../contexts/MarkupModelContext";

const MainMarkupStep = () => {
    const {mainXRayData} = useContext(MarkupModelProvider);

    return (
        <MarkupStep xRayMarkupData={mainXRayData.markup}
                    measureCalculator={mainXRayData.calculator}
                    xRayType="main_xray_type"
                    showPreliminaryDiagnosis={true}></MarkupStep>
    );
};

MainMarkupStep.propTypes = {};

MainMarkupStep.defaultProps = {};

export default MainMarkupStep;
