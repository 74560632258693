import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {EventDispatcher} from "../model/EventDispatcher";
import {SelectionManager} from "../model/SelectionManager";
import {getLngOptionsURLParam} from "../utils/URLUtils";
import {Settings} from "../model/Settings";

const GlobalModelContext = React.createContext();

const GlobalModelProvider = ({ children }) => {
  const [eventDispatcher, setEventDispatcher] = useState(null);
  const [selectionManager, setSelectionManager] = useState(null);
  const [settings, setSettings] = useState(new Settings());

  useEffect(() => {
    setEventDispatcher(new EventDispatcher());
    const lngOptionsURL = getLngOptionsURLParam();
    if (lngOptionsURL && (lngOptionsURL === '1' || lngOptionsURL === 'true' || lngOptionsURL === true)) {
      setSettings((prevSettings => {
        prevSettings.showLanguageOptions = true;
        return prevSettings;
      }));
    }
  }, []);

  useEffect(() => {
    if (eventDispatcher) {
      setSelectionManager((prevSelectionManager) => {
        if (prevSelectionManager) {
          prevSelectionManager.destroy();
        }
        return new SelectionManager(eventDispatcher);
      });
    }
  }, [eventDispatcher]);

  if (!eventDispatcher || !selectionManager) {
    return null;
  }

  return (
    <GlobalModelContext.Provider value={{
      eventDispatcher,
      selectionManager,
      settings
    }}
    >
      {children}
    </GlobalModelContext.Provider>
  );
};

GlobalModelContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { GlobalModelContext as default, GlobalModelProvider };
