import MeasureResult from "../MeasureResult";
import Line from "../../base/Line";
import {getAngleWithVerticalFromCenter} from "../../../utils/MathUtils";
import MarkupAxisLine from "../../markup/MarkupAxisLine";

class Abduction extends MeasureResult {
  constructor(refMarkupData) {
    super('ABDUCTION', refMarkupData);
  }

  recalculateValue() {
    const abductionLine = this.getAbductionLine();
    const line = new Line(abductionLine.start.position, abductionLine.end.position);
    return getAngleWithVerticalFromCenter(line, this.getMeasureSideDirection());
  }

  getAbductionLine () {
    return this.refMarkupData.getAbduction();
  }

  recalculateShapes() {
    const {start, end} = this.getAbductionLine();

    this.shapes.push(this.getAxisLine('vAbdAxis', start.type, MarkupAxisLine.VERTICAL_DIRECTION, 100));
    this.shapes.push(this.getLine('abduction', [start, end].map(markupPoint => markupPoint.type)));
  }
}

export default Abduction;