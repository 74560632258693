import DependantMarkupPoint from "./DependantMarkupPoint";
import {getCircleBy3Points} from "../../utils/MathUtils";

class CircleCenterMarkupPoint extends DependantMarkupPoint {
  recalculatePosition () {
    const {center} = getCircleBy3Points(this.refMarkupPoints.map(markupPoint => markupPoint.position));
    return center;
  }
}

export default CircleCenterMarkupPoint;