import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {MainXRayMarkupData} from "../model/markup/MainXRayMarkupData";
import MainMeasureCalculator from "../model/measure/MainMeasureCalculator";
import {LateralXRayMarkupData} from "../model/markup/LateralXRayMarkupData";
import LateralMeasureCalculator from "../model/measure/LateralMeasureCalculator";
import {FrontalXRayMarkupData} from "../model/markup/FrontalXRayMarkupData";
import FrontalMeasureCalculator from "../model/measure/FrontalMeasureCalculator";
import {defaultAge} from "../model/base/AgeRangeTypes";
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";

const MarkupModelContext = React.createContext();

const MarkupModelProvider = ({ children }) => {
  const { t } = useTranslation();
  const [dataToImport, setDataToImport] = useState(null);

  const [age, setAge] = useState(defaultAge);

  const getMainXRayMarkupData = () => {
    const markup = new MainXRayMarkupData(defaultAge);
    const calculator = new MainMeasureCalculator(markup);
    return {markup, calculator};
  }

  const getLateralXRayMarkupData = () => {
    const markup = new LateralXRayMarkupData();
    const calculator = new LateralMeasureCalculator(markup);
    return {markup, calculator};
  }

  const getFrontalXRayMarkupData = () => {
    const markup = new FrontalXRayMarkupData();
    const calculator = new FrontalMeasureCalculator(markup);
    return {markup, calculator};
  }

  const [mainXRayData] = useState(getMainXRayMarkupData());
  const [lateralXRayData] = useState(getLateralXRayMarkupData());
  const [frontalXRayData] = useState(getFrontalXRayMarkupData());

  const resetData = useCallback(() => {
    if (!mainXRayData || !lateralXRayData || !frontalXRayData
        || !mainXRayData.markup || !lateralXRayData.markup || !frontalXRayData.markup) {
      return;
    }

    mainXRayData.markup.clearEntireMarkup();
    lateralXRayData.markup.clearEntireMarkup();
    frontalXRayData.markup.clearEntireMarkup();
    setAge(defaultAge);
    mainXRayData.markup.updateAge(defaultAge);
  }, [mainXRayData, lateralXRayData, frontalXRayData, setAge]);

  useEffect(() => {
    if (!dataToImport || !mainXRayData || !lateralXRayData || !frontalXRayData
        || !mainXRayData.markup || !lateralXRayData.markup || !frontalXRayData.markup) {
      return;
    }

    const {age:jsonAge, main, lateral, frontal} = dataToImport;
    try {
        mainXRayData.markup.updateAge(jsonAge);
        mainXRayData.markup.fromJSON(main);
        lateralXRayData.markup.fromJSON(lateral);
        frontalXRayData.markup.fromJSON(frontal);
        setAge(jsonAge);
        toast(t('general.import_success'), {type: 'success'});
    }
    catch(err) {
       toast(t('general.import_failed', {err}), {type: 'error'});
    }
    finally {
      setDataToImport(null);
    }
  }, [dataToImport, mainXRayData, lateralXRayData, frontalXRayData, setDataToImport, setAge, t])

  const importData = useCallback((jsonData) => {
    if (!mainXRayData || !lateralXRayData || !frontalXRayData
        || !mainXRayData.markup || !lateralXRayData.markup || !frontalXRayData.markup) {
      return;
    }

    resetData();
    setDataToImport(jsonData)

  }, [mainXRayData, lateralXRayData, frontalXRayData, setDataToImport, resetData]);

  const exportData = useCallback(() => {
    if (!mainXRayData || !lateralXRayData || !frontalXRayData
        || !mainXRayData.markup || !lateralXRayData.markup || !frontalXRayData.markup) {
      return null;
    }

    return {
      age,
      main: mainXRayData.markup.toJSON(),
      lateral: lateralXRayData.markup.toJSON(),
      frontal: frontalXRayData.markup.toJSON()
    }
  }, [mainXRayData, lateralXRayData, frontalXRayData, age]);

  useEffect(() => {
    if (!mainXRayData || !mainXRayData.markup || !age) {
      return;
    }

    mainXRayData.markup.updateAge(age);
  }, [mainXRayData, age]);

  useEffect(() => {
    return () => {
      [mainXRayData, lateralXRayData, frontalXRayData].forEach(xRayData => {
        if (xRayData) {
          xRayData.calculator.destroy();
          xRayData.markup.destroy();
        }
      });
    }
  }, [mainXRayData, lateralXRayData, frontalXRayData]);

  if (!mainXRayData || !lateralXRayData || !frontalXRayData) {
    return;
  }

  return (
    <MarkupModelContext.Provider value={{
      mainXRayData,
      lateralXRayData,
      frontalXRayData,
      age,
      setAge,
      importData,
      exportData,
      resetData
    }}
    >
      {children}
    </MarkupModelContext.Provider>
  );
};

MarkupModelContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { MarkupModelContext as default, MarkupModelProvider };
