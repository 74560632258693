import {getMeasureDataByType} from "../measure/MeasureTypes";

class CaseRule {
  constructor(measureResultType, range) {
    this.measureResultType = measureResultType;
    this.range = range;
  }

  match(measureResultsMap) {
    const result = measureResultsMap.get(this.measureResultType);
    if (!result || !result.value) {
      return false;
    }

    return this.validateResult(result.value);
  }

  validateResult (resultValue) {
    return this.range.isInRange(resultValue);
  }

  getRangeDescription () {
    return this.range.toString();
  }

  getRuleDescription(translate) {
    const {label} = getMeasureDataByType(this.measureResultType);
    return `${translate(label)}: ${this.getRangeDescription()}`;
  }
}

export default CaseRule;