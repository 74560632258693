import MeasureCalculator from "./MeasureCalculator";
import WibergAngle from "./results/WibergAngle";
import Abduction from "./results/Abduction";

class LateralMeasureCalculator extends MeasureCalculator {
   recreateMeasureResults() {
    return [
      new WibergAngle(this.markupData),
      new Abduction(this.markupData),
    ];
  }
}

export default LateralMeasureCalculator;