import React, {useContext} from 'react';
import './Age.scss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MarkupModelProvider from "../../../contexts/MarkupModelContext";
import {ageRanges} from "../../../model/base/AgeRangeTypes";

const Age = () => {
  const {age, setAge} = useContext(MarkupModelProvider);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <RadioGroup
        value={age}
        onChange={handleChange}
      >
      {
        ageRanges.map(({ageId, label}) => {
          return (<FormControlLabel key={ageId} value={ageId} control={<Radio />} label={label} />);
        })
      }
      </RadioGroup>
  );
};

Age.propTypes = {};

Age.defaultProps = {};

export default Age;
