import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './MarkupShapeView.scss';
import MarkupShape from "../../../model/markup/MarkupShape";
import {MarkupShapeEvent} from "../../../model/events/MarkupShapeEvent";

const MarkupShapeView = ({markupShape}) => {
  const svg = useRef(null);
  const [svgElem, setSvgElem] = useState(null);
  const [viewport, setViewport] = useState({width: 0, height: 0, x: 0, y: 0});

  useEffect(() => {
    if (svg && svg.current) {
      setSvgElem(svg.current);
    }
  }, [svg]);

  const removeAllShapeElements = useCallback(() => {
    while (svgElem.firstChild) {
      svgElem.removeChild(svgElem.firstChild);
    }
  }, [svgElem]);

  const getShapeElement = useCallback(() => {
    return markupShape.getShapeElement(svgElem.namespaceURI);
  }, [markupShape, svgElem]);

  const onGeometryChanged = useCallback(() => {
    const {x, y, width, height} = markupShape.getBoundingBox();
    setViewport({x, y, width, height});
    removeAllShapeElements();
    getShapeElement().forEach(node => {
      svgElem.appendChild(node);
    });

  }, [markupShape, svgElem, removeAllShapeElements, getShapeElement]);

  useEffect(() => {
    if (!markupShape || !svgElem) {
      return;
    }

    markupShape.addEventListener(MarkupShapeEvent.GEOMETRY_CHANGED, onGeometryChanged);
    onGeometryChanged();

    return () => {
      markupShape.removeEventListener(MarkupShapeEvent.GEOMETRY_CHANGED, onGeometryChanged);
    }
  }, [markupShape, svgElem, onGeometryChanged]);

  const strokeWidth = 2;
  return (<svg ref={svg} className="markup-shape"
               style={{
                 stroke: markupShape.color,
                 fill: "none",
                 top: viewport.y,
                 left: viewport.x
               }}
               width={Math.max(viewport.width, strokeWidth)}
               height={Math.max(viewport.height, strokeWidth)}>
  </svg>);
};

MarkupShapeView.propTypes = {
  markupShape: PropTypes.instanceOf(MarkupShape).isRequired,
};

MarkupShapeView.defaultProps = {};

export default MarkupShapeView;
