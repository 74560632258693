import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './PointsViewer.scss';
import GlobalModelProvider from "../../../contexts/GlobalModelContext";
import Point from "../../../model/base/Point";
import Size from "../../../model/base/Size";
import {PointEvent} from "../../../model/events/PointEvent";
import {MarkupDataEvent} from "../../../model/events/MarkupDataEvent";
import XRayMarkupData from "../../../model/markup/XRayMarkupData";
import MarkupPointView from "../MarkupPointView/MarkupPointView";

const PointerViewer = ({viewportSize, xRayMarkupData}) => {
  const [markupPoints, setMarkupPoints] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const {selectionManager} = useContext(GlobalModelProvider);
  const pointersContainer = useRef(null);

  let mouseDownPoint = null;
  let moveStartData = null;

  const onPointSelected = useCallback((event) => {
    const {selectedPoint:pointToSelect} = event.payload;
    setSelectedPoint(pointToSelect);
  }, []);

  useEffect(() => {
     selectionManager.addEventListener(PointEvent.POINT_SELECTED, onPointSelected);
     return () => {
      selectionManager.removeEventListener(PointEvent.POINT_SELECTED, onPointSelected);
    }
  }, [selectionManager, onPointSelected]);

  const onMarkupPointsChanged = useCallback(() => {
    setMarkupPoints(xRayMarkupData.markupPoints);
  }, [xRayMarkupData]);

  useEffect(() => {
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_POINT_ADDED, onMarkupPointsChanged);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, onMarkupPointsChanged);
    xRayMarkupData.addEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, onMarkupPointsChanged);
    return () => {
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_POINT_ADDED, onMarkupPointsChanged);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_CLEARED, onMarkupPointsChanged);
      xRayMarkupData.removeEventListener(MarkupDataEvent.MARKUP_DATA_IMPORTED, onMarkupPointsChanged);
    }
  }, [xRayMarkupData, onMarkupPointsChanged]);

  useEffect(() => {
    setMarkupPoints(xRayMarkupData.markupPoints);
  }, [xRayMarkupData]);

  const selectPoint = useCallback((markupPoint) => {
    selectionManager.selectPoint(markupPoint);
  }, [selectionManager]);

  const onPointClick = (markupPoint) => {
    selectPoint(markupPoint);
  }

  const onPointsContainerClick = (event) => {
    if (selectedPoint) {
      selectPoint(null);
    }
  }

  const onPointMouseDown = (markupPoint, selected) => {
    if (!selected) {
      selectPoint(markupPoint);
    }
    mouseDownPoint = markupPoint;
  }

  const onMouseDown = (event) => {
    if (mouseDownPoint) {
      const {offsetX, offsetY} = event.nativeEvent;
      const {offsetLeft:targetX, offsetTop:targetY} = event.target;
      const x = targetX + offsetX;
      const y = targetY + offsetY;
      moveStartData = {pointPos: mouseDownPoint.position.clone(), mousePos: new Point(x, y)};
      setMoving(true);
      addMouseEventListeners();
    }
  }

  const addMouseEventListeners = () => {
    pointersContainer.current.addEventListener('mousemove', onMouseMove, true);
    pointersContainer.current.addEventListener('mouseup', onMouseUp, true);
  }

  const removeMouseEventListeners = () => {
    pointersContainer.current.removeEventListener('mousemove', onMouseMove, true);
    pointersContainer.current.removeEventListener('mouseup', onMouseUp, true);
  }

  const setMoving = (moving) => {
    pointersContainer.current.classList.remove('moving');
    if (moving) {
      pointersContainer.current.classList.add('moving');
    }
  }

  const movePointAfterMouse = (event) => {
    const {offsetX:x, offsetY:y} = event;
    const currMousePos = new Point(x, y);
    const {mousePos, pointPos} = moveStartData;
    const {dx, dy} = currMousePos.getDelta(mousePos);
    mouseDownPoint.updatePosition({x: pointPos.x + dx, y: pointPos.y + dy});
  }

  const onMouseMove = (event) => {
    if (!mouseDownPoint) {
      return;
    }

    movePointAfterMouse(event);
  }

  const onMouseUp = (event) => {
    if (!mouseDownPoint) {
      return;
    }

    movePointAfterMouse(event);
    mouseDownPoint = null;
    moveStartData = null;
    setMoving(false);
    removeMouseEventListeners();
    const captureClickListener = (event) => {
      event.stopImmediatePropagation();
      pointersContainer.current.removeEventListener('click', captureClickListener, true);
    }
    pointersContainer.current.addEventListener('click', captureClickListener, true);
  }

  return (
    <div className="points-container" ref={pointersContainer}
         style={{width: `${viewportSize.width}px`, height: `${viewportSize.height}px`}}
         onClick={onPointsContainerClick} onMouseDown={onMouseDown}
    >
      {
        markupPoints.map(markupPoint => {
          return (
            <MarkupPointView
              key={markupPoint.id}
              markupPoint={markupPoint}
              onPointClick={onPointClick}
              onPointMouseDown={onPointMouseDown}
              selected={selectedPoint && selectedPoint.id === markupPoint.id}></MarkupPointView>
          );
        })
      }
    </div>
  )
};

PointerViewer.propTypes = {
  viewportSize: PropTypes.instanceOf(Size).isRequired,
  xRayMarkupData: PropTypes.instanceOf(XRayMarkupData).isRequired,
};

PointerViewer.defaultProps = {};

export default PointerViewer;
