import Line from "../../base/Line";
import {getAngleWithVerticalFromCenter} from "../../../utils/MathUtils";
import MarkupAxisLine from "../../markup/MarkupAxisLine";
import {femoralHeadPointTypes} from "../../markup/MarkupPointTypes";
import MeasureResult from "../MeasureResult";

class AngleD extends MeasureResult {
  constructor(refMarkupData) {
    super('ANGLE_D', refMarkupData);
  }

  recalculateValue() {
    // Femoral head
    const femoralHead = this.getFemoralHead();
    const b2Point = this.getB2MarkupPoint().position;

    const line = new Line(femoralHead.center, b2Point);
    return getAngleWithVerticalFromCenter(line, this.getMeasureSideDirection());
  }

  getB2MarkupPoint () {
    return this.refMarkupData.getMarkupPoint('AC_FRONTAL_UP_OUTER');
  }

  getFemoralHeadCenterPoint () {
    return this.refMarkupData.getMarkupPoint('FH_CIRCLE_CENTER');
  }

  recalculateShapes() {
    const bPoint = this.getB2MarkupPoint();
    const femoralHeadCenter = this.getFemoralHeadCenterPoint();

    this.shapes.push(this.getAxisLine('vFHAxis', femoralHeadCenter.type, MarkupAxisLine.VERTICAL_DIRECTION, -200));
    this.shapes.push(this.getLine('fhb', [bPoint, femoralHeadCenter].map(markupPoint => markupPoint.type)));
    this.shapes.push(this.getCircle('femoral', femoralHeadPointTypes));
  }
}

export default AngleD;