import XRayMarkupData from "./XRayMarkupData";
import {femoralHeadPointTypes, frontalMarkupPointTypes} from "./MarkupPointTypes";

export class FrontalXRayMarkupData extends XRayMarkupData {
  constructor() {
    super();
    this._requiredPointTypes = frontalMarkupPointTypes;
  }

  updateShapes(addedMarkupPoint) {
    const {type} = addedMarkupPoint;

    if (this.shouldAddShape(type, femoralHeadPointTypes)) {
      this.addShape(this.getFHCircleShape());
    }
  }

  validateFemoralHeadPoints() {
    if (!this.isFulfilled) {
      return true;
    }

    const points = this.getFemoralHeadMarkupPoints();
    if (!points || points.length < 3 || !points.every((markupPoint) => markupPoint !== null && markupPoint !== undefined)) {
      return false;
    }

    const [inner, outer, center] = points.map(markupPoint => markupPoint.position);
    if (center.x === inner.x || center.x === outer.x) {
      return false;
    }

    const ratio = (center.x - inner.x) / (outer.x - inner.x);
    return ratio > 0 && ratio < 1;
  }

  getFemoralHeadOrientation() {
    if (!this.isFulfilled) {
      return 0;
    }

    if (!this.validateFemoralHeadPoints()) {
      console.log('Incorrect markup for the femoral head');
      return 0;
    }
    const [inner, outer] = this.getFemoralHeadMarkupPoints().map(markupPoint => markupPoint.position);
    return ((outer.x - inner.x) / Math.abs(outer.x - inner.x));
  }

  getMarkupSideFromSagittalAxis () {
    return this.getFemoralHeadOrientation();
  }
}