import React, {useCallback, useContext, useRef} from 'react';
import './ImportFromFile.scss';
import MarkupModelProvider from "../../../contexts/MarkupModelContext";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";

const ImportFromFile = () => {
  const {importData} = useContext(MarkupModelProvider);
  const {t} = useTranslation();
  const fileInput = useRef(null);

  const onFileInputChange = useCallback(() => {
    const { files } = fileInput.current;
    if (!files.length || !files[0]) {
      return;
    }

    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const json = JSON.parse(fileReader.result);
        importData(json);
      };

      fileReader.onerror = () => {
        throw new Error(fileReader.error);
      };

      fileReader.readAsText(files[0]);
    }
    catch (err) {
      throw new Error(err);
    }
    finally {
      fileInput.current.value = '';
    }

  }, [fileInput, importData])

  const onImportClick = useCallback(() => {
    fileInput.current.click();
  }, [fileInput]);

  return (
     <>
       <input ref={fileInput} type="file" id="file-selector" accept=".json" onChange={onFileInputChange}
              style={{display: "none"}}/>
       <Button variant="contained" onClick={onImportClick} size="small">
        {t("general.import")}
       </Button>
     </>
  );
};

ImportFromFile.propTypes = {};

ImportFromFile.defaultProps = {};

export default ImportFromFile;
