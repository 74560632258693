import MarkupLine from "./MarkupLine";
import Point from "../base/Point";

class MarkupAxisLine extends MarkupLine {
  static VERTICAL_DIRECTION = 'vertical';
  static HORIZONTAL_DIRECTION = 'horizontal';
  constructor(name, label, description, refPoints = [], direction = MarkupAxisLine.HORIZONTAL_DIRECTION, length = 100, color = '#000') {
    super(name, label, description, refPoints, color);
    this.direction = direction;
    this.length = length;
    this.recalculateShapeGeometry();
  }

  checkRefPoints() {
    if (this._refPoints.length < 1) {
      return `Axis line shape requires 1 reference point, got ${this._refPoints.length}`
    }
  }

  recalculateShapeGeometry() {
    this.startPoint = this._refPoints[0].position;
    const {x:startX, y:startY} = this.startPoint;
    let endX, endY;
    if (this.direction === MarkupAxisLine.HORIZONTAL_DIRECTION) {
      endY = startY;
      endX = startX + this.length;
    }
    else {
      endY = startY + this.length;
      endX = startX;
    }

    this.endPoint = new Point(endX, endY);
  }
}

export default MarkupAxisLine;