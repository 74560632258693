import MarkupShape from "./MarkupShape";
import Point from "../base/Point";
import Line from "../base/Line";
import {getLineCenter} from "../../utils/MathUtils";

class MarkupLine extends MarkupShape {
  constructor(name, label, description, refPoints = [], color = '#000', showLabel = false) {
    super(name, label, description, refPoints, color);
    this.startPoint = null;
    this.endPoint = null;
    this.showLabel = showLabel;
    this.recalculateShapeGeometry();
  }

  checkRefPoints() {
    if (this._refPoints.length < 2) {
      return `Line shape requires 2 reference points, got ${this._refPoints.length}`
    }
  }

  recalculateShapeGeometry() {
    this.startPoint = this._refPoints[0].position;
    this.endPoint = this._refPoints[1].position;
  }

  getBoundingBox() {
    const points = [this.startPoint, this.endPoint];
    const hSorted = points.map(p => p.x).sort((x1, x2) => x1 - x2);
    const vSorted = points.map(p => p.y).sort((y1, y2) => y1 - y2);

    const rect = {left: hSorted[0], right: hSorted[1], top: vSorted[0], bottom: vSorted[1]};
    rect.x = rect.left;
    rect.width = rect.right - rect.left;
    rect.y = rect.top;
    rect.height = rect.bottom - rect.top;

    return rect;
  }

  getShapeElement (svgNS) {
    const {left, top} = this.getBoundingBox();

    const startPoint = new Point(this.startPoint.x - left, this.startPoint.y - top);
    const endPoint = new Point(this.endPoint.x - left, this.endPoint.y - top);

    const line = this.getLine(svgNS, startPoint, endPoint);

    const result = [line];

    if (this.showLabel) {
      result.push(this.getLabel(svgNS, this.label, getLineCenter(new Line(startPoint, endPoint))));
    }

    return result;
  }
}

export default MarkupLine;