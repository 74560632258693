import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import Step from "../model/base/Step";

const StepsContext = React.createContext();

const StepsProvider = ({ children }) => {
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    setSteps(getListOfSteps());
  }, []);

  useEffect(() => {
    if (steps.length) {
      setCurrentStep(steps[0]);
    }
  }, [steps]);

  const toStart = useCallback(() => {
    setCurrentStep(steps.find(step => step.stepId === 'age') || steps[0]);
  }, [setCurrentStep, steps]);

  const getListOfSteps = () => {
    return ["introduction", "age", "main_measures", "lateral_inclination_measures", "frontal_inclination_measures", "treatment"].map((stepId, index) => {
        return new Step(index, stepId);
    });
  };

  return (
    <StepsContext.Provider value={{
      steps,
      currentStep,
      setCurrentStep,
      toStart
    }}
    >
      {children}
    </StepsContext.Provider>
  );
};

StepsContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { StepsContext as default, StepsProvider };
