import React from 'react';
import PropTypes from 'prop-types';
import './MainWrapper.scss';
import {StepsProvider} from "../../contexts/StepsContext";
import {GlobalModelProvider} from "../../contexts/GlobalModelContext";
import {MarkupModelProvider} from "../../contexts/MarkupModelContext";

const MainWrapper = ({children}) => {
  return (
    <GlobalModelProvider>
      <MarkupModelProvider>
        <StepsProvider>
          {children}
        </StepsProvider>
      </MarkupModelProvider>
    </GlobalModelProvider>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MainWrapper.defaultProps = {};

export default MainWrapper;
