import MarkupShape from "./MarkupShape";
import Point from "../base/Point";
import {getCircleBy3Points} from "../../utils/MathUtils";

class MarkupCircle extends MarkupShape {
  constructor(name, label, description, refPoints = [], color = '#000', showRadius = false, radiusLabel = null) {
    super(name, label, description, refPoints, color);
    this.center = new Point();
    this.radius = 0;
    this.showRadius = showRadius;
    this.radiusLabel = radiusLabel;
    this.recalculateShapeGeometry();
  }

  checkRefPoints() {
    if (this._refPoints.length < 3) {
      return `Circle shape requires 3 reference points, got ${this._refPoints.length}`
    }
  }

  recalculateShapeGeometry() {
    const {center, radius} = getCircleBy3Points(this._refPoints.map(markupPoint => markupPoint.position));
    this.center = center;
    this.radius = radius;
  }

  getBoundingBox() {
    const rect = {left: this.center.x - this.radius, top: this.center.y - this.radius};
    rect.x = rect.left;
    rect.y = rect.top;
    rect.width = rect.height = Math.ceil(this.radius * 2);

    return rect;
  }

  getShapeElement (svgNS) {
    const {left, top} = this.getBoundingBox();
    const centerX = this.center.x - left;
    const centerY = this.center.y - top;

    const circle = document.createElementNS(svgNS, 'circle');
    circle.setAttribute('cx', centerX);
    circle.setAttribute('cy', centerY);
    circle.setAttribute('r', this.radius);

    const pointSize = 4;
    const center = document.createElementNS(svgNS, 'rect');
    center.setAttribute('x', centerX - pointSize / 2);
    center.setAttribute('y', centerY - pointSize / 2);
    center.setAttribute('width', pointSize);
    center.setAttribute('height', pointSize);

    const elements = [circle, center];

    if (this.showRadius) {
      const radiusLine = this.getLine(
        svgNS,
        new Point(centerX + pointSize / 2, centerY),
        new Point(centerX + this.radius, centerY));
      elements.push(radiusLine);

      if (this.radiusLabel && this.radiusLabel.length > 0) {
        elements.push(this.getLabel(svgNS, this.radiusLabel, new Point(centerX + this.radius / 2, centerY)));
      }
    }

    return elements;
  }
}

export default MarkupCircle;