import React, {useContext} from 'react';
import './MainShell.scss';
import StepsProvider from "../../contexts/StepsContext";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MainNavigation from "../MainNavigation/MainNavigation";
import StepsStack from "../steps/StepsStack/StepsStack";
import ChangeLanguage from "../controls/ChangeLanguage/ChangeLanguage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainShell = () => {
  const {currentStep} = useContext(StepsProvider);

  if (!currentStep) {
    return null;
  }

  return (
    <>
      <Stack direction="row"
             divider={<Divider orientation="vertical" flexItem/>}
             spacing={2}
             sx={{padding: '10px', width: '100%', height: '100%', boxSizing: 'border-box'}}>
        <MainNavigation></MainNavigation>
        <Stack direction="column"
               sx={{width: '100%', height: '100%', boxSizing: 'border-box', alignItems: 'flex-end'}}>
          <ChangeLanguage></ChangeLanguage>
          <StepsStack></StepsStack>
        </Stack>
      </Stack>
      <ToastContainer
        autoClose={false}
        position="top-right"
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{whiteSpace: 'pre-line'}}
      />
    </>
  );
};

MainShell.propTypes = {};

MainShell.defaultProps = {};

export default MainShell;
