const GeneralDiagnosisTypes = {
  NO_TREATMENT: {name: "no_treatment"},
  NO_OPERATION: {descr: "No operation", name: "no_operation"},
  ISH_NOT_APPLIED: {descr: "No operation", name: "ish_not_applied"},
  OSTEOTOMY: {descr: "Pelvic osteotomy", name: "osteotomy"},
  ACETABULOPLASTY: {descr: "Acetabuloplasty", name: "acetabuloplasty"},
  PPO: {descr: "Partial periacetabular osteotomy", name: "ppo"},
  G_OSTEOTOMY: {descr: "G-shaped osteotomy", name: "g_osteotomy"},
  PUBOFEMORAL_ILEAC_OSTEOTOMY: {descr: "Pubofemoral ileac osteotomy", name: "pubofemoral_ileac_osteotomy"},
  LATERAL_OSTEOTOMY: {descr: "Lateral osteotomy", name: "lateral_osteotomy"},
};

const AdditionalRecommendationTypes = {
  OSTEOTOMY_SAGITTAL_HORIZONTAL: {descr: "Due to the pelvic osteotomy sagittal and horizontal inclinations are provided",
                                  name: "osteotomy_sagittal_horizontal"},
  OSTEOTOMY_SAGITTAL_FRONTAL: {descr: "Due to the pelvic osteotomy sagittal and frontal inclinations are provided",
                               name: "osteotomy_sagittal_frontal"},
  OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL: {descr: "Due to the pelvic osteotomy sagittal, frontal and horizontal inclinations are provided",
                                          name: "osteotomy_sagittal_frontal_horizontal"},
  LATERAL_OSTEOTOMY_SAGITTAL_FRONTAL: {descr: "Due to the lateral osteotomy sagittal and frontal inclinations are provided",
                               name: "lateral_osteotomy_sagittal_frontal"},
  LATERAL_OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL: {descr: "Due to the lateral osteotomy sagittal, frontal and horizontal inclinations are provided",
                                          name: "lateral_osteotomy_sagittal_frontal_horizontal"},
  ACETABULOPLASTY_ADDITIONAL: {descr: "Because of the acetabuloplasty additinal lateral inclination is provided",
                    name: "acetabuloplasty_additional"},
  ACETABULOPLASTY_LATERAL_15: {descr: "Because of the acetabuloplasty additinal lateral inclination of 15 degrees is provided",
                    name: "acetabuloplasty_lateral_15"},
  PPO_LATERAL: {descr: "Additional lateral inclination due to PPO",
                name: "ppo_lateral"},
  PPO_FRONTAL_SAGITTAL: {descr: "Additional frontal and sagittal inclination due to PPO",
                         name: "ppo_frontal_sagittal"},
  FRONTAL_SAGITTAL: {descr: "Sagittal and frontal inclinations are provided",
                     name: "frontal_sagittal"},
  SAGITTAL_HORIZONTAL: {descr: "Sagittal and horizontal inclinations are provided",
                     name: "sagittal_horizontal"},
  FRONTAL_SAGITTAL_HORIZONTAL: {descr: "Sagittal, frontal and horizontal inclinations are provided",
                                name: "frontal_sagittal_horizontal"},
  FRONTAL_SAGITTAL_HORIZONTAL_ROTATION: {descr: "Sagittal, frontal inclinations and rotation in the horizontal surface are provided",
                                name: "frontal_sagittal_horizontal_rotation"},
  LATERAL_FORNIX_INCLINATION: {name: "lateral_fornix_inclination"}
};

const MainRecommendationTypes = {
  LATERAL_INCLINATION: {descr: "Level of the lateral inclination", name: "lateral_inclination"},
  ADDITIONAL_LATERAL_INCLINATION: {name: "additional_lateral_inclination"},
  FRONTAL_INCLINATION: {descr: "Level of the frontal inclination", name: "frontal_inclination"},
  HORIZONTAL_INCLINATION: {descr: "Level of the horizontal inclination", name: "horizontal_inclination"},
  TRANSPLANT_SIZE: {descr: "Transplant size", name: "transplant_size"},
};

const getDiagnosisData = (prefix, name) => {
  return `diagnosis.${prefix}.${name}.descr`;
}

export const getGeneralDiagnosisByType = (type) => {
  if (!GeneralDiagnosisTypes[type]) {
    return {};
  }

  const {name} = GeneralDiagnosisTypes[type];
  return getDiagnosisData('general', name);
}

export const getDiagnosisRecommendationByType = (type) => {
  if (!AdditionalRecommendationTypes[type]) {
    return {};
  }

  const {name} = AdditionalRecommendationTypes[type];
  return getDiagnosisData('additional_recommendations', name);
}

export const getMainRecommendationByType = (type) => {
  if (!MainRecommendationTypes[type]) {
    return {};
  }

  const {name} = MainRecommendationTypes[type];
  return getDiagnosisData('main_recommendations', name);
}
