import React, {useState} from 'react';
import {Handle, Position} from 'react-flow-renderer';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import './DiagnosisFlowNode.scss';

const DiagnosisFlowNode = ({data}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = anchorEl !== null;
  const id = open ? 'popover' : undefined;

  return (
    <>
      <Handle type="target" position={Position.Top}/>
      <div>
        <Typography sx={{fontWeight: 'bold', fontSize: 11}} color="text.secondary">{data.code}</Typography>
        <IconButton onClick={handleClick} size="small" sx={{padding: 0}}>
          <ReadMoreIcon fontSize="inherit"/>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Box sx={{padding: '10px', whiteSpace: 'pre-line'}} onClick={handleClose}>
            <Typography sx={{fontSize: 10, fontWeight: 'bold'}}>{data.label}</Typography>
            <Typography sx={{fontSize: 10}}>{data.description}</Typography>
          </Box>
        </Popover>
      </div>
    </>
  )
};

DiagnosisFlowNode.propTypes = {};

DiagnosisFlowNode.defaultProps = {};

export default DiagnosisFlowNode;
