import MeasureResult from "../MeasureResult";
import Line from "../../base/Line";
import MarkupAxisLine from "../../markup/MarkupAxisLine";
import {femoralHeadPointTypes} from "../../markup/MarkupPointTypes";
import {getAngleWithVerticalFromCenter} from "../../../utils/MathUtils";

class WibergAngle extends MeasureResult {
  constructor(refMarkupData) {
    super('WIBERG_ANGLE', refMarkupData);
  }

  recalculateValue() {
    // Femoral head
    const femoralHead = this.getFemoralHead();
    const bPoint = this.getBMarkupPoint().position;
    const line = new Line(femoralHead.center, bPoint);
    return getAngleWithVerticalFromCenter(line, this.getMeasureSideDirection());
  }

  getBMarkupPoint () {
    return this.refMarkupData.getMarkupPoint('AC_UP_OUTER');
  }

  getFemoralHeadCenterPoint () {
    return this.refMarkupData.getMarkupPoint('FH_CIRCLE_CENTER');
  }

  recalculateShapes() {
    const bPoint = this.getBMarkupPoint();
    const femoralHeadCenter = this.getFemoralHeadCenterPoint();

    this.shapes.push(this.getAxisLine('vFHAxis', femoralHeadCenter.type, MarkupAxisLine.VERTICAL_DIRECTION, -200));
    this.shapes.push(this.getLine('fhb', [bPoint, femoralHeadCenter].map(markupPoint => markupPoint.type)));
    this.shapes.push(this.getCircle('femoral', femoralHeadPointTypes));
  }
}

export default WibergAngle;