import React, {useContext} from 'react';
import './LateralMarkupStep.scss';
import MarkupStep from "../MarkupStep/MarkupStep";
import MarkupModelProvider from "../../../contexts/MarkupModelContext";

const LateralMarkupStep = () => {
    const {lateralXRayData} = useContext(MarkupModelProvider);
    const {markup, calculator} = lateralXRayData;

    return (
        <MarkupStep xRayMarkupData={markup}
                    measureCalculator={calculator}
                    xRayType="rotation_xray_type"></MarkupStep>
    );
};

LateralMarkupStep.propTypes = {};

LateralMarkupStep.defaultProps = {};

export default LateralMarkupStep;
