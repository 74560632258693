import Line from '../../base/Line';
import MarkupAxisLine from "../../markup/MarkupAxisLine";
import MainMeasureResult from "../MainMeasureResult";

class AngleB extends MainMeasureResult {
  constructor(refMarkupData) {
    super('ANGLE_B', refMarkupData);
    this.dependsOnMarkupDirection = true;
  }

  recalculateValue() {
    const v1Point = this.getV1MarkupPoint().position;
    const b1Point = this.getB1MarkupPoint().position;
    const line = new Line(v1Point, b1Point);
    return this.getAngleWithHorizontalDirection(line);
  }

  getV1MarkupPoint() {
    return this.refMarkupData.getMarkupPoint('AC_WEIGHT_BEARING_BOTTOM_EDGE');
  }

  recalculateShapes() {
    const v1Point = this.getV1MarkupPoint();
    const b1Point = this.getB1MarkupPoint();

    this.shapes.push(this.getAxisLine('v1Axis', v1Point.type, MarkupAxisLine.HORIZONTAL_DIRECTION, 200 * this.getHAxisDirection()));
    this.shapes.push(this.getLine('v1b1', [v1Point, b1Point].map(markupPoint => markupPoint.type)));
  }
}

export default AngleB;