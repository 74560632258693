import {
  getDiagnosisRecommendationByType,
  getGeneralDiagnosisByType,
  getMainRecommendationByType
} from "./DiagnosisTypes";
import Range from "../base/Range";
import CaseRule from "./CaseRule";
import {v4 as uuidv4} from "uuid";
import {AgeType, allAgeRanges, getAgeDescr} from "../base/AgeRangeTypes";

export const getBaseISHRule = () => new CaseRule('ISH', new Range(0.6, 1));

export class BaseDiagnosisCase {
  constructor() {
    this.id = uuidv4();
    this.code = this.getCode();
    this.invalid = false;
    this.ageRanges = this.getAgeRanges();
    this.rules = this.getRules();
    this.generalPrescriptions = this.getGeneralPrescriptions();
    this.additionalRecommendations = this.getRecommendations();
    this.recommendations = [{
      type: 'LATERAL_INCLINATION',
      callback: this.getLateralInclination.bind(this),
      descr: this.getLateralInclinationDescr.bind(this),
    },
      {
        type: 'ADDITIONAL_LATERAL_INCLINATION',
        callback: this.getAdditionalLateralInclination.bind(this),
        descr: this.getAdditionalLateralInclinationDescr.bind(this),
      },
      {
        type: 'FRONTAL_INCLINATION',
        callback: this.getFrontalInclination.bind(this),
        descr: this.getFrontalInclinationDescr.bind(this),
      },
      {
        type: 'HORIZONTAL_INCLINATION',
        callback: this.getHorizontalInclination.bind(this),
        descr: this.getHorizontalInclinationDescr.bind(this),
      },
      {
        type: 'TRANSPLANT_SIZE',
        callback: this.getTransplantSize.bind(this),
        descr: this.getTransplantSizeDescr.bind(this)}];
  }

  getAgeRanges() {
    // stub to override
    return [];
  }

  getCode() {
    // stub to override
    return null;
  }

  getGeneralPrescriptions() {
    // Stub to override
    return [];
  }

  getRecommendations() {
    // Stub to override
    return [];
  }

  // stub to override
  getTransplantType() {
    return null;
  }

  getRegularTransplantType() {
    return 'regular_transplant';
  }

  getTransplant25FixType() {
    return 'transplant_25';
  }

  getTransplantSize(measureResultMap) {
    switch (this.getTransplantType()) {
      case this.getRegularTransplantType():
        return this.getRegularTransplantSize(measureResultMap);
      case this.getTransplant25FixType():
        return 25;
      default:
        return null;
    }
  }

  getTransplantSizeDescr() {
    switch (this.getTransplantType()) {
      case this.getRegularTransplantType():
        return "diagnosis.calc_descriptions.regular_transplant";
      case this.getTransplant25FixType():
        return "diagnosis.calc_descriptions.transplant_25";
      default:
        return null;
    }
  }

  getRules() {
    // Stub to override
    return [
      getBaseISHRule()
    ];
  }

  isSuitableForAge(age) {
    return this.ageRanges.includes(age);
  }

  match(ageRange, mainMeasureResultsMap) {
    return this.isSuitableForAge(ageRange) && this.rules.every(rule => rule.match(mainMeasureResultsMap));
  }

  getGeneralTreatment(translate) {
    return this.generalPrescriptions.map((prescriptionType) => {
      return translate(getGeneralDiagnosisByType(prescriptionType));
    }).join(' + ');
  }

  getDiagnosisRecommendations(translate) {
    return this.additionalRecommendations.map((recommendationType) => {
      return translate(getDiagnosisRecommendationByType(recommendationType));
    }).join('\r\n');
  }

  getMainRecommendations(allMeasureResultsMap, translate) {
    return this.recommendations.map(({type, callback, descr}) => {
      const description = descr ? translate(descr()) : '';
      if (description && description.length > 0) {
        const label = translate(getMainRecommendationByType(type));
        const result = allMeasureResultsMap ? callback(allMeasureResultsMap) : null;
        const value = result ? parseFloat(result).toFixed(2) : null;
        return {label, description, value, type};
      }
      return null;
    }).filter(recommendation => recommendation !== null);
  }

  getMeasure(type, allMeasureResultsMap) {
    const measure = allMeasureResultsMap.get(type);
    if (!measure) {
      throw new Error(`Can't find measure type ${type}`);
    }

    return measure.value;
  }

  // stub to override
  getLateralInclination(allMeasureResultsMap) {
    const angleOfWiberg = this.getMeasure('WIBERG_ANGLE', allMeasureResultsMap);
    const abduction = this.getMeasure('ABDUCTION', allMeasureResultsMap);

    if (angleOfWiberg > 30) {
      throw new Error(`Angle of Wiberg should be less than 30, given value: ${angleOfWiberg}`);
    }

    return abduction + (30 - angleOfWiberg);
  }

  getLateralInclinationDescr() {
    return this.getRegularLateralInclinationDescr();
  }

  getRegularLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.regular_lateral_inclination";
  }

  getBLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.b_lateral_inclination";
  }

  // stub to override
  getAdditionalLateralInclinationAngle() {
    return null;
  }

  getAdditionalLateralInclinationAngleB() {
    return 'ANGLE_B';
  }

  getAdditionalLateralInclinationAngleB1() {
    return 'ANGLE_B1';
  }

  getAdditionalLateralInclination(allMeasureResultsMap) {
    switch (this.getAdditionalLateralInclinationAngle()) {
      case this.getAdditionalLateralInclinationAngleB():
        return (this.getBAngle(allMeasureResultsMap) - this.getLateralInclination(allMeasureResultsMap))
      case this.getAdditionalLateralInclinationAngleB1():
        return (this.getB1Angle(allMeasureResultsMap) - this.getLateralInclination(allMeasureResultsMap))
      default:
        return null;
    };
  }

  getAdditionalLateralInclinationDescr() {
    switch (this.getAdditionalLateralInclinationAngle()) {
      case this.getAdditionalLateralInclinationAngleB():
        return "diagnosis.calc_descriptions.additional_lateral_inclination_b";
      case this.getAdditionalLateralInclinationAngleB1():
        return "diagnosis.calc_descriptions.additional_lateral_inclination_b1";
      default:
        return '';
    };
  }

  // stub to override
  getFrontalInclination(allMeasureResultsMap) {
    const angleD = this.getMeasure('ANGLE_D', allMeasureResultsMap);

    if (angleD > 25) {
      throw new Error(`Angle D should be less than 25, given value: ${angleD}`);
    }

    return 25 - angleD;
  }

  getFrontalInclinationDescr() {
    return "diagnosis.calc_descriptions.regular_frontal_inclination";
  }

  // stub to override, 3 ranges by default
  getHorizontalInclinationRangesCount() {
    return this.get3RangesHorizontalInclination();
  }

  // stub to override, 15 limit value by default
  getHorizontalInclinationMunusLimit() {
    return this.getHorizontalInclination15MunusLimit();
  }

  get3RangesHorizontalInclination() {
    return 3;
  }

  get2RangesHorizontalInclination() {
    return 2;
  }

  getHorizontalInclination15MunusLimit() {
    return 15;
  }

  getHorizontalInclination20MunusLimit() {
    return 20;
  }

  getHorizontalInclination(allMeasureResultsMap) {
    if (this.getHorizontalInclinationRangesCount() === this.get3RangesHorizontalInclination()) {
      return this.getHorizontalInclination3Ranges(allMeasureResultsMap);
    } else if (this.getHorizontalInclinationRangesCount() === this.get2RangesHorizontalInclination()) {
      return this.getHorizontalInclination2Ranges(allMeasureResultsMap);
    }

    return null;
  }

  getHorizontalInclinationDescr() {
    if (this.getHorizontalInclinationRangesCount() === this.get3RangesHorizontalInclination()) {
      return this.getHorizontalInclination3RangesDescr();
    } else if (this.getHorizontalInclinationRangesCount() === this.get2RangesHorizontalInclination()) {
      return this.getHorizontalInclination2RangesDescr();
    }

    return '';
  }

  getHorizontalInclination3Ranges(allMeasureResultsMap) {
    const angleV = this.getVAngle(allMeasureResultsMap);

    if (angleV < 10) {
      return this.getHorizontalInclinationMunusLimit() - angleV;
    } else if (angleV > 20) {
      return angleV - 20;
    }

    return null;
  }

  getHorizontalInclination2Ranges(allMeasureResultsMap) {
    const angleV = this.getVAngle(allMeasureResultsMap);

    if (angleV <= 10) {
      return this.getHorizontalInclinationMunusLimit() - angleV;
    }

    return null;
  }

  getRuleForMeasureType(measureType) {
    return this.rules.find(rule => rule.measureResultType === measureType) || null;
  }

  getHorizontalInclination3RangesDescr() {
    const rule = this.getRuleForMeasureType('ANGLE_V');
    if (!rule) {
      return '';
    }

    if (rule.range.isEqual(new Range(10, 20))) {
      return '';
    } else if (rule.range.isEqual(new Range(undefined, 10, false))) {
      const minusLimit = this.getHorizontalInclinationMunusLimit();
      return `diagnosis.calc_descriptions.horizontal_inclination_${minusLimit}_minus_v`;
    } else if (rule.range.isEqual(new Range(20, undefined, false))) {
      return "diagnosis.calc_descriptions.horizontal_inclination_more_20";
    }

    return "";
  }

  getHorizontalInclination2RangesDescr() {
    const rule = this.getRuleForMeasureType('ANGLE_V');
    if (!rule) {
      return '';
    }

    if (rule.range.isEqual(new Range(undefined, 10))) {
      const minusLimit = this.getHorizontalInclinationMunusLimit();
      return `diagnosis.calc_descriptions.horizontal_inclination_${minusLimit}_minus_v`;
    }

    return "";
  }

  getVAngle(allMeasureResultsMap) {
    const angleV = this.getMeasure('ANGLE_V', allMeasureResultsMap);
    return angleV;
  }

  getBAngle(allMeasureResultsMap) {
    const angleB = this.getMeasure('ANGLE_B', allMeasureResultsMap);
    return angleB;
  }

  getB1Angle(allMeasureResultsMap) {
    const angleB1 = this.getMeasure('ANGLE_B1', allMeasureResultsMap);
    return angleB1;
  }

  getRegularTransplantSize(allMeasureResultsMap) {
    const dH = this.getMeasure('DH', allMeasureResultsMap);
    const ICAS = this.getMeasure('ICAS', allMeasureResultsMap);
    return 15 + dH.value * (1 - ICAS);
  }

  getAgeDescription(translate) {
    return translate('general.age') + ': ' + this.ageRanges.map(ageRange => getAgeDescr(ageRange)).join(', ');
  }

  getCaseDescription(translate) {
    return [...this.rules.map(rule => rule.getRuleDescription(translate))].join('; ');
  }
}

class DiagnosisCaseISHNoTreatment1 extends BaseDiagnosisCase {
  constructor() {
    super();
    this.invalid = true;
    this.recommendations = [];
  }

  getRules() {
    return [
      new CaseRule('ISH', new Range(undefined, 0.6, false))
    ]
  }

  getAgeRanges() {
    return allAgeRanges;
  }

  getCode() {
    return '';
  }

  getGeneralPrescriptions() {
    return ['ISH_NOT_APPLIED'];
  }
}

class DiagnosisCaseISHNoTreatment2 extends DiagnosisCaseISHNoTreatment1 {
  getRules() {
    return [
      new CaseRule('ISH', new Range(1, undefined, false))
    ]
  }
}

class DiagnosisCaseNoTreatment extends BaseDiagnosisCase {
  constructor() {
    super();
    this.invalid = true;
    this.recommendations = [];
  }

  getCode() {
    return '';
  }

  getAgeRanges() {
    return allAgeRanges;
  }

  getGeneralPrescriptions() {
    return ['NO_TREATMENT'];
  }
}

class BaseAge12DiagnosisCase extends BaseDiagnosisCase {
  getAgeRanges() {
    return [AgeType.R12_];
  }

  getCode() {
    return getAgeDescr(AgeType.R12_);
  }
}

class Base12ICASDiagnosisCase1 extends BaseAge12DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(1, 1.7))
    ];
  }

  getCode() {
    return '3.1';
  }
}

class Base12ICASDiagnosisCase2 extends BaseAge12DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(1.7, undefined, false))
    ];
  }

  getCode() {
    return '3.2';
  }
}

class Base12ICASDiagnosisCase3 extends BaseAge12DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(0.8, 0.99))
    ];
  }

  getCode() {
    return '3.3';
  }
}

class DiagnosisCase0 extends Base12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(45, undefined, false)),
      new CaseRule('ANGLE_B', new Range(undefined, 15))];
  }

  getCode() {
    return '3.1.0';
  }

  getGeneralPrescriptions() {
    return ['NO_OPERATION'];
  }
}

class BaseDiagnosisCase11 extends Base12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 40, false)),
      new CaseRule('ANGLE_B', new Range(15, undefined, false)),
      new CaseRule('AC_COEFF', new Range(150, undefined))
    ];
  }

  getCode() {
    return '3.1.1';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY'];
  }
}

class DiagnosisCase111 extends BaseDiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '3.1.1.a';
  }
}

class DiagnosisCase112 extends BaseDiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.1.1.b';
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }
}

class DiagnosisCase113 extends BaseDiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '3.1.1.c';
  }
}

class BaseDiagnosisCase12 extends Base12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 40, false)),
      new CaseRule('ANGLE_B', new Range(15, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 150, false))
    ];
  }

  getCode() {
    return '3.1.2';
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }
}

class DiagnosisCase121 extends BaseDiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.1.2a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class DiagnosisCase122 extends BaseDiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.1.2b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class DiagnosisCase123 extends BaseDiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.1.2c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class BaseDiagnosisCase13 extends Base12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(40, 45, false)),
      new CaseRule('ANGLE_B', new Range(15, undefined, false)),
      new CaseRule('AC_COEFF', new Range(120, undefined))
    ];
  }

  getCode() {
    return '3.1.3';
  }

  getLateralInclination(allMeasureResultsMap) {
    return super.getBAngle(allMeasureResultsMap);
  }

  getLateralInclinationDescr() {
    return this.getBLateralInclinationDescr();
  }

  getGeneralPrescriptions() {
    return ['PPO'];
  }
}

class DiagnosisCase131 extends BaseDiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.1.3a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class DiagnosisCase132 extends BaseDiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.1.3b';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'SAGITTAL_HORIZONTAL',
      'LATERAL_FORNIX_INCLINATION'
    ];
  }
}

class DiagnosisCase133 extends BaseDiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.1.3c';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'SAGITTAL_HORIZONTAL',
      'LATERAL_FORNIX_INCLINATION'
    ];
  }
}

class BaseDiagnosisCase14 extends Base12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(40, 45, false)),
      new CaseRule('ANGLE_B', new Range(15, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 120, false))
    ];
  }

  getCode() {
    return '3.1.4';
  }

  getGeneralPrescriptions() {
    return ['PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getLateralInclination(allMeasureResultsMap) {
    return super.getBAngle(allMeasureResultsMap);
  }

  getLateralInclinationDescr() {
    return this.getBLateralInclinationDescr();
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class DiagnosisCase141 extends BaseDiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.1.4a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class DiagnosisCase142 extends BaseDiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.1.4b';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'SAGITTAL_HORIZONTAL',
      'LATERAL_FORNIX_INCLINATION'
    ];
  }
}

class DiagnosisCase143 extends BaseDiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.1.4c';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'SAGITTAL_HORIZONTAL',
      'LATERAL_FORNIX_INCLINATION'
    ];
  }
}

class BaseDiagnosisCase21 extends Base12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B', new Range(15, undefined, false)),
      new CaseRule('AC_COEFF', new Range(100, undefined))
    ];
  }

  getCode() {
    return '3.2.1';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', 'PPO'];
  }
}

class DiagnosisCase211 extends BaseDiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.2.1a';
  }

  getLateralInclination(allMeasureResultsMap) {
    return super.getBAngle(allMeasureResultsMap);
  }

  getLateralInclinationDescr() {
    return this.getBLateralInclinationDescr();
  }

  getGeneralPrescriptions() {
    return ['PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class DiagnosisCase212 extends BaseDiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.2.1b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_HORIZONTAL',
      'PPO_LATERAL'
    ];
  }
}

class DiagnosisCase213 extends BaseDiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.2.1c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_HORIZONTAL',
      'PPO_LATERAL'
    ];
  }
}

class BaseDiagnosisCase22 extends Base12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B', new Range(15, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 100, false))
    ];
  }

  getGeneralPrescriptions() {
    return ['PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class DiagnosisCase221 extends BaseDiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.2.2a';
  }

  getLateralInclination(allMeasureResultsMap) {
    return super.getBAngle(allMeasureResultsMap);
  }

  getLateralInclinationDescr() {
    return this.getBLateralInclinationDescr();
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'PPO_FRONTAL_SAGITTAL'
    ];
  }
}

class DiagnosisCase222 extends BaseDiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.2.2b';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_HORIZONTAL',
      'PPO_LATERAL'
    ];
  }
}

class DiagnosisCase223 extends BaseDiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.2.2c';
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_HORIZONTAL',
      'PPO_LATERAL'
    ];
  }
}

class BaseDiagnosisCase23 extends Base12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35)),
      new CaseRule('ANGLE_B', new Range(15, undefined)),
      new CaseRule('AC_COEFF', new Range(100, undefined))
    ];
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', 'PPO'];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB();
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'PPO_LATERAL'
    ];
  }
}

class DiagnosisCase231 extends BaseDiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.2.3a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL'
    ];
  }
}

class DiagnosisCase232 extends BaseDiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.2.3b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL'
    ];
  }
}

class DiagnosisCase233 extends BaseDiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.2.3c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL'
    ];
  }
}

class BaseDiagnosisCase24 extends Base12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35)),
      new CaseRule('ANGLE_B', new Range(15, undefined)),
      new CaseRule('AC_COEFF', new Range(undefined, 100, false))
    ];
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', 'PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_LATERAL_15', 'PPO_LATERAL'];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB();
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class DiagnosisCase241 extends BaseDiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.2.4a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL'
    ];
  }
}

class DiagnosisCase242 extends BaseDiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.2.4b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL'
    ];
  }
}

class DiagnosisCase243 extends BaseDiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.2.4c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL'
    ];
  }
}


class BaseDiagnosisCase31 extends Base12ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35)),
      new CaseRule('ANGLE_B', new Range(15, undefined)),
      new CaseRule('AC_COEFF', new Range(undefined, 170, false))
    ];
  }

  getGeneralPrescriptions() {
    return ['OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}

class DiagnosisCase311 extends BaseDiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.3.1a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL'
    ];
  }
}

class DiagnosisCase312 extends BaseDiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.1.1b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL'
    ];
  }
}

class DiagnosisCase313 extends BaseDiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.3.1c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL'
    ];
  }
}

class BaseDiagnosisCase32 extends Base12ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B', new Range(undefined, 15, false))
    ];
  }
}

class DiagnosisCase321 extends BaseDiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('AC_COEFF', new Range(undefined, 170, false))
    ];
  }

  getCode() {
    return '3.2.1';
  }

  getGeneralPrescriptions() {
    return ['ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}


class DiagnosisCase322 extends BaseDiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('AC_COEFF', new Range(170, undefined))
    ];
  }

  getCode() {
    return '3.2.2';
  }

  getGeneralPrescriptions() {
    return ['NO_OPERATION'];
  }
}

////////// 7 - 9 /////////////

class BaseAge7to9DiagnosisCase extends BaseDiagnosisCase {
  getAgeRanges() {
    return [AgeType.R7_9];
  }

  getCode() {
    return getAgeDescr(AgeType.R7_9);
  }

  getHorizontalInclinationMunusLimit() {
    return this.getHorizontalInclination20MunusLimit();
  }
}

class Base7to9ICASDiagnosisCase1 extends BaseAge7to9DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(1, 1.7))
    ];
  }

  getCode() {
    return '1.1';
  }
}

class Base7to9ICASDiagnosisCase2 extends BaseAge7to9DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(1.7, undefined, false))
    ];
  }

  getCode() {
    return '1.2';
  }
}

class Base7to9ICASDiagnosisCase3 extends BaseAge7to9DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(0.8, 0.99))
    ];
  }

  getCode() {
    return '1.3';
  }
}

class Base7to9DiagnosisCase11 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(32, 40)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(170, undefined, false))
    ];
  }

  getCode() {
    return '1.1.a';
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY']
  }
}

class Diagnosis7to9Case111 extends Base7to9DiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, false))
    ];
  }

  getGeneralPrescriptions() {
    return ['G_OSTEOTOMY']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '1.1.a.a';
  }
}

class Diagnosis7to9Case112 extends Base7to9DiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }


  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '1.1.a.b';
  }
}

class Base7to9DiagnosisCase12 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(32, 40)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(150, 170))
    ];
  }

  getCode() {
    return '1.1.b';
  }
}

class Diagnosis7to9Case121 extends Base7to9DiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '1.1.b.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case122 extends Base7to9DiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '1.1.b.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case123 extends Base7to9DiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '1.1.b.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase13 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(32, 40)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(120, 149))
    ];
  }

  getCode() {
    return '1.1.c';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'PPO_LATERAL'
    ];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }
}

class Diagnosis7to9Case131 extends Base7to9DiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '1.1.c.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case132 extends Base7to9DiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '1.1.c.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case133 extends Base7to9DiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '1.1.c.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase14 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(32, 40)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(undefined, 120, false))
    ];
  }

  getCode() {
    return '1.1.d';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class Diagnosis7to9Case141 extends Base7to9DiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '1.1.d.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case142 extends Base7to9DiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '1.1.d.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case143 extends Base7to9DiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '1.1.d.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase15 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35, false)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(170, undefined, false))
    ];
  }

  getCode() {
    return '1.2.a';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY'];
  }

}

class Diagnosis7to9Case151 extends Base7to9DiagnosisCase15 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '1.2.a.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case152 extends Base7to9DiagnosisCase15 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '1.2.a.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case153 extends Base7to9DiagnosisCase15 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '1.2.a.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase16 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35, false)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(120, 170))
    ];
  }

  getCode() {
    return '1.2.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'PPO_LATERAL'
    ];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }
}

class Diagnosis7to9Case161 extends Base7to9DiagnosisCase16 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '1.2.b.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case162 extends Base7to9DiagnosisCase16 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '1.2.b.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case163 extends Base7to9DiagnosisCase16 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '1.2.b.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase17 extends Base7to9ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35, false)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 120, false))
    ];
  }

  getCode() {
    return '1.2.c';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class Diagnosis7to9Case171 extends Base7to9DiagnosisCase17 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '1.2.c.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case172 extends Base7to9DiagnosisCase17 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '1.2.c.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case173 extends Base7to9DiagnosisCase17 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '1.2.c.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase21 extends Base7to9ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(100, undefined, false))
    ];
  }

  getCode() {
    return '2.1.a';
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }
}

class Diagnosis7to9Case211 extends Base7to9DiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, undefined))
    ];
  }

  getCode() {
    return '2.1.a.a';
  }

  getGeneralPrescriptions() {
    return ['PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case212 extends Base7to9DiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getCode() {
    return '2.1.a.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase22 extends Base7to9ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(30, 35)),
      new CaseRule('ANGLE_B1', new Range(25, 40)),
      new CaseRule('AC_COEFF', new Range(100, undefined, false))
    ];
  }

  getCode() {
    return '2.1.b';
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }

  getHorizontalInclinationMunusLimit() {
    return this.getHorizontalInclination15MunusLimit();
  }

}

class Diagnosis7to9Case221 extends Base7to9DiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, undefined))
    ];
  }

  getCode() {
    return '2.1.b.a';
  }

  getGeneralPrescriptions() {
    return ['LATERAL_OSTEOTOMY', 'PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'LATERAL_OSTEOTOMY_SAGITTAL_FRONTAL',
      'PPO_LATERAL'
    ];
  }
}

class Diagnosis7to9Case222 extends Base7to9DiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getCode() {
    return '2.1.b.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'LATERAL_OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL',
      'PPO_LATERAL'
    ];
  }
}

class Base7to9DiagnosisCase23 extends Base7to9ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(undefined, 100, false))
    ];
  }

  getCode() {
    return '2.1.c';
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }
}

class Diagnosis7to9Case231 extends Base7to9DiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, undefined))
    ];
  }

  getCode() {
    return '2.1.c.a';
  }

  getGeneralPrescriptions() {
    return ['PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL',
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }
}

class Diagnosis7to9Case232 extends Base7to9DiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getCode() {
    return '2.1.c.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL_ROTATION',
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }
}

class Base7to9DiagnosisCase24 extends Base7to9ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(30, 35)),
      new CaseRule('ANGLE_B1', new Range(25, 40)),
      new CaseRule('AC_COEFF', new Range(undefined, 100))
    ];
  }

  getCode() {
    return '2.1.d';
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class Diagnosis7to9Case241 extends Base7to9DiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, undefined))
    ];
  }

  getCode() {
    return '2.1.d.a';
  }

  getGeneralPrescriptions() {
    return ['LATERAL_OSTEOTOMY', 'PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'LATERAL_OSTEOTOMY_SAGITTAL_FRONTAL',
      'PPO_LATERAL',
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }
}

class Diagnosis7to9Case242 extends Base7to9DiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getCode() {
    return '2.1.d.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'OSTEOTOMY_SAGITTAL_FRONTAL_HORIZONTAL',
      'PPO_LATERAL',
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }
}

class Base7to9DiagnosisCase25 extends Base7to9ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 30, false)),
      new CaseRule('ANGLE_B1', new Range(40, undefined, false)),
      new CaseRule('AC_COEFF', new Range(100, undefined, false))
    ];
  }

  getCode() {
    return '2.2.a';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO'];
  }

  getHorizontalInclinationMunusLimit() {
    return super.getHorizontalInclination15MunusLimit();
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'PPO_LATERAL'
    ];
  }
}

class Diagnosis7to9Case251 extends Base7to9DiagnosisCase25 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '2.2.a.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case252 extends Base7to9DiagnosisCase25 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '2.2.a.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case253 extends Base7to9DiagnosisCase25 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '2.2.a.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase26 extends Base7to9ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 30, false)),
      new CaseRule('ANGLE_B1', new Range(40, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 100))
    ];
  }

  getCode() {
    return '2.2.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO', 'ACETABULOPLASTY'];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'PPO_LATERAL',
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }

}

class Diagnosis7to9Case261 extends Base7to9DiagnosisCase26 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '2.2.b.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case262 extends Base7to9DiagnosisCase26 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '2.2.b.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case263 extends Base7to9DiagnosisCase26 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '2.2.b.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase31 extends Base7to9ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35, false)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 120))
    ];
  }

  getCode() {
    return '3.1';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap);
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1";
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}

class Diagnosis7to9Case311 extends Base7to9DiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.1.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case312 extends Base7to9DiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.1.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case313 extends Base7to9DiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.1.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase32 extends Base7to9ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35, false)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(120, undefined, false))
    ];
  }

  getCode() {
    return '3.2';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }

  getHorizontalInclinationMunusLimit() {
    return this.getHorizontalInclination15MunusLimit();
  }
}

class Diagnosis7to9Case321 extends Base7to9DiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.2.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case322 extends Base7to9DiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.2.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case323 extends Base7to9DiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.2.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase33 extends Base7to9ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, 39)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(120, undefined, false))
    ];
  }

  getCode() {
    return '3.3';
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }
}

class Diagnosis7to9Case331 extends Base7to9DiagnosisCase33 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, false))
    ];
  }

  getCode() {
    return '3.3.a';
  }

  getGeneralPrescriptions() {
    return ['G_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL',
      'FRONTAL_SAGITTAL'
    ];
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}

class Diagnosis7to9Case332 extends Base7to9DiagnosisCase33 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getCode() {
    return '3.3.b';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Base7to9DiagnosisCase34 extends Base7to9ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, 39)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(undefined, 120))
    ];
  }

  getCode() {
    return '3.4';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap);
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1";
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}

class Diagnosis7to9Case341 extends Base7to9DiagnosisCase34 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getCode() {
    return '3.4.a';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL'
    ];
  }
}

class Diagnosis7to9Case342 extends Base7to9DiagnosisCase34 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getCode() {
    return '3.4.b';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case343 extends Base7to9DiagnosisCase34 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getCode() {
    return '3.4.c';
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'FRONTAL_SAGITTAL_HORIZONTAL'
    ];
  }
}

class Diagnosis7to9Case35 extends Base7to9ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(39, undefined, false)),
      new CaseRule('ANGLE_B1', new Range(20, 30)),
      new CaseRule('AC_COEFF', new Range(undefined, 180, false))
    ];
  }

  getCode() {
    return '3.5';
  }

  getGeneralPrescriptions() {
    return ['ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [
      ...super.getRecommendations(),
      'ACETABULOPLASTY_ADDITIONAL'
    ];
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }

  getHorizontalInclination(allMeasureResultsMap) {
    return null;
  }

  getHorizontalInclinationDescr() {
    return '';
  }

  getLateralInclination(allMeasureResultsMap) {
    return null;
  }

  getLateralInclinationDescr() {
    return '';
  }

  getFrontalInclination(allMeasureResultsMap) {
    return null;
  }

  getFrontalInclinationDescr() {
    return '';
  }
}

////////// 10 - 12 /////////////

class BaseAge10to12DiagnosisCase extends BaseDiagnosisCase {
  getAgeRanges() {
    return [AgeType.R10_12];
  }

  getCode() {
    return getAgeDescr(AgeType.R10_12);
  }

  getHorizontalInclinationMunusLimit() {
    return this.getHorizontalInclination20MunusLimit();
  }
}

class Base10to12ICASDiagnosisCase1 extends BaseAge10to12DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(1, 1.7))
    ];
  }

  getCode() {
    return '2.1';
  }
}

class Base10to12ICASDiagnosisCase2 extends BaseAge10to12DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(1.7, undefined, false))
    ];
  }

  getCode() {
    return '2.2';
  }
}

class Base10to12ICASDiagnosisCase3 extends BaseAge10to12DiagnosisCase {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ICAS', new Range(0.8, 0.99))
    ];
  }

  getCode() {
    return '2.3';
  }
}

class Base10to12DiagnosisCase11 extends Base10to12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(30, 40)),
      new CaseRule('ANGLE_B1', new Range(25, 40)),
      new CaseRule('AC_COEFF', new Range(120, undefined, false))
    ];
  }

  getCode() {
    return '2.1.1';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY']
  }
}

class Diagnosis10to12Case111 extends Base10to12DiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.1.1.a';
  }
}

class Diagnosis10to12Case112 extends Base10to12DiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.1.b';
  }
}

class Diagnosis10to12Case113 extends Base10to12DiagnosisCase11 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.1.c';
  }
}

class Base10to12DiagnosisCase12 extends Base10to12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 30, false)),
      new CaseRule('ANGLE_B1', new Range(40, undefined, false)),
      new CaseRule('AC_COEFF', new Range(100, undefined, false))
    ];
  }

  getCode() {
    return '2.1.2';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'PPO_LATERAL'];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }
}

class Diagnosis10to12Case121 extends Base10to12DiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.1.2.a';
  }
}

class Diagnosis10to12Case122 extends Base10to12DiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.2.b';
  }
}

class Diagnosis10to12Case123 extends Base10to12DiagnosisCase12 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.2.c';
  }
}

class Base10to12DiagnosisCase13 extends Base10to12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(30, 40)),
      new CaseRule('ANGLE_B1', new Range(25, 40)),
      new CaseRule('AC_COEFF', new Range(undefined, 120))
    ];
  }

  getCode() {
    return '2.1.3';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class Diagnosis10to12Case131 extends Base10to12DiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.1.3.a';
  }
}

class Diagnosis10to12Case132 extends Base10to12DiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.3.b';
  }
}

class Diagnosis10to12Case133 extends Base10to12DiagnosisCase13 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.3.c';
  }
}

class Base10to12DiagnosisCase14 extends Base10to12ICASDiagnosisCase1 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 30, false)),
      new CaseRule('ANGLE_B1', new Range(40, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 100))
    ];
  }

  getCode() {
    return '2.1.4';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY', 'PPO']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL', 'PPO_LATERAL'];
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }
}

class Diagnosis10to12Case141 extends Base10to12DiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.1.4.a';
  }
}

class Diagnosis10to12Case142 extends Base10to12DiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.4.b';
  }
}

class Diagnosis10to12Case143 extends Base10to12DiagnosisCase14 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.1.4.c';
  }
}

class Base10to12DiagnosisCase21 extends Base10to12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(130, undefined, false))
    ];
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getCode() {
    return '2.2.1';
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }
}

class Diagnosis10to12Case211 extends Base10to12DiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, false))
    ];
  }

  getGeneralPrescriptions() {
    return ['PPO']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.2.1.a';
  }
}

class Diagnosis10to12Case212 extends Base10to12DiagnosisCase21 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.2.1.b';
  }
}

class Base10to12DiagnosisCase22 extends Base10to12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(35, undefined, false)),
      new CaseRule('ANGLE_B1', new Range(25, 35)),
      new CaseRule('AC_COEFF', new Range(undefined, 130))
    ];
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }

  getCode() {
    return '2.2.2';
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }
}

class Diagnosis10to12Case221 extends Base10to12DiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, false))
    ];
  }

  getGeneralPrescriptions() {
    return ['PPO', 'ACETABULOPLASTY']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL', 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.2.2.a';
  }
}

class Diagnosis10to12Case222 extends Base10to12DiagnosisCase22 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO', 'ACETABULOPLASTY']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL', 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.2.2.b';
  }
}

class Base10to12DiagnosisCase23 extends Base10to12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(100, undefined, false))
    ];
  }

  getCode() {
    return '2.2.3';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO']
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'PPO_LATERAL'];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }
}

class Diagnosis10to12Case231 extends Base10to12DiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.2.3.a';
  }
}

class Diagnosis10to12Case232 extends Base10to12DiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.2.3.b';
  }
}

class Diagnosis10to12Case233 extends Base10to12DiagnosisCase23 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.2.3.c';
  }
}

class Base10to12DiagnosisCase24 extends Base10to12ICASDiagnosisCase2 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 35)),
      new CaseRule('ANGLE_B1', new Range(35, undefined, false)),
      new CaseRule('AC_COEFF', new Range(undefined, 100))
    ];
  }

  getCode() {
    return '2.2.4';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'PPO', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'PPO_LATERAL', 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getAdditionalLateralInclinationAngle() {
    return this.getAdditionalLateralInclinationAngleB1();
  }

  getTransplantType() {
    return this.getTransplant25FixType();
  }
}

class Diagnosis10to12Case241 extends Base10to12DiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.2.4.a';
  }
}

class Diagnosis10to12Case242 extends Base10to12DiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.2.4.b';
  }
}

class Diagnosis10to12Case243 extends Base10to12DiagnosisCase24 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.2.4.c';
  }
}

class Base10to12DiagnosisCase31 extends Base10to12ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 37)),
      new CaseRule('ANGLE_B1', new Range(25, undefined)),
      new CaseRule('AC_COEFF', new Range(undefined, 130, false))
    ];
  }

  getCode() {
    return '2.3.1';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getHorizontalInclinationMunusLimit() {
    return super.getHorizontalInclination15MunusLimit();
  }

  getLateralInclination(allMeasureResultsMap) {
    return this.getB1Angle(allMeasureResultsMap) - 10;
  }

  getLateralInclinationDescr() {
    return "diagnosis.calc_descriptions.lateral_inclination_b1_minus_10";
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}

class Diagnosis10to12Case311 extends Base10to12DiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.3.1.a';
  }
}

class Diagnosis10to12Case312 extends Base10to12DiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.3.1.b';
  }
}

class Diagnosis10to12Case313 extends Base10to12DiagnosisCase31 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.3.1.c';
  }
}

class Base10to12DiagnosisCase32 extends Base10to12ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(undefined, 37)),
      new CaseRule('ANGLE_B1', new Range(25, undefined)),
      new CaseRule('AC_COEFF', new Range(130, undefined))
    ];
  }

  getCode() {
    return '2.3.2';
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', 'ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getHorizontalInclinationMunusLimit() {
    return super.getHorizontalInclination15MunusLimit();
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }
}

class Diagnosis10to12Case321 extends Base10to12DiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, 20))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.3.2.a';
  }
}

class Diagnosis10to12Case322 extends Base10to12DiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(20, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.3.2.b';
  }
}

class Diagnosis10to12Case323 extends Base10to12DiagnosisCase32 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.3.2.c';
  }
}

class Base10to12DiagnosisCase33 extends Base10to12ICASDiagnosisCase3 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_A', new Range(39, undefined, false)),
      new CaseRule('ANGLE_B1', new Range(20, 30)),
      new CaseRule('AC_COEFF', new Range(undefined, 180, false))
    ];
  }

  getCode() {
    return '2.3.3';
  }

  getGeneralPrescriptions() {
    return ['ACETABULOPLASTY'];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'ACETABULOPLASTY_ADDITIONAL'];
  }

  getTransplantType() {
    return this.getRegularTransplantType();
  }

  getHorizontalInclinationRangesCount() {
    return this.get2RangesHorizontalInclination();
  }
}

class Diagnosis10to12Case331 extends Base10to12DiagnosisCase33 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(10, undefined, false))
    ];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL'];
  }

  getCode() {
    return '2.3.3.a';
  }
}

class Diagnosis10to12Case332 extends Base10to12DiagnosisCase33 {
  getRules() {
    return [
      ...super.getRules(),
      new CaseRule('ANGLE_V', new Range(undefined, 10))
    ];
  }

  getGeneralPrescriptions() {
    return ['PUBOFEMORAL_ILEAC_OSTEOTOMY', ...super.getGeneralPrescriptions()];
  }

  getRecommendations() {
    return [...super.getRecommendations(), 'FRONTAL_SAGITTAL_HORIZONTAL'];
  }

  getCode() {
    return '2.3.3.b';
  }
}


const diagnosisCases = [
  new DiagnosisCaseISHNoTreatment1(), new DiagnosisCaseISHNoTreatment2(),
  new DiagnosisCase0(),
  new DiagnosisCase111(), new DiagnosisCase112(), new DiagnosisCase113(),
  new DiagnosisCase121(), new DiagnosisCase122(), new DiagnosisCase123(),
  new DiagnosisCase131(), new DiagnosisCase132(), new DiagnosisCase133(),
  new DiagnosisCase141(), new DiagnosisCase142(), new DiagnosisCase143(),
  new DiagnosisCase211(), new DiagnosisCase212(), new DiagnosisCase213(),
  new DiagnosisCase221(), new DiagnosisCase222(), new DiagnosisCase223(),
  new DiagnosisCase231(), new DiagnosisCase232(), new DiagnosisCase233(),
  new DiagnosisCase241(), new DiagnosisCase242(), new DiagnosisCase243(),
  new DiagnosisCase311(), new DiagnosisCase312(), new DiagnosisCase313(),
  new DiagnosisCase321(), new DiagnosisCase322(),
  new Diagnosis7to9Case111(), new Diagnosis7to9Case112(),
  new Diagnosis7to9Case121(), new Diagnosis7to9Case122(), new Diagnosis7to9Case123(),
  new Diagnosis7to9Case131(), new Diagnosis7to9Case132(), new Diagnosis7to9Case133(),
  new Diagnosis7to9Case141(), new Diagnosis7to9Case142(), new Diagnosis7to9Case143(),
  new Diagnosis7to9Case151(), new Diagnosis7to9Case152(), new Diagnosis7to9Case153(),
  new Diagnosis7to9Case161(), new Diagnosis7to9Case162(), new Diagnosis7to9Case163(),
  new Diagnosis7to9Case171(), new Diagnosis7to9Case172(), new Diagnosis7to9Case173(),
  new Diagnosis7to9Case211(), new Diagnosis7to9Case212(),
  new Diagnosis7to9Case221(), new Diagnosis7to9Case222(),
  new Diagnosis7to9Case231(), new Diagnosis7to9Case232(),
  new Diagnosis7to9Case241(), new Diagnosis7to9Case242(),
  new Diagnosis7to9Case251(), new Diagnosis7to9Case252(), new Diagnosis7to9Case253(),
  new Diagnosis7to9Case261(), new Diagnosis7to9Case262(), new Diagnosis7to9Case263(),
  new Diagnosis7to9Case311(), new Diagnosis7to9Case312(), new Diagnosis7to9Case313(),
  new Diagnosis7to9Case321(), new Diagnosis7to9Case322(), new Diagnosis7to9Case323(),
  new Diagnosis7to9Case331(), new Diagnosis7to9Case332(),
  new Diagnosis7to9Case341(), new Diagnosis7to9Case342(), new Diagnosis7to9Case343(),
  new Diagnosis7to9Case35(),
  new Diagnosis10to12Case111(), new Diagnosis10to12Case112(), new Diagnosis10to12Case113(),
  new Diagnosis10to12Case121(), new Diagnosis10to12Case122(), new Diagnosis10to12Case123(),
  new Diagnosis10to12Case131(), new Diagnosis10to12Case132(), new Diagnosis10to12Case133(),
  new Diagnosis10to12Case141(), new Diagnosis10to12Case142(), new Diagnosis10to12Case143(),
  new Diagnosis10to12Case211(), new Diagnosis10to12Case212(),
  new Diagnosis10to12Case221(), new Diagnosis10to12Case222(),
  new Diagnosis10to12Case231(), new Diagnosis10to12Case232(), new Diagnosis10to12Case233(),
  new Diagnosis10to12Case241(), new Diagnosis10to12Case242(), new Diagnosis10to12Case243(),
  new Diagnosis10to12Case311(), new Diagnosis10to12Case312(), new Diagnosis10to12Case313(),
  new Diagnosis10to12Case321(), new Diagnosis10to12Case322(), new Diagnosis10to12Case323(),
  new Diagnosis10to12Case331(), new Diagnosis10to12Case332()
];

export const getDiagnosisCase = (age, mainMeasureResultsMap) => {
  const foundCase = diagnosisCases.find(diagnosisCase => diagnosisCase.match(age, mainMeasureResultsMap));
  if (!foundCase) {
    return new DiagnosisCaseNoTreatment();
  }

  return foundCase;
}

export const getAllDiagnosisCases = (age) => {
  return diagnosisCases.filter(diagnosis => diagnosis.isSuitableForAge(age));
}