class Range {
  constructor(min = -Infinity, max = Infinity, includeEdges = true) {
    this.min = min;
    this.max = max;
    this.includeEdges = includeEdges;
  }

  isInRange(value) {
    if (this.includeEdges) {
      return value >= this.min && value <= this.max;
    }
    else {
      return value > this.min && value < this.max;
    }
  }

  isEqual(range) {
    return this.min === range.min && this.max === range.max && this.includeEdges === range.includeEdges;
  }

  isDefined() {
    return this.min > -Infinity || this.max < Infinity;
  }

  toString() {
    if (this.min === -Infinity && this.max === Infinity) {
      return '';
    }
    else if (this.min === -Infinity) {
      return `${this.includeEdges ? '<=' : '<'} ${this.max}`;
    }
    else if (this.max === Infinity) {
      return `${this.includeEdges ? '>=' : '>'} ${this.min}`;
    }

    const minmaxRange = `${this.min} - ${this.max}`;

    if (this.includeEdges) {
      return `[${minmaxRange}]`;
    }
    else {
      return `(${minmaxRange})`;
    }
  }
}

export default Range;