class Point {
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  update ({x, y}) {
    this.x = x;
    this.y = y;
    return this;
  }

  getDelta ({x, y}) {
    return {dx : this.x - x, dy: this.y - y};
  }

  add ({dx, dy}) {
    this.update({x: this.x + dx, y: this.y + dy});
    return this;
  }

  clone () {
    return new Point(this.x, this.y);
  }

  toJSON() {
    const {x, y} = this;
    return {x, y};
  }

  fromJSON(jsonData) {
    this.update(jsonData);
  }
}

export default Point;