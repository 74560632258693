import React from 'react';
import PropTypes from 'prop-types';
import './DiagnosisItemView.scss';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {BaseDiagnosisCase} from "../../../model/diagnosis/DiagnosisCase";
import {useTranslation} from "react-i18next";

const DiagnosisItemView = ({diagnosisCase, allMeasureResultsMap}) => {
  const {t} = useTranslation();
  const {code, invalid} = diagnosisCase;
  const caseDescription = diagnosisCase.getCaseDescription(t);
  const prescription = diagnosisCase.getGeneralTreatment(t);
  const mainRecommendations = diagnosisCase.getMainRecommendations(allMeasureResultsMap, t);
  const additionalRecommendations = diagnosisCase.getDiagnosisRecommendations(t);
  const measureResults = allMeasureResultsMap
                          ? Array.from(allMeasureResultsMap, ([key, value]) => value)
                                 .map(({type, value, label}) => {return {
                                   type, label: t(label), value: value.toFixed(2)
                                 }})
                          : null;

  const subtitleStyle = {fontSize: 12, fontWeight: 'bold'};
  const titleStyle = {...subtitleStyle, textDecoration: 'underline', paddingBottom: '5px', paddingTop: '10px'};

  return (
    <Stack sx={{padding: '4px', width: '100%', whiteSpace: 'pre-wrap', boxSizing: 'border-box'}} direction="column">
      {
        caseDescription && caseDescription.length > 0
          ? <>
              <Typography sx={{...subtitleStyle}} color="text.secondary" gutterBottom>
                {code}
              </Typography>
              <Typography sx={{fontStyle: 'italic', fontWeight: 'bold', fontSize: 11}}
                          color="text.secondary" gutterBottom>
                {caseDescription}
              </Typography>
            </>
          : null
      }
      <div style={{paddingBottom: '5px'}}>
        <Typography sx={{...titleStyle}} color="text.secondary" gutterBottom component="span">
          {`${t('general.treatment')}`}
        </Typography>
        <Typography sx={{...subtitleStyle}} color={invalid ? "error" : "text.secondary"} gutterBottom component="span">
          {`: ${prescription}`}
        </Typography>
      </div>
      {
       additionalRecommendations
          ? <Typography color="text.secondary" gutterBottom sx={{fontSize: 10}}>
              {additionalRecommendations}
            </Typography>
          : null
      }
      {
        mainRecommendations
          ? mainRecommendations.map(({label, value, description, type}) => {
              return (
                <div key={type} >
                  <Typography component="span" color="text.secondary" sx={{fontSize: 10}}>{`${label}`}</Typography>
                  <Typography component="span" color="text.secondary" sx={{fontSize: 10, fontStyle: 'italic'}}>{` (${description})`}</Typography>
                  <Typography component="span" color="text.secondary" sx={{fontSize: 10, fontWeight: 'bold'}}>{value ? `: ${value}`: ''}</Typography>
                </div>)
            })
          : null
      }
      {
        measureResults
          ? (
              <>
                <Typography color="text.secondary" gutterBottom sx={{...titleStyle}}>
                    {`${t('general.measures')}`}
                </Typography>
                {
                  measureResults.map(({label, value, type}) => {
                    return (
                      <Typography key={type} color="text.secondary" gutterBottom sx={{fontSize: 11}}>
                        {`${label}: ${value}`}
                      </Typography>)
                  })
                }
              </>
            )
          : null
      }
    </Stack>
  );
};

DiagnosisItemView.propTypes = {
  diagnosisCase: PropTypes.instanceOf(BaseDiagnosisCase).isRequired,
  allMeasureResultsMap: PropTypes.object
};

DiagnosisItemView.defaultProps = {
  allMeasureResultsMap: null,
};

export default DiagnosisItemView;
