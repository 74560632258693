import MeasureResult from "./MeasureResult";

class MainMeasureResult extends MeasureResult {

  getTearDrop() {
    return this.refMarkupData.getTearDrop();
  }

  getAcetabulum() {
    return this.refMarkupData.getAcetabulum();
  }

  getBMarkupPoint () {
    return this.refMarkupData.getBMarkupPoint();
  }

  getB1MarkupPoint() {
    return this.refMarkupData.getB1MarkupPoint();
  }

  getClosestTearDropMarkupPoint (point) {
    return this.refMarkupData.getClosestTearDropMarkupPoint(point)
  }

  getHAxisDirection() {
    return this.refMarkupData.getMarkupSideFromSagittalAxis();
  }

}

export default MainMeasureResult;