const AgeRangeLabels = {
  R7_9: "7-9",
  R10_12: "10-12",
  R12_: ">12",
};

export const AgeType = {
  R7_9: "R7_9",
  R10_12: "R10_12",
  R12_: "R12_"
}

export const defaultAge = AgeType.R12_;
export const ageRanges = Object.keys(AgeRangeLabels).map(ageId => {return {ageId, label: AgeRangeLabels[ageId]}});
export const allAgeRanges = ageRanges.map(range => range.ageId);
export const getAgeDescr = (ageId) => AgeRangeLabels[ageId] || '';